<ion-content class="ion-padding app-ticker-details" appScrollbar>
  <ol class="cd-breadcrumb custom-separator custom-icons">
    <li><a routerLink="/{{ role }}/dashboard">Tools</a></li>
    <li>
      <a routerLink="/{{ role }}/tools/trade-trackers/{{ toolSlug }}">Trade Trackers</a>
    </li>
    <li><a (click)="back()">Money Press Method</a></li>
    <li class="current" *ngIf="stockDetailRow && stockDetailRow?.tickerName">
      {{ stockDetailRow?.tickerName | titlecase }}
    </li>
  </ol>

  <div class="row-page-heading trade-row-heading">
    <div class="row">
      <span class="back-trade" (click)="back()"> < Back To Money Press Trade Tracker</span>
    </div>
    <div class="page-heading">
      <div class="actions">
        <ion-buttons>
          <ion-button
            class="border-style"
            (click)="openRightMenuNotes()"
            color="primary"
            fill="solid"
          >
            <ion-icon slot="start" name="document-text-outline"></ion-icon>
            <span>{{ stockDetailRow?.notesCount }} Notes</span>
          </ion-button>

          <div class="more-btn">
            <ion-button class="more-btn" (click)="toggleDropdown($event, row)">
              <img src="../../../../../../assets/icon/more.png" />
            </ion-button>
            <div class="dropdown-menu dropdown-content" *ngIf="isDropdownOpen">
              <svg
                class="svg-bg"
                width="174"
                height="80"
                viewBox="0 0 174 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask id="path-1-inside-1_4123_4235" fill="white">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M154.295 9L147.5 0L140.705 9H4C1.79086 9 0 10.7909 0 13V76C0 78.2091 1.79086 80 4 80H170C172.209 80 174 78.2091 174 76V13C174 10.7909 172.209 9 170 9H154.295Z"
                  />
                </mask>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M154.295 9L147.5 0L140.705 9H4C1.79086 9 0 10.7909 0 13V76C0 78.2091 1.79086 80 4 80H170C172.209 80 174 78.2091 174 76V13C174 10.7909 172.209 9 170 9H154.295Z"
                  fill="white"
                />
                <path
                  d="M147.5 0L148.298 -0.602549L147.5 -1.65962L146.702 -0.602549L147.5 0ZM154.295 9L153.497 9.60255L153.797 10H154.295V9ZM140.705 9V10H141.203L141.503 9.60255L140.705 9ZM146.702 0.602549L153.497 9.60255L155.093 8.39745L148.298 -0.602549L146.702 0.602549ZM141.503 9.60255L148.298 0.602549L146.702 -0.602549L139.907 8.39745L141.503 9.60255ZM4 10H140.705V8H4V10ZM1 13C1 11.3431 2.34315 10 4 10V8C1.23858 8 -1 10.2386 -1 13H1ZM1 76V13H-1V76H1ZM4 79C2.34315 79 1 77.6569 1 76H-1C-1 78.7614 1.23857 81 4 81V79ZM170 79H4V81H170V79ZM173 76C173 77.6569 171.657 79 170 79V81C172.761 81 175 78.7614 175 76H173ZM173 13V76H175V13H173ZM170 10C171.657 10 173 11.3431 173 13H175C175 10.2386 172.761 8 170 8V10ZM154.295 10H170V8H154.295V10Z"
                  fill="#B3B3B3"
                  mask="url(#path-1-inside-1_4123_4235)"
                />
              </svg>

              <div class="arrow"></div>
              <ion-item
                id=""
                (click)="showTradeDelete()"
                lines="none"
                [disabled]="!disableCloseTrade"
                class="first ion-no-padding width-css dropdown-item"
                >Close Trade</ion-item
              >
              <ion-item
                id=""
                (click)="openRightMenuTraders()"
                lines="none"
                class="second ion-no-padding width-css dropdown-item"
                >Trade History</ion-item
              >
            </div>
          </div>

          <!-- <ion-button class="border-style" (click)="openRightMenu()" color="primary" fill="solid"> -->
          <!-- <ion-icon slot="start" name="notifications-outline"></ion-icon> -->
          <!-- <span>{{ stockDetailRow?.alertsCount }} Alerts</span> -->
          <!-- </ion-button> -->
        </ion-buttons>
      </div>
    </div>
  </div>
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="8" size-lg="8">
      <div class="share-details">
        <ion-card class="ion-no-margin ion-margin-bottom">
          <ion-card-content>
            <div class="share">
              <div class="share-name">
                <div class="title">
                  <!-- <h1 style="color: #4f4f4f; font-weight: 700; margin-bottom: 0"></h1> -->
                  <ion-card-title *ngIf="stockDetailRow && stockDetailRow.tickerName">
                    {{ stockDetailRow?.tickerName }} ({{ stockDetailRow?.symbol }} )
                    <div class="subtitle-text">NasdaqGS: {{ stockDetailRow?.tickerName }}</div>
                  </ion-card-title>
                </div>
                <!-- <div class="action" *ngIf="stockDetailRow?.stockUrl">
                  <span class="logo"
                    ><img [src]="stockDetailRow?.stockUrl" alt="" srcset=""
                  /></span>
                </div> -->
                <div class="action" *ngIf="iframeSrc && !stockDetailRow?.stockUrl">
                  <span class="logo log-dynamic"><img [src]="iframeSrc" alt="" srcset="" /></span>
                </div>
              </div>
              <div
                class="ticker-description-container ion-flex-container ion-align-items-left ion-flex-wrap"
              >
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>INDUSTRY:</span>
                  <span>{{ stockDetailRow?.industry }}</span>
                </div>
                <span class="desc-divider"></span>
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>SECTOR:</span>
                  <span>{{ stockDetailRow?.sector }}</span>
                </div>
                <span class="desc-divider"></span>
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>CURRENT PRICE:</span>
                  <div
                    *ngIf="stockDetailRow && stockDetailRow.stockPrice"
                    class="ion-flex-container ion-align-items-left ion-flex-gap-default"
                  >
                    <span>{{ stockDetailRow?.stockPrice | currency }}</span>
                  </div>
                </div>
                <span class="desc-divider"></span>
                <!-- <div -->
                <!-- class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column" -->
                <!-- > -->
                <!-- <span>EARNING DATE:</span> -->
                <!-- <span>{{ tradeEarningsDate }}</span> -->
                <!-- </div> -->
                <div
                  class="desc-item ion-flex-container ion-align-items-left ion-flex-wrap ion-flex-column"
                >
                  <span>TODAY'S CHANGE:</span>
                  <div class="ion-flex-container ion-align-items-left ion-flex-gap-default">
                    <ng-container *ngIf="stockDetailRow?.regularMarketChange > 0">
                      <ion-icon name="arrow-up" color="primary" style="font-size: 20px"></ion-icon>
                    </ng-container>
                    <ng-container *ngIf="stockDetailRow?.regularMarketChange < 0">
                      <ion-icon name="arrow-down" color="danger" style="font-size: 20px"></ion-icon>
                    </ng-container>
                    <span [ngClass]="getHeaderClassTodayChange({ stockDetailRow: stockDetailRow })"
                      >{{ stockDetailRow?.regularMarketChange | currency }}({{
                        stockDetailRow?.marketChangePercentage
                      }}%)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
        <div class="profit-n-loss">
          <div class="profit">
            <ion-card class="ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="6" size-lg="6"
                      ><span class="today-pnl-title">Earnings Date:</span></ion-col
                    >
                    <ion-col
                      [ngClass]="getClassForEarningDays({ stockDetailRow: stockDetailRow })"
                      size-xs="12"
                      size-sm="12"
                      size-md="12"
                      size-xl="6"
                      size-lg="6"
                    >
                      <span
                        class="earning-date-value"
                        *ngIf="stockDetailRow?.earningStartDate != stockDetailRow?.earningEndDate"
                      >
                        <span>
                          ~ {{ stockDetailRow?.earningStartDate }}

                          <div
                            class="tooltip"
                            *ngIf="
                              stockDetailRow?.earningStartDate != stockDetailRow?.earningEndDate
                            "
                          >
                            <ion-icon name="alert-circle"></ion-icon>
                            <span class="tooltiptext"
                              >The earnings date is currently an estimate. As the date gets closer
                              the actual earnings date will be confirmed.</span
                            >
                          </div>
                        </span>
                        <p>{{ stockDetailRow?.daysDifference }} days</p>
                      </span>

                      <span
                        class="earning-date-value"
                        *ngIf="stockDetailRow?.earningStartDate == stockDetailRow?.earningEndDate"
                      >
                        <span>
                          <ion-icon name="checkmark-circle"></ion-icon
                          >{{ stockDetailRow?.earningStartDate }}
                        </span>
                        <p>{{ stockDetailRow?.daysDifference }} days</p></span
                      ></ion-col
                    >
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </div>
          <div class="loss">
            <ion-card class="ion-no-margin">
              <ion-card-content class="ion-no-padding">
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-xl="6" size-lg="6"
                      ><span class="today-pnl-title"
                        >Position P&L
                        <p>Return On Margin</p></span
                      ></ion-col
                    >
                    <ion-col
                      [ngClass]="
                        getHeaderClassPositionPnL({ row: { totalProfitLoss: calculatePnL() } })
                      "
                      size-xs="12"
                      size-sm="12"
                      size-md="12"
                      size-xl="6"
                      size-lg="6"
                    >
                      <span class="earning-date-value" *ngIf="isIntervalLoader"
                        ><ion-spinner name="dots"></ion-spinner
                      ></span>
                      <!-- <span *ngIf="!isIntervalLoader" class="today-pnl-value">
                        {{ calculatePnL() | currency }}
                        <p>{{ calculatePnLMargin().toFixed(2) }} %</p></span
                      > -->
                      <span *ngIf="!isIntervalLoader" class="today-pnl-value">
                        <span>{{ calculatePnL() | currency }}</span>
                        <p>{{ calculatePnLMargin().toFixed(2) }} %</p></span
                      >
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </div>
    </ion-col>
    <ion-col class="left-col" size-xs="12" size-sm="12" size-md="12" size-xl="4" size-lg="4">
      <div class="account-details ion-no-padding">
        <ion-card class="ion-no-margin" style="height: 100%">
          <ion-card-content class="ion-no-padding">
            <ion-list lines="full" [inset]="true" class="list-ticker-portfolio-details">
              <ion-item>
                <div class="detail-container">
                  <div class="details">
                    <div class="label-icon">
                      <span>ACCOUNT SIZE:</span>
                      <div class="icon-div" (click)="accountSizeRedirect()">
                        <ion-icon slot="icon-only" name="create-outline"></ion-icon>
                      </div>
                    </div>
                    <span class="account-value">{{ row?.accountSize | currency }}</span>
                  </div>
                </div>
              </ion-item>
              <ion-item>
                <div class="detail-container">
                  <div class="details">
                    <span>MARGIN:</span>
                    <!-- <span></span> -->
                    <span>{{ row?.margin | currency }}</span>
                  </div>
                </div>
              </ion-item>
              <ion-item>
                <div class="detail-container">
                  <div class="details">
                    <span>PORTFOLIO EXPOSURE :</span>
                    <!-- <span></span> -->
                    <span>{{ row?.portpholioRisk?.toFixed(2) }}%</span>
                  </div>
                </div>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-col>
  </ion-row>

  <div class="page-heading">
    <h2>Weekly Sold Put</h2>
    <div class="actions">
      <ion-buttons>
        <div class="action">
          <div class="edit">
            <ion-button (click)="positionAddUpdate('weekly', 'add')" color="primary">
              <ion-icon name="add-circle-outline"></ion-icon>
              ADD WEEKLY LEG
            </ion-button>
          </div>
        </div>
      </ion-buttons>
    </div>
  </div>
  <ion-card class="ion-no-margin card-container">
    <ion-card-content class="ion-no-padding">
      <ngx-datatable
        external-analytics
        #externalAnalyticsTable
        class="material body-cell-padding-custom body-cell-stacked ticker-details-table"
        [rows]="showAllWeeklyRows ? weeklyPutPosition : parentData"
        [rowClass]="isRowClass"
        [columnMode]="'force'"
        [rowHeight]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [treeFromRelation]="'parentSlug'"
        [treeToRelation]="'slug'"
        (treeAction)="onTreeActionWeekly($event)"
        (activate)="onTreeActionWeekly($event)"
      >
        <ngx-datatable-column
          [width]="40"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.class === 'split-row-custom-style'" class="tooltip tooltip-text-show">
              <svg
                width="10"
                height="10"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="visibility: hidden"
              >
                <path
                  d="M8.492 6.92414C8.46974 6.80615 8.40225 6.70043 8.30283 6.6278C8.20341 6.55517 8.07926 6.5209 7.95482 6.53174C7.83039 6.54257 7.71469 6.59773 7.63051 6.68634C7.54633 6.77496 7.49977 6.89061 7.5 7.01052V11.3312L7.508 11.4176C7.53026 11.5355 7.59775 11.6413 7.69717 11.7139C7.79659 11.7865 7.92074 11.8208 8.04518 11.81C8.16961 11.7991 8.28531 11.744 8.36949 11.6554C8.45367 11.5667 8.50023 11.4511 8.5 11.3312V7.01052L8.492 6.92414ZM8.799 4.85115C8.799 4.66025 8.71998 4.47717 8.57933 4.34218C8.43868 4.20719 8.24791 4.13136 8.049 4.13136C7.85009 4.13136 7.65932 4.20719 7.51867 4.34218C7.37802 4.47717 7.299 4.66025 7.299 4.85115C7.299 5.04205 7.37802 5.22513 7.51867 5.36012C7.65932 5.4951 7.85009 5.57094 8.049 5.57094C8.24791 5.57094 8.43868 5.4951 8.57933 5.36012C8.71998 5.22513 8.799 5.04205 8.799 4.85115ZM16 7.97024C16 5.93397 15.1571 3.9811 13.6569 2.54124C12.1566 1.10138 10.1217 0.29248 8 0.29248C5.87827 0.29248 3.84344 1.10138 2.34315 2.54124C0.842855 3.9811 0 5.93397 0 7.97024C0 10.0065 0.842855 11.9594 2.34315 13.3992C3.84344 14.8391 5.87827 15.648 8 15.648C10.1217 15.648 12.1566 14.8391 13.6569 13.3992C15.1571 11.9594 16 10.0065 16 7.97024ZM1 7.97024C1 7.08801 1.18106 6.21443 1.53284 5.39936C1.88463 4.58429 2.40024 3.8437 3.05025 3.21987C3.70026 2.59604 4.47194 2.10119 5.32122 1.76358C6.1705 1.42597 7.08075 1.2522 8 1.2522C8.91925 1.2522 9.8295 1.42597 10.6788 1.76358C11.5281 2.10119 12.2997 2.59604 12.9497 3.21987C13.5998 3.8437 14.1154 4.58429 14.4672 5.39936C14.8189 6.21443 15 7.08801 15 7.97024C15 9.75197 14.2625 11.4607 12.9497 12.7206C11.637 13.9805 9.85652 14.6883 8 14.6883C6.14348 14.6883 4.36301 13.9805 3.05025 12.7206C1.7375 11.4607 1 9.75197 1 7.97024Z"
                  fill="#000032"
                  fill-opacity="0.39"
                />
              </svg>
              <p class="tooltiptext">
                A historical leg is when you add a leg from the past whose expiration date has
                already passed. This is typically used when journaling old positions.
              </p>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="104"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> DATE SOLD </ng-template>
          <ng-template let-row="row" let-dateSold="row.dateSold" ngx-datatable-cell-template>
            {{ dateSold | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="104"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>EXPIRATION DATE</ng-template>
          <ng-template
            let-row="row"
            let-expirationPeriod="row.expirationPeriod"
            ngx-datatable-cell-template
          >
            {{ expirationPeriod | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> STRIKE SOLD </ng-template>
          <ng-template let-row="row" let-strikeSold="row.strikeSold" ngx-datatable-cell-template>
            {{ strikeSold | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="108"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> UNDERLYING PRICE </ng-template>
          <ng-template
            let-row="row"
            let-underlyingStock="row.underlyingStock"
            ngx-datatable-cell-template
          >
            {{ underlyingStock | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="105"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> #OF CONTRACTS </ng-template>
          <ng-template
            let-row="row"
            let-numberOfContracts="row.numberOfContracts"
            ngx-datatable-cell-template
          >
            {{ numberOfContracts }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>CREDIT</ng-template>
          <ng-template let-row="row" let-premiumSold="row.premiumSold" ngx-datatable-cell-template>
            {{ premiumSold | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> EXIT PREMIUM </ng-template>
          <ng-template let-row="row" let-exitPremium="row.exitPremium" ngx-datatable-cell-template>
            <span
              *ngIf="!isIntervalLoader || row.positionStatus === 'closed'"
              [style.color]="getColorBasedOnStatus(row.positionStatus)"
            >
              {{ exitPremium | currency }}</span
            >
            <span *ngIf="checkForLoader(row) && isIntervalLoader">
              <ion-spinner name="dots"></ion-spinner>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column [sortable]="false"> -->
        <!-- <ng-template ngx-datatable-header-template> PREMIUM EARNED </ng-template> -->
        <!-- <ng-template -->
        <!-- let-row="row" -->
        <!-- let-premiumEarned="row.premiumEarned" -->
        <!-- ngx-datatable-cell-template -->
        <!-- > -->
        <!-- <p>{{ premiumEarned }}</p> -->
        <!-- </ng-template> -->
        <!-- </ngx-datatable-column> -->
        <ngx-datatable-column
          [width]="100"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> P&L </ng-template>
          <ng-template
            let-row="row"
            let-tradeProfitLoss="row.tradeProfitLoss"
            ngx-datatable-cell-template
          >
            <p>{{ tradeProfitLoss | currency }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="120"
          prop="cumulative"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> CUMULATIVE </ng-template>
          <ng-template
            let-row="row"
            let-cumulativeProfitLoss="row.cumulativeProfitLoss"
            ngx-datatable-cell-template
          >
            <p>{{ cumulativeProfitLoss > 0 ? '+' : '' }}{{ cumulativeProfitLoss | currency }}</p>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="65"
          [cellClass]="getHeaderClassProfitLoss"
          prop="cumulative"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> % </ng-template>
          <ng-template
            let-row="row"
            let-profitLossPercentage="row.profitLossPercentage"
            ngx-datatable-cell-template
          >
            {{ profitLossPercentage }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="110"
          [sortable]="true"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> STATUS </ng-template>
          <ng-template
            let-row="row"
            let-positionStatus="row.positionStatus"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-chip
              *ngIf="!isTotalCumulativeProfitPrice"
              [outline]="true"
              [color]="positionStatus == 'closed' ? 'danger' : 'primary'"
            >
              <span
                class="status-dot"
                [ngClass]="{
                  'dot-position-close': positionStatus == 'closed',
                  'dot-position-open': positionStatus == 'open'
                }"
              ></span>
              <ion-text>
                {{ positionStatus | uppercase }}
              </ion-text>
            </ion-chip>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="80"
          class="custom-button"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> ACTION </ng-template>
          <ng-template
            let-row="row"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-buttons
              *ngIf="!isTotalCumulativeProfitPrice"
              slot="primary"
              class="ion-justify-content-end custom-button ion-buttons-custom"
            >
              <ion-button
                *ngIf="!row.isSubData"
                class="p-0 edit-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  positionAddUpdate('weekly', 'edit', row)
                "
              >
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
              </ion-button>
              <ion-button
                *ngIf="!row.isSubData"
                class="p-0 delete-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  openConfirmationDialog(row, 'weekly')
                "
              >
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer></ngx-datatable-footer>
      </ngx-datatable>
      <div class="show-more-button" *ngIf="weeklyCount > 3">
        <!-- weeklyCount instead of weeklyPutPosition.length > 4 -->
        <button class="show-button" ion-button (click)="toggleWeeklyShowMore()">
          {{
            showAllWeeklyRows
              ? 'SHOW LESS'
              : 'SHOW&nbsp;&nbsp;' + (weeklyCount - 3) + '&nbsp;&nbsp;MORE'
          }}
        </button>
      </div>
    </ion-card-content>
  </ion-card>
  <div class="page-heading ion-padding-top">
    <h2>Long Put Protection</h2>
    <div class="actions">
      <ion-buttons>
        <div class="action">
          <div class="edit">
            <ion-button (click)="positionAddUpdate('long', 'add')" color="primary">
              <ion-icon name="add-circle-outline"></ion-icon>
              ADD PROTECTIVE LEG
            </ion-button>
          </div>
        </div>
      </ion-buttons>
    </div>
  </div>
  <ion-card class="ion-no-margin card-container">
    <ion-card-content class="ion-no-padding">
      <ngx-datatable
        external-analytics
        #externalAnalyticsTable
        class="material body-cell-padding-custom body-cell-stacked ticker-details-table"
        [rows]="showAllLongRows ? longPutPosition : longParentData"
        [rowClass]="isRowClass"
        [columnMode]="'force'"
        [rowHeight]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [treeFromRelation]="'parentSlug'"
        [treeToRelation]="'slug'"
        (treeAction)="onTreeActionLong($event)"
        (activate)="onTreeActionLong($event)"
      >
        <ngx-datatable-column
          [width]="40"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row.class === 'split-row-custom-style'" class="tooltip tooltip-text-show">
              <svg
                width="10"
                height="10"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="visibility: hidden"
              >
                <path
                  d="M8.492 6.92414C8.46974 6.80615 8.40225 6.70043 8.30283 6.6278C8.20341 6.55517 8.07926 6.5209 7.95482 6.53174C7.83039 6.54257 7.71469 6.59773 7.63051 6.68634C7.54633 6.77496 7.49977 6.89061 7.5 7.01052V11.3312L7.508 11.4176C7.53026 11.5355 7.59775 11.6413 7.69717 11.7139C7.79659 11.7865 7.92074 11.8208 8.04518 11.81C8.16961 11.7991 8.28531 11.744 8.36949 11.6554C8.45367 11.5667 8.50023 11.4511 8.5 11.3312V7.01052L8.492 6.92414ZM8.799 4.85115C8.799 4.66025 8.71998 4.47717 8.57933 4.34218C8.43868 4.20719 8.24791 4.13136 8.049 4.13136C7.85009 4.13136 7.65932 4.20719 7.51867 4.34218C7.37802 4.47717 7.299 4.66025 7.299 4.85115C7.299 5.04205 7.37802 5.22513 7.51867 5.36012C7.65932 5.4951 7.85009 5.57094 8.049 5.57094C8.24791 5.57094 8.43868 5.4951 8.57933 5.36012C8.71998 5.22513 8.799 5.04205 8.799 4.85115ZM16 7.97024C16 5.93397 15.1571 3.9811 13.6569 2.54124C12.1566 1.10138 10.1217 0.29248 8 0.29248C5.87827 0.29248 3.84344 1.10138 2.34315 2.54124C0.842855 3.9811 0 5.93397 0 7.97024C0 10.0065 0.842855 11.9594 2.34315 13.3992C3.84344 14.8391 5.87827 15.648 8 15.648C10.1217 15.648 12.1566 14.8391 13.6569 13.3992C15.1571 11.9594 16 10.0065 16 7.97024ZM1 7.97024C1 7.08801 1.18106 6.21443 1.53284 5.39936C1.88463 4.58429 2.40024 3.8437 3.05025 3.21987C3.70026 2.59604 4.47194 2.10119 5.32122 1.76358C6.1705 1.42597 7.08075 1.2522 8 1.2522C8.91925 1.2522 9.8295 1.42597 10.6788 1.76358C11.5281 2.10119 12.2997 2.59604 12.9497 3.21987C13.5998 3.8437 14.1154 4.58429 14.4672 5.39936C14.8189 6.21443 15 7.08801 15 7.97024C15 9.75197 14.2625 11.4607 12.9497 12.7206C11.637 13.9805 9.85652 14.6883 8 14.6883C6.14348 14.6883 4.36301 13.9805 3.05025 12.7206C1.7375 11.4607 1 9.75197 1 7.97024Z"
                  fill="#000032"
                  fill-opacity="0.39"
                />
              </svg>
              <p class="tooltiptext">
                A historical leg is when you add a leg from the past whose expiration date has
                already passed. This is typically used when journaling old positions.
              </p>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="104"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>DATE PURCHASED</ng-template>
          <ng-template
            let-row="row"
            let-datePurchased="row.datePurchased"
            ngx-datatable-cell-template
          >
            {{ datePurchased | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="104"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>EXPIRATION DATE</ng-template>
          <ng-template
            let-row="row"
            let-expirationPeriod="row.expirationPeriod"
            ngx-datatable-cell-template
          >
            {{ expirationPeriod | date : 'MM/dd/yyyy' : 'UTC' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>STRIKE BOUGHT</ng-template>
          <ng-template
            let-row="row"
            let-strikeBought="row.strikeBought"
            ngx-datatable-cell-template
          >
            {{ strikeBought | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="108"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>UNDERLYING PRICE</ng-template>
          <ng-template
            let-row="row"
            let-underlyingStock="row.underlyingStock"
            ngx-datatable-cell-template
          >
            {{ underlyingStock | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="105"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>#OF CONTRACTS</ng-template>
          <ng-template
            let-row="row"
            let-numberOfContracts="row.numberOfContracts"
            ngx-datatable-cell-template
          >
            {{ numberOfContracts }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>PREMIUM PAID</ng-template>
          <ng-template let-row="row" let-premiumPaid="row.premiumPaid" ngx-datatable-cell-template>
            {{ premiumPaid | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="90"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>EXIT PREMIUM</ng-template>
          <ng-template let-row="row" let-exitPremium="row.exitPremium" ngx-datatable-cell-template>
            <span
              *ngIf="!isIntervalLoader || row.positionStatus === 'closed'"
              [style.color]="getColorBasedOnStatus(row.positionStatus)"
            >
              {{ exitPremium | currency }}</span
            >
            <span *ngIf="checkForLoader(row) && isIntervalLoader">
              <ion-spinner name="dots"></ion-spinner>
            </span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="100"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template>P&L</ng-template>
          <ng-template
            let-row="row"
            let-tradeProfitLoss="row.tradeProfitLoss"
            ngx-datatable-cell-template
          >
            {{ tradeProfitLoss | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="120"
          prop="cumulative"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> CUMULATIVE </ng-template>
          <ng-template
            let-row="row"
            let-cumulativeProfitLoss="row.cumulativeProfitLoss"
            ngx-datatable-cell-template
          >
            {{ cumulativeProfitLoss > 0 ? '+' : '' }}{{ cumulativeProfitLoss | currency }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="65"
          [cellClass]="getHeaderClassProfitLoss"
          prop="cumulative"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> % </ng-template>
          <ng-template
            let-row="row"
            let-profitLossPercentage="row.profitLossPercentage"
            ngx-datatable-cell-template
          >
            {{ profitLossPercentage }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="110"
          [sortable]="true"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> STATUS </ng-template>
          <ng-template
            let-row="row"
            let-positionStatus="row.positionStatus"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-chip
              *ngIf="!isTotalCumulativeProfitPrice"
              [outline]="true"
              [color]="positionStatus == 'closed' ? 'danger' : 'primary'"
            >
              <span
                class="status-dot"
                [ngClass]="{
                  'dot-position-close': positionStatus == 'closed',
                  'dot-position-open': positionStatus == 'open'
                }"
              ></span>
              <ion-text>
                {{ positionStatus | uppercase }}
              </ion-text>
            </ion-chip>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [width]="80"
          class="custom-button"
          [sortable]="false"
          [isTreeColumn]="true"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template> ACTION </ng-template>
          <ng-template
            let-row="row"
            let-isTotalCumulativeProfitPrice="row.isTotalCumulativeProfitPrice"
            ngx-datatable-cell-template
          >
            <ion-buttons
              *ngIf="!isTotalCumulativeProfitPrice"
              slot="primary"
              class="ion-buttons-custom"
            >
              <ion-button
                *ngIf="!row.isSubData"
                class="p-0 edit-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  positionAddUpdate('long', 'edit', row)
                "
              >
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
              </ion-button>
              <ion-button
                *ngIf="!row.isSubData"
                class="p-0 delete-icon"
                color="medium"
                fill="clear"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  openConfirmationDialog(row, 'long')
                "
              >
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer></ngx-datatable-footer>
      </ngx-datatable>
      <div class="show-more-button" *ngIf="longCount > 3">
        <button class="show-button" ion-button (click)="toggleLongShowMore()">
          {{
            showAllLongRows
              ? 'SHOW LESS'
              : 'SHOW&nbsp;&nbsp;' + (longCount - 3) + '&nbsp;&nbsp;MORE'
          }}
        </button>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
