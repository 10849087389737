import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WalkwelDirectiveModules } from '../../directives/walkwel-directive-modules.module';
import { IonicModule } from '@ionic/angular';
import { InfusionTagsComponent } from './infusion-tags.component';
import { TagListComponent } from '../tag-list/tag-list/tag-list.component';

@NgModule({
  declarations: [InfusionTagsComponent, TagListComponent],
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule, WalkwelDirectiveModules],
  providers: [],
  exports: [InfusionTagsComponent, TagListComponent],
})
export class InfusionsoftTagsModule {}
