import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { WatchListService } from 'src/app/services/watch-list.service';

@Component({
  selector: 'app-watch-list-add-edit',
  templateUrl: './watch-list-add-edit.component.html',
  styleUrls: ['./watch-list-add-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WatchListAddEditComponent implements OnInit, OnChanges {
  @Input() watchListData: any;
  mode;
  name: string;
  description: string;
  watchListList: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private watchListService: WatchListService,
    private toastServ: ToastService,
    public menuController: MenuController,
    private menuService: commonRightMenuService,
    private router: Router
  ) {
    this.watchListList = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
    });
  }

  ngOnInit() {
    if (this.watchListData.mode === 'edit') {
      this.setInitData();
    }
    if (this.watchListData.mode === 'add') {
      this.watchListList.patchValue({
        name: '',
        description: '',
      });
    }
  }
  ngOnChanges(): void {
    if (this.watchListData.mode === 'edit') {
      this.setInitData();
    }
    if (this.watchListData.mode === 'add') {
      this.watchListList.patchValue({
        name: '',
        description: '',
      });
    }
  }

  setInitData() {
    this.watchListList.reset();
    if (this.watchListData) {
      this.watchListList.patchValue({
        name: this.watchListData.editData.name,
        description: this.watchListData.editData.description,
      });
    }
  }

  createWatchList(mode) {
    const description = this.watchListList.get('description').value;
    const name = this.watchListList.get('name').value;
    const watchListId = this.watchListData?.editData?._id;
    const payload = {
      name: name,
      description: description,
    };
    const updatePayload = {
      name: name,
      description: description,
      watchListId: watchListId,
    };
    if (this.watchListData.mode == 'add') {
      this.watchListService.createWatchList(payload).subscribe((res: any) => {
        const lastId = res.data._id;
        if (res) {
          this.menuController.close('payload');
          this.watchListList.reset();
          this.cancel('false');
          this.toastServ.presentToast(res?.message, 'success');
          this.menuService.triggerNameReset(name);
          this.router.navigate([
            `/user/tools/watchList/${this.watchListData.activeToolSlug}/product/${lastId}`,
          ]);
        }
      });
    }
    if (this.watchListData.mode == 'edit') {
      this.watchListService.updateWatchList(updatePayload).subscribe((res: any) => {
        if (res) {
          this.watchListList.patchValue({
            name: name,
            description: description,
          });
          this.menuController.close('payload');
          this.watchListList.reset();
          this.cancel('false');
          this.toastServ.presentToast(res?.message, 'success');
          this.menuService.triggerChildReset(name, description);
        }
      });
    }
  }

  cancel(isNotify = 'true') {
    this.menuController.close();
    if (isNotify === 'true') {
      this.menuService.setCloseMenuSubject({});
    }
  }
}
