import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-header-notifications-popover',
  template: `
    <ion-content>
      <div class="header-notification">
        <div class="notification-header">
          <div class="header-title">NOTIFICATIONS</div>
          <div class="header-action" *ngIf="notifications.length > 0 && notificationBedgeCount > 0">
            <span (click)="markAllRead()">Mark All Read</span>
          </div>
        </div>
        <div class="notification-body">
          <div class="notification-empty" *ngIf="notifications.length === 0">
            No recent notifications
          </div>
          <div
            class="notification"
            appScrollbar
            [directInject]="true"
            [ngClass]="{ 'seen-notification': notification.isRead }"
            (click)="
              notificationRedirect(notification.hrefLink, notification.id, notification.alertType)
            "
            *ngFor="let notification of notifications"
          >
            <div [ngSwitch]="notification.type">
              <div *ngSwitchCase="'ChatComment'">
                <div class="left-section">
                  <div class="user-profile">
                    <img
                      ngxGravatar
                      [email]="notification?.commentAuthor?.email"
                      size="45"
                      src="{{ notification?.commentAuthor?.image }}"
                    />
                  </div>
                  <div class="notification-info chat-notification-info">
                    <div
                      class="message-container"
                      [innerHtml]="notification.message | keepHtml"
                    ></div>
                    <div
                      class="comment"
                      *ngIf="notification.comment"
                      [innerHtml]="notification.comment.commentText | keepHtml"
                    ></div>
                    <div class="time">{{ notification.dateTime | moment }}</div>
                  </div>
                </div>
                <div class="read-status" *ngIf="!notification.isRead"></div>
              </div>
              <div *ngSwitchCase="'Broadcast'">
                <div class="left-section">
                  <div class="broadcast-logo">
                    <div class="image-container">
                      <img src="/assets/icon/broadcast-speaker.png" />
                    </div>
                  </div>
                  <div class="notification-info broadcast-notification-info">
                    <div class="broadcast-title">New Broadcast Message</div>
                    <div class="broadcast-subject">{{ notification.subject }}</div>
                    <div
                      class="broadcast-message"
                      [innerHtml]="notification.message | keepHtml"
                    ></div>
                    <div class="time">{{ notification.dateTime | moment }}</div>
                  </div>
                </div>
                <div class="read-status" *ngIf="!notification.isRead"></div>
              </div>
              <div *ngSwitchCase="'InsiderChat'">
                <div class="left-section">
                  <div class="user-profile">
                    <img
                      ngxGravatar
                      [email]="notification?.threadAuthor?.email"
                      size="45"
                      src="{{ notification?.threadAuthor?.image }}"
                    />
                  </div>
                  <div class="notification-info chat-notification-info">
                    <div
                      class="message-container"
                      [innerHtml]="notification.message | keepHtml"
                    ></div>
                    <div
                      class="comment"
                      *ngIf="notification.thread"
                      [innerHtml]="notification?.thread?.threadText | keepHtml"
                    ></div>
                    <div class="time">{{ notification.dateTime | moment }}</div>
                  </div>
                </div>
                <div class="read-status" *ngIf="!notification.isRead"></div>
              </div>
              <div *ngSwitchCase="'Alert'">
                <div class="left-section">
                  <div class="user-profile">
                    <div class="symbol" *ngIf="notification.alertType == 'stock_price'">
                      <svg
                        width="35"
                        height="55"
                        viewBox="0 0 20 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 11C1 9.89543 1.89543 9 3 9V9C4.10457 9 5 9.89543 5 11V14C5 15.1046 4.10457 16 3 16V16C1.89543 16 1 15.1046 1 14V11ZM7.99999 8C7.99999 6.89543 8.89542 6 9.99999 6V6C11.1046 6 12 6.89543 12 8V17C12 18.1046 11.1046 19 9.99999 19V19C8.89542 19 7.99999 18.1046 7.99999 17V8Z"
                          stroke="#30362B"
                          stroke-width="1.2"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.0002 21V19"
                          stroke="#30362B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M15.0002 7C15.0002 5.89543 15.8957 5 17.0002 5V5C18.1048 5 19.0002 5.89543 19.0002 7V7.5C19.0002 8.60456 18.1048 9.49999 17.0002 9.49999V9.49999C15.8957 9.49999 15.0002 8.60457 15.0002 7.5V7Z"
                          stroke="#30362B"
                          stroke-width="1.2"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.00061 8.99999V4M17.0006 16V9.49999M17.0006 4.99999V1"
                          stroke="#30362B"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <div class="symbol" *ngIf="notification.alertType == 'on_date'">
                      <svg
                        width="35"
                        height="55"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 1C14.9707 1 19 5.0293 19 10C19 14.9707 14.9707 19 10 19C5.0293 19 1 14.9707 1 10C1 5.0293 5.0293 1 10 1ZM10 2.8C8.09044 2.8 6.25909 3.55857 4.90883 4.90883C3.55857 6.25909 2.8 8.09044 2.8 10C2.8 11.9096 3.55857 13.7409 4.90883 15.0912C6.25909 16.4414 8.09044 17.2 10 17.2C11.9096 17.2 13.7409 16.4414 15.0912 15.0912C16.4414 13.7409 17.2 11.9096 17.2 10C17.2 8.09044 16.4414 6.25909 15.0912 4.90883C13.7409 3.55857 11.9096 2.8 10 2.8ZM10 4.6C10.2204 4.60003 10.4332 4.68096 10.5979 4.82744C10.7627 4.97393 10.8679 5.17577 10.8937 5.3947L10.9 5.5V9.6274L13.3363 12.0637C13.4977 12.2257 13.5914 12.443 13.5984 12.6715C13.6054 12.9001 13.5251 13.1227 13.3739 13.2943C13.2226 13.4658 13.0118 13.5733 12.7842 13.595C12.5565 13.6166 12.3292 13.5509 12.1483 13.411L12.0637 13.3363L9.3637 10.6363C9.22382 10.4963 9.13399 10.3141 9.1081 10.1179L9.1 10V5.5C9.1 5.2613 9.19482 5.03239 9.3636 4.8636C9.53239 4.69482 9.7613 4.6 10 4.6Z"
                          fill="#30362B"
                          stroke="#F8FAF7"
                          stroke-width="0.5"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="notification-info chat-notification-info">
                    <div
                      class="message-container"
                      [innerHtml]="notification?.postBody | keepHtml"
                    ></div>
                    <div
                      class="comment"
                      *ngIf="notification.message"
                      [innerHtml]="notification.message | keepHtml"
                    ></div>
                    <div class="time">{{ notification.dateTime | moment }}</div>
                  </div>
                </div>
                <div class="read-status" *ngIf="!notification.isRead"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="notification-footer" *ngIf="notifications.length > 0">
          <ion-button color="primary" expand="full" (click)="viewAllNotifications()"
            >View All Notifications</ion-button
          >
        </div>
      </div>
    </ion-content>
  `,
  styleUrls: ['./common.component.scss'],
})
export class NotificationsPopoverPage {
  @Input() notifications = [];
  @Input() notificationBedgeCount = 0;
  @Input() triggerMarkAllRead;
  @Input() triggerNotificationRedirect;
  @Input() triggerViewAllNotifications;

  constructor(private popoverCtrl: PopoverController, private toolsApi: ToolServiceService) {}

  markAllRead() {
    this.close();
    this.triggerMarkAllRead();
  }

  notificationRedirect(link, id, alertType) {
    this.close();
    this.toolsApi.setSegmentValue('alert');
    this.toolsApi.setAlertValue(alertType);
    this.triggerNotificationRedirect(link, id);
  }

  viewAllNotifications() {
    this.close();
    this.triggerViewAllNotifications();
  }

  close() {
    this.popoverCtrl.dismiss();
  }
}
