import { NgModule } from '@angular/core';
import { ToolDashboardComponent } from './tool-dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { ToolDashboardSymbolDetailsComponent } from './tool-dashboard-symbol-details/tool-dashboard-symbol-details.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ToolDashboardComponent,
  },
  {
    path: 'stock-info/:symbol',
    component: ToolDashboardSymbolDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ToolDashboardRoutingModule {}
