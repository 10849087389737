import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TickerAlertsRoutingModule } from './ticker-alerts-routing.module';
import { AppDatePickerModule } from '../date-picker/date-picker.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TickerAlertsComponent } from './ticker-alerts.component';
import { AlertDatePickerModule } from '../alert-date-picker/alert-date-picker.module';

@NgModule({
  declarations: [TickerAlertsComponent],
  imports: [
    CommonModule,
    IonicModule,
    TickerAlertsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
    AlertDatePickerModule,
  ],
  exports: [TickerAlertsComponent],
})
export class TickerAlertsModule {}
