import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { StorageService } from '../services/storage.service';

const redirectCommentToUser = (next, state, role) => {
  const urlEndpoint = window.location.pathname;
  const endPointSlug = urlEndpoint.split('/');
  const staticEndpoint = endPointSlug.splice(0, 4).join('/');
  //check admin request but role is user
  if (staticEndpoint == '/admin/products/comments' && role == 'user') {
    const [productGroup, , product, , categorySlug, , content, , subContent] = endPointSlug;
    let url = `/user`;

    const queryParam = state.root.queryParams;
    //append slug to user route
    if (productGroup) url += `/product-groups/${productGroup}`;
    if (product) url += `/product/${product}`;
    if (categorySlug) url += `/category/${categorySlug}`;
    if (content) url += `/content/${content}`;
    if (subContent) url += `/sub-content/${subContent}`;
    //append query params to routes
    Object.keys(queryParam).forEach((each, index) => {
      if (index == 0) url += '?';
      url += `${each}=${queryParam[each]}& `;
    });
    window.location.href = url;
    return false;
  }
};
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public storageServ: StorageService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.storageServ.getObject('userData');
    const token = this.storageServ.get('token');
    const role = this.storageServ.get('role');
    if (!token || !role) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    //check comment redirection
    redirectCommentToUser(next, state, role);

    //It will check access for analytics pages
    if (
      (state.url.includes('reports') || state.url.includes('analytics')) &&
      (!user || !user.hasAnalyticsAccess)
    ) {
      this.router.navigate([`${role}/404`]);
      return false;
    }

    return true;
  }
}
