import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-watchlist-create-add-modal',
  template: `
    <div>
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button (click)="close()">
              <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>Add To Watchlist</ion-title>
        </ion-toolbar>
      </ion-header>
      <ngx-datatable
        #externalSpendTable
        class="material body-cell-stacked"
        [rows]="watchList"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        [scrollbarH]="true"
      >
        <ngx-datatable-column [sortable]="false">
          <ng-template
            let-row="row"
            let-name="row?.name"
            let-id="row?._id"
            let-symbolCount="row?.symbolCount"
            ngx-datatable-cell-template
          >
            <div class="watchlist-data">
              <span> {{ name }} ({{ symbolCount }}) </span>
              <ion-checkbox
                class="checkmark"
                [(ngModel)]="row.selected"
                (ionChange)="onWatchListSelect(row)"
              ></ion-checkbox>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <ion-buttons>
        <ion-button color="primary" (click)="createWatchlist()">
          <ion-icon slot="start" name="add-outline"></ion-icon>
          Create Watchlist
        </ion-button>
      </ion-buttons>
      <ion-content class="ion-padding-horizontal" *ngIf="showInput">
        <form [formGroup]="watchListForm" (ngSubmit)="onSubmit()" >
          <ion-row>
            <ion-col size-xl="12" size-lg="12">
              <ion-item  lines="inset" class="ion-item-no-inner-padding">
                <ion-label position="floating">Create Watchlist</ion-label>
                <ion-input inputmode="text" formControlName="name"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </form>
        
      </ion-content>
      <ion-toolbar class="footer-toolbar">
          <ion-buttons slot="end">
            <ion-button color="dark" fill="outline" (click)="close()"> Cancel </ion-button>
            <ion-button color="primary" fill="solid" (click)="onSubmit()"> ADD </ion-button>
          </ion-buttons>
        </ion-toolbar>
    </div>
  `,
  styleUrls: ['./tool-dashboard.component.scss'],
})
export class WatchListCreateAddModalComponent implements OnInit {
  public watchListForm: FormGroup;
  @Input() selectedSymbol;
  showInput: boolean;
  selectedWatchListIds: string[] = [];
  selectedSymbols: string[] = [];
  watchList: any;
  watchListCount: number = 0;

  constructor(
    public modalController: ModalController,
    private fb: FormBuilder,
    private toolsApi: ToolServiceService
  ) {}

  ngOnInit() {
    this.watchListForm = this.fb.group({
      watchListId: '',
      name: '',
      symbol: this.selectedSymbol,
    });
    this.onListForWatchList();
  }

  onListForWatchList() {
    this.toolsApi.getForWatchList().subscribe((response: any) => {
      if (response && response?.data) {
        this.watchList = response?.data.map((item) => ({
          ...item,
          selected: item.symbols.includes(this.selectedSymbol),
        }));
      }
    });
  }

  close() {
    this.modalController.dismiss(null);
  }

  createWatchlist() {
    this.showInput = !this.showInput;
  }

  onWatchListSelect(selectedWatchList) {
    const index = this.selectedWatchListIds.indexOf(selectedWatchList._id);
    const symbolIndex = this.selectedSymbols.indexOf(this.selectedSymbol);
    if (selectedWatchList.selected) {
      if (index === -1) {
        this.selectedWatchListIds.push(selectedWatchList._id);
        this.selectedSymbols.push(this.selectedSymbol);
      }
    } else {
      if (index > -1) {
        this.selectedWatchListIds.splice(index, 1);
      }
      if (symbolIndex > -1) {
        this.selectedSymbols.splice(symbolIndex, 1);
      }
    }
    this.watchListCount = this.selectedWatchListIds.length;
  }

  onSubmit() {
    if (this.watchListForm.valid) {
      const { value: form } = this.watchListForm;
      const selectedWatchListIds = this.watchList
        .filter((item) => item.selected)
        .map((item) => item._id);
      const addUpdateclone: any = {
        name: form.name,
        symbol: form.symbol,
        watchListIds: selectedWatchListIds,
        watchListCount: selectedWatchListIds.length,
      };
      this.modalController.dismiss(addUpdateclone);
    }
  }
}
