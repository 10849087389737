import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GalleryFilesService } from 'src/app/services/gallery-files-service.service';
import { GalleryFile } from 'src/app/models/gallery-file';
import { FileType } from 'src/app/models/enum.file-types';

@Component({
  selector: 'app-gallery-move-directory',
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button (click)="dismissModal()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Directory Selection</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-row class="content-row">
        <ion-col size-xl="12" size-lg="12">
          <ul class="folder-tree" appScrollbar [directInject]="true">
            <ng-container
              *ngTemplateOutlet="recursiveListTmpl; context: { list: folderDirs }"
            ></ng-container>
          </ul>
          <ng-template #recursiveListTmpl let-list="list">
            <li
              *ngFor="let item of list"
              (click)="$event.stopPropagation(); dirSelection(item)"
              [ngClass]="{ expanded: item.selected }"
            >
              <div
                class="dir-container"
                [ngClass]="{
                  'selected-directory': currentFolder && currentFolder._id === item._id
                }"
              >
                <div class="arrow" *ngIf="item.childrenCount > 0">
                  <svg
                    _ngcontent-bsq-c263=""
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="5 0 27 26"
                    id="arrow_down"
                    data-name="arrow down"
                  >
                    <defs _ngcontent-bsq-c263="">
                      <clipPath _ngcontent-bsq-c263="" id="clip-path">
                        <rect
                          _ngcontent-bsq-c263=""
                          id="Mask"
                          width="20"
                          height="20"
                          fill="none"
                          stroke="#000"
                          stroke-width="1"
                        ></rect>
                      </clipPath>
                    </defs>
                    <g
                      _ngcontent-bsq-c263=""
                      id="_20x20_arrow-back--grey"
                      data-name="20x20/arrow-back--grey"
                      transform="translate(0 18) rotate(-90)"
                    >
                      <rect
                        _ngcontent-bsq-c263=""
                        id="Mask-2"
                        data-name="Mask"
                        width="24"
                        height="24"
                        fill="none"
                      ></rect>
                      <g
                        _ngcontent-bsq-c263=""
                        id="_20x20_arrow-back--grey-2"
                        data-name="20x20/arrow-back--grey"
                        clip-path="url(#clip-path)"
                      >
                        <g
                          _ngcontent-bsq-c263=""
                          id="Group_2"
                          data-name="Group 2"
                          transform="translate(8 7)"
                        >
                          <path
                            _ngcontent-bsq-c263=""
                            id="Rectangle"
                            d="M0,6.586V0H6.586"
                            transform="translate(0.686 5.157) rotate(-45)"
                            fill="none"
                            stroke="#000"
                            stroke-miterlimit="10"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <img class="fa-folder" [src]="iconFolder" />
                <span class="folder-name">{{ item.title }}</span>
              </div>
              <ul *ngIf="item.children && item.children.length > 0">
                <ng-container
                  *ngTemplateOutlet="recursiveListTmpl; context: { list: item.children }"
                ></ng-container>
              </ul>
            </li>
          </ng-template>
        </ion-col>
      </ion-row>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button
            size="medium"
            fill="solid"
            color="warning"
            *ngIf="currentFolder"
            (click)="onDirectorySelection()"
            >Select ({{ currentFolder.title }})</ion-button
          >
          <ion-button
            size="medium"
            fill="solid"
            color="warning"
            *ngIf="!currentFolder"
            (click)="onDirectorySelection()"
            >Select (Root)</ion-button
          >
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  `,
  styleUrls: ['./gallery.component.scss'],
})
export class MoveDirectoryPage {
  @Input() selectedFile;
  public currentFolder: GalleryFile;
  public readonly iconFolder = GalleryFile.iconFolder;
  public dirsArray = [];
  public folderDirs = [];

  constructor(
    private galleryService: GalleryFilesService,
    public modalController: ModalController
  ) {
    this.currentFolder = null;
    this.getFilesOnCurrentPage();
  }

  onDirectorySelection() {
    const previousParent = this.selectedFile.parent;
    const file = { ...this.selectedFile };

    if (this.currentFolder) {
      file.parent = this.currentFolder._id;
      this.modalController.dismiss({
        file,
        current: this.currentFolder,
        previousParent,
        dirs: this.dirsArray,
      });
    } else {
      file.parent = null;
      this.modalController.dismiss({ file, current: null, previousParent, dirs: this.dirsArray });
    }
  }

  navigateBackTo(folder: GalleryFile) {
    if (
      folder.fileType !== FileType.Folder ||
      (this.currentFolder !== null && this.currentFolder._id === folder._id)
    ) {
      return;
    }
    this.currentFolder = folder;
    this.getFilesOnCurrentPage();
  }

  getFilesOnCurrentPage() {
    this.galleryService.getDirectoryFiles('', this.currentFolder).subscribe((response: any) => {
      if (response) {
        const folders = response.data.filter((file: any) => file.fileType === 'folder');
        const currentSlectedFileIndex = folders.findIndex(
          (i) => i.fileType === 'folder' && i._id === this.selectedFile._id
        );
        if (currentSlectedFileIndex > -1) {
          folders.splice(currentSlectedFileIndex, 1);
        }

        this.folderDirs = this.prepareSidebarDirs(folders, this.currentFolder);
      }
    });
  }

  prepareSidebarDirs = (folders, currentFolder) => {
    if (!this.currentFolder) {
      this.dirsArray = [...folders].map((item: any) => {
        item.selected = false;
        return item;
      });
    } else {
      const sampleArray = [...this.dirsArray, ...folders];
      const ids = sampleArray.map((o) => o._id);
      this.dirsArray = sampleArray
        .filter(({ _id }, index) => !ids.includes(_id, index + 1))
        .map((item: any) => {
          return item;
        });
    }

    const parentLookupUpperlevel = (parent) => {
      if (!parent) {
        return this.dirsArray;
      }

      const crFolderIndex = this.dirsArray.findIndex((item: any) => item._id === parent._id);
      if (crFolderIndex === -1) {
        return this.dirsArray;
      }

      this.dirsArray[crFolderIndex].selected = true;

      if (this.dirsArray[crFolderIndex].parent) {
        const crParentFolderIndex = this.dirsArray.findIndex(
          (item: any) => item._id === this.dirsArray[crFolderIndex].parent
        );
        return parentLookupUpperlevel(this.dirsArray[crParentFolderIndex]);
      }

      return this.dirsArray;
    };

    this.dirsArray = parentLookupUpperlevel(currentFolder);

    const files = [...this.dirsArray];
    const map: any = {};
    let file: any;
    const dirs = [];
    let i;

    for (i = 0; i < files.length; i += 1) {
      map[files[i]._id] = i; // initialize the map
      files[i].children = []; // initialize the children
    }

    for (i = 0; i < files.length; i += 1) {
      file = files[i];

      if (file.parent && map[file.parent] !== -1) {
        files[map[file.parent]].children.push(file);
      } else {
        dirs.push(file);
      }
    }

    return dirs;
  };

  dirSelection(folder) {
    this.navigateBackTo(folder);
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
