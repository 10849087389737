import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TickerAlertNotificationRoutingModule } from './ticker-alert-notification-routing.module';
import { AppDatePickerModule } from '../date-picker/date-picker.module';
import { AlertDatePickerModule } from '../alert-date-picker/alert-date-picker.module';
import { TickerAlertNotificationComponent } from './ticker-alert-notification.component';

@NgModule({
  declarations: [TickerAlertNotificationComponent],
  imports: [
    CommonModule,
    IonicModule,
    TickerAlertNotificationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
    AlertDatePickerModule,
  ],
  exports: [TickerAlertNotificationComponent],
})
export class TickerAlertNotificationModule {}
