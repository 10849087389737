<div class="drawer-header">
  <div class="header-container">
    <div class="back-to"></div>
  </div>
</div>
<ng-container>
  <div class="page-heading">
    <h2>Treasure Hunting Climate</h2>
  </div>
  <div class="form-container">
    <form [formGroup]="climatesForm">
      <div class="form-group slider-rang">
        <label for="value" style="font-size: 18px">Hunting Climate</label>        
       
        <ion-range  aria-label="Custom range"  [min]="0"
  [max]="100"
  [value]="value"
  [pin]="true"
  [ticks]="true"
  [snaps]="true"
  formControlName="value"
></ion-range>
        <div class="slider-values">
          <span>0%</span>
       
          <span>100%</span>
        </div>
      </div>
      <div class="form-group">
        <label style="font-size: 18px" for="notes">Insights</label>
        <textarea
          class="note-description"
          formControlName="description"
          id="description"
          rows="4"
          cols="50"
        ></textarea>
      </div>
      <ion-button
        type="submit"
        color="primary"
        fill="solid"
        style="width: 100%"
        (click)="updateClimate()"
      >
        Update
      </ion-button>
    </form>
  </div>
</ng-container>
