import { Injectable } from '@angular/core';
import { baseUrl } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  userImage = localStorage.getItem('image');
  firstName: string;
  lastName: string;
  email: string;
  constructor(
    private http: HttpClient,
    private route: Router,
    private storageserv: StorageService
  ) {}

  login(params): Observable<any> {
    return this.http.post(`${baseUrl}/auth/login`, params, { observe: 'response' });
  }

  impersonateUser(params): Observable<any> {
    const { token, ...restparams } = params;

    return this.http.post(`${baseUrl}/auth/impersonate/user`, restparams, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + token)
        .set('impersonate-secret', environment.impersonateSecret),
    });
  }

  register(params) {
    return this.http.post(`${baseUrl}/auth/register`, params, { observe: 'response' });
  }

  deleteUser(id) {
    return this.http.delete(`${baseUrl}/auth/delete-user/${id}`);
  }

  async logout(impersonate = false) {
    return new Promise(async (resolve, reject) => {
      this.storageserv.clear();
      this.route.navigate(['login'], {
        queryParams: {
          impersonate: null,
          token: null,
          email: null,
          setSession: null,
          impersonateRole: null,
        },
        queryParamsHandling: 'merge',
      });
      resolve(true);
    });
  }

  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append('Content-Type', 'application/json');
  }

  requestResetPassword(email) {
    return this.http.get(`${baseUrl}/auth/request-reset-password?email=${email}`, {
      headers: new HttpHeaders().set('disableHttpResponseToast', 'true'),
    });
  }

  requestMagicLink(email) {
    return this.http.post(`${baseUrl}/auth/send-magic-link?email=${email}`, {
      headers: new HttpHeaders().set('disableHttpResponseToast', 'true'),
    });
  }

  validateMagicLinkToken(token) {
    return this.http.post(`${baseUrl}/auth/validate-magic-link?token=${token}`, {
      headers: new HttpHeaders().set('disableHttpResponseToast', 'true'),
    });
  }

  validateResetPasswordToken(resetToken) {
    return this.http.get(`${baseUrl}/auth/validate-token?token=${resetToken}`, {
      headers: new HttpHeaders().set('disableHttpResponseToast', 'true'),
    });
  }

  resetPassword(form) {
    return this.http.post(`${baseUrl}/auth/reset-password`, form, {
      headers: new HttpHeaders().set('disableHttpResponseToast', 'true'),
    });
  }
}
