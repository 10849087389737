<ion-item [ngClass]="options.classes" [lines]="options.lines" id="{{ hash }}-open-modal">
  <ion-label *ngIf="label" [position]="options.labelPosition">{{ label }}</ion-label>
  <ion-text slot="start">{{ formattedDateString }}</ion-text>
</ion-item>

<ion-modal class="date-picker-modal" trigger="{{ hash }}-open-modal">
  <ng-template>
    <ion-content>
      <ion-datetime
        [value]="dateValue"
        size="cover"
        #datetime
        [presentation]="presentation"
        showDefaultButtons="true"
        (ionChange)="dateChanged(datetime.value)"
        hourCycle="h12"
        [min]="min"
        displayFormat="MMM DD YYYY, h:mm A"
        pickerFormat="MMM DD YYYY, h:mm A"
        minuteValues="0,15,30,45"
      ></ion-datetime>
    </ion-content>
  </ng-template>
</ion-modal>
