import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-stock-details',
  templateUrl: './stock-details.component.html',
  styleUrls: ['./stock-details.component.scss'],
})
export class StockDetailsComponent implements OnInit {
  lookupListVisibility: boolean = false;
  @Input() watchlistId;
  @Input() selectedSymbol: string[] = [];
  listOfLookup: any[] = [];
  searchResult: any[];
  someProperty: any;
  isLoading = true;
  public filterStockByName: string = null;
  public searchText: string = null;
  sortDirection = 'asc';
  stockDetails: any[];
  selectedStockDetails: any;
  selectedLookup: {
    name: string;
    symbol: string;
    exchange: string;
  } = {
    name: '',
    symbol: '',
    exchange: '',
  };
  buttonStateGroup = {
    is_watch_list: false,
    title: 'Add To Watchlist',
    icon: 'add-outline',
    isButtonActive: false,
    color: 'dark',
  };
  highlightedIndex: number = -1;
  constructor(
    private toolsApi: ToolServiceService,
    private cdr: ChangeDetectorRef,
    public modalController: ModalController,
    private toastServ: ToastService
  ) {}

  ngOnInit() {
    this.isLoading = false;
    /* this.getWatchlistStockDetails(); */
  }
  onSearchLookup(event: CustomEvent) {
    this.lookupListVisibility = true;
    const trimmedSearchValue = event.detail.value.trim().toLowerCase();
    if (trimmedSearchValue.length > 0) {
      const exactMatches = [];
      const otherMatches = [];
      for (const item of this.listOfLookup) {
        const value = `${item.symbol} - ${item.name}`.toLowerCase();
        if (value.startsWith(trimmedSearchValue)) {
          exactMatches.push(item);
        } else if (value.includes(trimmedSearchValue)) {
          otherMatches.push(item);
        }
      }
      this.searchResult = [...exactMatches, ...otherMatches];
      this.highlightedIndex = -1;
    } else {
      this.lookupListVisibility = false;
      this.selectedLookup = {
        symbol: '',
        name: '',
        exchange: '',
      };
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (this.lookupListVisibility && this.searchResult.length > 0) {
      switch (event.key) {
        case 'ArrowDown':
          if (this.highlightedIndex < this.searchResult.length - 1) {
            this.highlightedIndex++;
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          if (this.highlightedIndex > 0) {
            this.highlightedIndex--;
          }
          event.preventDefault();
          break;
        case 'Enter':
          if (this.highlightedIndex >= 0) {
            this.addLookup(this.searchResult[this.highlightedIndex].symbol);
          } else if (this.searchResult.length > 0) {
            this.addLookup(this.searchResult[0].symbol);
          }
          event.preventDefault();
          break;
      }
    }
  }

  addLookup(symbol: string) {
    this.hideLookupList();
    this.onClearSearch();
    this.toolsApi.setContext('dashboard');
  }

  hideLookupList() {
    this.lookupListVisibility = false;
    this.selectedLookup = {
      name: '',
      symbol: '',
      exchange: '',
    };
  }

  onClearSearch() {
    this.searchText = null;
    this.stockDetails = [];
  }

  onSearchCategory(ev: CustomEvent, type: string) {
    this.searchText = type === 'filter' && ev.detail.value === 'All' ? null : ev.detail.value;
    if (this.searchText) {
      this.isLoading = true;
      this.getWatchlistStockDetails();
    } else {
      this.stockDetails = [];
    }
  }

  getWatchlistStockDetails() {
    if (this.filterStockByName) {
      this.searchText = this.filterStockByName;
    }
    if (this.searchText) {
      this.toolsApi.getWatchListStockDetails(this.searchText).subscribe((response: any) => {
        this.isLoading = true;
        if (response && response?.data) {
          this.stockDetails = response?.data;
          this.isLoading = false;
        } else {
          this.stockDetails = [];
        }
        this.isLoading = false;
      });
    } else {
      this.stockDetails = [];
      this.isLoading = false;
    }
  }

  sortDataPrice() {
    const sortedData = [...this.stockDetails].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketPrice - b.regularMarketPrice;
      } else {
        return b.regularMarketPrice - a.regularMarketPrice;
      }
    });
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.stockDetails = sortedData;
  }

  sortDataChangePer() {
    const sortedData = [...this.stockDetails].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketChangePercent - b.regularMarketChangePercent;
      } else {
        return b.regularMarketChangePercent - a.regularMarketChangePercent;
      }
    });
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.stockDetails = sortedData;
  }

  sortDataChange() {
    const sortedData = [...this.stockDetails].sort((a, b) => {
      if (this.sortDirection === 'asc') {
        return a.regularMarketChange - b.regularMarketChange;
      } else {
        return b.regularMarketChange - a.regularMarketChange;
      }
    });
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.stockDetails = sortedData;
  }

  close() {
    window.location.reload();
    this.modalController.dismiss(null);
  }

  toggleWatchlist(row: any) {
    const payload = {
      name: '',
      symbol: row.symbol,
      watchListIds: [this.watchlistId],
    };
    const isSelected = this.selectedSymbol.includes(row.symbol);
    if (!row.isInWatchlist && !isSelected) {
      this.toolsApi.addWatchList(payload).subscribe((response: any) => {
        if (response && response.message) {
          this.toastServ.presentToast(response.message, 'success');
          row.isInWatchlist = true;
        }
      });
    } else if (row.isInWatchlist || isSelected) {
      this.toolsApi.removeFromWatchList(row.symbol).subscribe((response: any) => {
        if (response && response.message) {
          this.toastServ.presentToast(response.message, 'success');
          row.isInWatchlist = false;
          this.selectedSymbol = this.selectedSymbol.filter((symbol) => symbol !== row.symbol);
        }
      });
    }
  }

  getHeaderClassTodayChange({ row }): any {
    return {
      'custom-green': Number(row?.regularMarketChange) >= 0,
      'custom-red': Number(row?.regularMarketChange) < 0,
      'custom-black': Number(row?.regularMarketChange) === 0,
    };
  }

  getHeaderClassTodayChangePer({ row }): any {
    return {
      'custom-green': Number(row?.regularMarketChangePercent) >= 0,
      'custom-red': Number(row?.regularMarketChangePercent) < 0,
      'custom-black': Number(row?.regularMarketChangePercent) === 0,
    };
  }
}
