import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { GravatarModule } from 'ngx-gravatar';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { InAppNotificationRoutingModule } from './in-app-notification-routing.module';
import { InAppNotificationComponent } from './in-app-notifications.component';

@NgModule({
  declarations: [InAppNotificationComponent],
  imports: [CommonModule, IonicModule, GravatarModule, PipesModule, InAppNotificationRoutingModule],
  exports: [InAppNotificationComponent],
})
export class InAppNotificationModule {}
