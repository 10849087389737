import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public putObject(key, object) {
    return localStorage.setItem(key, JSON.stringify(object));
  }

  public getObject(key) {
    const values = localStorage.getItem(key);
    try {
      return values ? JSON.parse(values) : null;
    } catch (error) {
      return values;
    }
  }

  public set(key, value) {
    return localStorage.setItem(key, value);
  }

  public get(key) {
    return localStorage.getItem(key);
  }

  public async remove(key) {
    return localStorage.removeItem(key);
  }

  public clear() {
    return localStorage.clear();
  }
}
