import { NgModule } from '@angular/core';
import { ScrollbarDirective } from './scrollbar/scrollbar.directive';
import { TitleInputDirective } from './title-input/title-input.directive';
import { CustomScrollDirective } from './custom-scroll/custom-scroll.directive';
import { CachedSrcDirective } from './iframe-binding/iframe.directive';
import { ImgDirective } from './image-binding/img.directive';
import { ElementInitDirective } from './element-init/element-init.directive';
import { TextHighlightDirective } from './text-highlight/text-highlight.directive';

@NgModule({
  declarations: [
    ScrollbarDirective,
    TitleInputDirective,
    CustomScrollDirective,
    CachedSrcDirective,
    ImgDirective,
    ElementInitDirective,
    TextHighlightDirective,
  ],
  imports: [],
  exports: [
    ScrollbarDirective,
    TitleInputDirective,
    CustomScrollDirective,
    CachedSrcDirective,
    ImgDirective,
    ElementInitDirective,
    TextHighlightDirective,
  ],
})
export class WalkwelDirectiveModules {}
