import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ClimateService } from 'src/app/services/climate.service';
import { ToastService } from 'src/app/services/toast.service';
import { Options } from '@angular-slider/ngx-slider';
@Component({
  selector: 'app-climates-update',
  templateUrl: './climates-update.component.html',
  styleUrls: ['./climates-update.component.scss'],
})
export class ClimatesUpdateComponent implements OnInit {

  // valuenw: number = 100;
  sliderOptions: any;

  climatesForm: FormGroup;
  @Input() climatesData: any;
  mode;
  constructor(
    private fb: FormBuilder,
    public menuController: MenuController,
    private climateService: ClimateService,
    private menuService: commonRightMenuService,
    private toastServ: ToastService
  ) {
    this.climatesForm = this.fb.group({
      description: [''],
      value: [0],
    });
    this.sliderOptions = {
      floor: 0,
      ceil: 100,
      step: 1,
      // Add other options as needed
    };
    
  }

  ngOnInit() {
    

    this.climatesForm.get('value')!.valueChanges.subscribe((newValue) => {});
    if (this.climatesData.mode === 'edit') {
      this.setInitData();
    }
  }

  get value() {  
    // this.climatesForm.get('value')?.valueChanges.subscribe(val => {
    //   this.valuenw = val; // Update the displayed value
    // });                     
    return this.climatesForm.get('value')!.value; // Getter to access the value in the template
   
  }

  setInitData() {
    this.climatesForm.reset();
    if (this.climatesData) {
      this.climatesForm.patchValue({
        value: this.climatesData.editData[0].value,
        description: this.climatesData.editData[0].description,
        _id: this.climatesData.editData[0]._id,
      });
    }
  }

  updateClimate() {
    const description = this.climatesForm.get('description').value;
    const value = this.climatesForm.get('value').value;
    const _id = this.climatesData.editData[0]._id;
    const updatePayload = {
      value: value,
      description: description,
    };
    if (this.climatesData.mode == 'edit') {
      this.climateService.updateClimates(_id, updatePayload).subscribe((res: any) => {
        if (res) {
          this.climatesForm.patchValue({
            value: value,
            description: description,
          });
          this.menuController.close('updatePayload');
          this.cancel('false');
          this.toastServ.presentToast(res?.message, 'success');
          this.menuService.triggerChildClimateReset(value, description);
        }
      });
    }
  }

  cancel(isNotify = 'true') {
    this.menuController.close();
    if (isNotify === 'true') {
      this.menuService.setCloseMenuSubject({});
    }
  }
}
