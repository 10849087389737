import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { ClimateService } from 'src/app/services/climate.service';

@Component({
  selector: 'app-climate-details',
  templateUrl: './climate-details.component.html',
  styleUrls: ['./climate-details.component.scss'],
})
export class ClimateDetailsComponent implements OnInit {
  climateData: any;
  iframeSrc!: SafeResourceUrl;
  constructor(
    public modalController: ModalController,
    private climateService: ClimateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getClimateData();
  }

  close() {
    this.modalController.dismiss(null);
  }

  getClimateData() {
    this.climateService.getAllClimate().subscribe((response: any) => {
      if (response && response?.data) {
        this.climateData = response?.data?.climates;
        const value = this.climateData[0]?.value;
        const dynamicUrl = `https://tradersedgenetwork.com/dynamic_image_creater/guage/combine-image.html?value=${value}`;
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(dynamicUrl);
      }
    });
  }
}
