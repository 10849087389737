import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loader: HTMLIonLoadingElement;
  constructor(public loadingController: LoadingController) {}

  async showLoader(message: string) {
    const loader = await this.loadingController.create({
      spinner: 'dots',
      message,
      cssClass: 'custom-loader-container',
    });
    return loader.present();
  }

  async HideLoader() {
    this.loadingController.getTop().then((topLoader) => {
      if (topLoader) {
        this.loadingController.dismiss();
      }
    });
  }
}
