import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-header-notifications-modal',
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button (click)="close()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>NOTIFICATIONS</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="header-notification notification-page-container">
        <div class="notification-body">
          <div class="notification-empty" *ngIf="notifications.length === 0">
            No recent notifications
          </div>
          <div
            class="notification"
            appScrollbar
            [directInject]="true"
            [ngClass]="{ 'seen-notification': notification.isRead }"
            (click)="notificationRedirect(notification.hrefLink, notification.id)"
            *ngFor="let notification of notifications"
          >
            <div [ngSwitch]="notification.type">
              <div *ngSwitchCase="'ChatComment'">
                <div class="left-section">
                  <div class="user-profile">
                    <img
                      ngxGravatar
                      [email]="notification.commentAuthor.email"
                      size="45"
                      src="{{ notification.commentAuthor.image }}"
                    />
                  </div>
                  <div class="notification-info chat-notification-info">
                    <div
                      class="message-container"
                      [innerHtml]="notification.message | keepHtml"
                    ></div>
                    <div
                      class="comment"
                      *ngIf="notification.comment"
                      [innerHtml]="notification.comment.commentText | keepHtml"
                    ></div>
                    <div class="time">{{ notification.dateTime | moment }}</div>
                  </div>
                </div>
                <div class="read-status" *ngIf="!notification.isRead"></div>
              </div>
              <div *ngSwitchCase="'Broadcast'">
                <div class="left-section">
                  <div class="broadcast-logo">
                    <div class="image-container">
                      <img src="/assets/icon/broadcast-speaker.png" />
                    </div>
                  </div>
                  <div class="notification-info broadcast-notification-info">
                    <div class="broadcast-title">New Broadcast Message</div>
                    <div class="broadcast-subject">{{ notification.subject }}</div>
                    <div
                      class="broadcast-message"
                      [innerHtml]="notification.message | keepHtml"
                    ></div>
                    <div class="time">{{ notification.dateTime | moment }}</div>
                  </div>
                </div>
                <div class="read-status" *ngIf="!notification.isRead"></div>
              </div>
              <div *ngSwitchCase="'InsiderChat'">
                <div class="left-section">
                  <div class="user-profile">
                    <img
                      ngxGravatar
                      [email]="notification.threadAuthor.email"
                      size="45"
                      src="{{ notification.threadAuthor.image }}"
                    />
                  </div>
                  <div class="notification-info chat-notification-info">
                    <div
                      class="message-container"
                      [innerHtml]="notification.message | keepHtml"
                    ></div>
                    <div
                      class="comment"
                      *ngIf="notification.thread"
                      [innerHtml]="notification.thread.threadText | keepHtml"
                    ></div>
                    <div class="time">{{ notification.dateTime | moment }}</div>
                  </div>
                </div>
                <div class="read-status" *ngIf="!notification.isRead"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer class="ion-no-border" *ngIf="notifications.length > 0">
      <ion-button fill="solid" color="primary" expand="full" (click)="viewAllNotifications()"
        >View All Notifications</ion-button
      >
    </ion-footer>
  `,
  styleUrls: ['./common.component.scss'],
})
export class NotificationsModalPage {
  @Input() notifications = [];
  @Input() notificationBedgeCount = 0;
  @Input() triggerMarkAllRead;
  @Input() triggerNotificationRedirect;
  @Input() triggerViewAllNotifications;

  constructor(public modalController: ModalController) {}

  markAllRead() {
    this.close();
    this.triggerMarkAllRead();
  }

  notificationRedirect(link, id) {
    this.close();
    this.triggerNotificationRedirect(link, id);
  }

  viewAllNotifications() {
    this.close();
    this.triggerViewAllNotifications();
  }

  close() {
    this.modalController.dismiss();
  }
}
