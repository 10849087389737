<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="requestGalleryClose()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Gallery Manager</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="gallery-body">
    <div class="ten-gallery-controls">
      <div class="tab-picker">
        <ion-button [color]="currentTab === 'list' ? 'primary' : 'light'" (click)="pickTab('list')">
          Gallery
        </ion-button>
        <ion-button
          [color]="currentTab === 'trash' ? 'primary' : 'light'"
          (click)="pickTab('trash')"
        >
          Trash
        </ion-button>
      </div>
      <div class="tab-picker" *ngIf="currentTab === 'trash' && trashFiles && trashFiles.length > 0">
        <ion-button color="danger" (click)="clearTrash()">
          <ion-icon slot="start" name="trash"></ion-icon>
          Clear Trash
        </ion-button>
      </div>
    </div>
    <div class="tab normal-tab" *ngIf="currentTab === 'list'">
      <div class="gl-toolbar">
        <div class="gl-toolbar-left">
          <ion-buttons slot="primary">
            <ion-button fill="clear" color="medium" (click)="createNewFolder()">
              <ion-icon slot="start" name="add-outline"></ion-icon>
              New Folder
            </ion-button>
            <ion-button fill="clear" color="medium" (click)="openUploadModal()">
              <ion-icon slot="start" name="cloud-upload-outline"></ion-icon>
              Upload
            </ion-button>
            <ion-button fill="clear" color="medium" (click)="navigateBackHome()">
              <ion-icon slot="start" name="home-outline"></ion-icon>
              Home
            </ion-button>
          </ion-buttons>
        </div>
      </div>
      <div class="file-manager">
        <div class="files-container">
          <div class="file-sidebar">
            <ul class="folder-tree" appScrollbar [directInject]="true">
              <ng-container
                *ngTemplateOutlet="recursiveListTmpl; context: { list: folderDirs }"
              ></ng-container>
            </ul>
            <ng-template #recursiveListTmpl let-list="list">
              <li
                *ngFor="let item of list"
                (click)="$event.stopPropagation(); dirSelection(item)"
                [ngClass]="{ expanded: item.selected }"
              >
                <div
                  class="dir-container"
                  [ngClass]="{
                    'selected-directory': currentFolder && currentFolder._id === item._id
                  }"
                >
                  <div class="arrow" *ngIf="item.childrenCount > 0">
                    <svg
                      _ngcontent-bsq-c263=""
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="5 0 27 26"
                      id="arrow_down"
                      data-name="arrow down"
                    >
                      <defs _ngcontent-bsq-c263="">
                        <clipPath _ngcontent-bsq-c263="" id="clip-path">
                          <rect
                            _ngcontent-bsq-c263=""
                            id="Mask"
                            width="20"
                            height="20"
                            fill="none"
                            stroke="#000"
                            stroke-width="1"
                          ></rect>
                        </clipPath>
                      </defs>
                      <g
                        _ngcontent-bsq-c263=""
                        id="_20x20_arrow-back--grey"
                        data-name="20x20/arrow-back--grey"
                        transform="translate(0 18) rotate(-90)"
                      >
                        <rect
                          _ngcontent-bsq-c263=""
                          id="Mask-2"
                          data-name="Mask"
                          width="24"
                          height="24"
                          fill="none"
                        ></rect>
                        <g
                          _ngcontent-bsq-c263=""
                          id="_20x20_arrow-back--grey-2"
                          data-name="20x20/arrow-back--grey"
                          clip-path="url(#clip-path)"
                        >
                          <g
                            _ngcontent-bsq-c263=""
                            id="Group_2"
                            data-name="Group 2"
                            transform="translate(8 7)"
                          >
                            <path
                              _ngcontent-bsq-c263=""
                              id="Rectangle"
                              d="M0,6.586V0H6.586"
                              transform="translate(0.686 5.157) rotate(-45)"
                              fill="none"
                              stroke="#000"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <img class="fa-folder" [src]="iconFolder" />
                  <span class="folder-name">{{ item.title }}</span>
                </div>
                <ul *ngIf="item.children && item.children.length > 0">
                  <ng-container
                    *ngTemplateOutlet="recursiveListTmpl; context: { list: item.children }"
                  ></ng-container>
                </ul>
              </li>
            </ng-template>
            <ul *ngIf="folderDirs.length === 0" class="folder-tree">
              <li>Folder is Empty</li>
            </ul>
            <div class="total-files-info">
              <small class="text-smallest"
                >Files : <span>{{ totalFiles }}</span></small
              >
            </div>
          </div>
          <div class="file-list" appScrollbar [directInject]="true">
            <div class="file-breadcrumb">
              <ul class="breadcrumb-ul">
                <span (click)="navigateBackHome()">Home</span>
                <span
                  *ngFor="let folder of folders; let i = index"
                  (click)="navigateBackTo(folder)"
                  >{{ folder.title }}</span
                >
              </ul>
              <ion-searchbar
                search-icon="search-outline"
                debounce="1000"
                class="ion-no-padding gallery-seachbar"
                (ngModelChange)="performSearch($event)"
                inputmode="text"
                ngDefaultControl
                [(ngModel)]="searchTerm"
                name="search"
                placeholder="Search"
              >
              </ion-searchbar>
            </div>
            <table class="file-table-list" *ngIf="files && files.length > 0; else noFilesToShow">
              <tr class="file-list__header">
                <th>Name</th>
                <th>Type</th>
                <th>Last Modified</th>
                <th>Action</th>
              </tr>
              <tr
                (click)="filePickedAndChosen(file)"
                *ngFor="let file of files"
                class="file-list__file"
              >
                <td>
                  <div
                    class="file-item"
                    [ngClass]="{
                      selected: selectedFile && selectedFile._id === file._id,
                      file: true
                    }"
                    [title]="file.title"
                  >
                    <img [src]="file.src" [alt]="file.title" />
                    <span class="image-title">{{ file.title }}</span>
                  </div>
                </td>
                <td>
                  {{ file.fileType }}
                </td>
                <td>
                  {{ file.updatedAt | date : 'MMMM d, yyyy hh:mm a' }}
                </td>
                <td>
                  <div class="gl-toolbar-item" aria-disabled="false" title="View">
                    <ion-buttons slot="end">
                      <ion-button
                        color="medium"
                        (click)="$event.stopPropagation(); selectFile(file)"
                      >
                        <ion-icon slot="icon-only" name="settings-outline"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                  </div>
                </td>
              </tr>
            </table>
            <ng-template #noFilesToShow>
              <div class="no-files">
                No files available. <span (click)="openUploadModal()">Try uploading files?</span>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="tab trash-tab" *ngIf="currentTab === 'trash'">
      <div class="file-manager">
        <div class="files-container">
          <div class="file-list">
            <table
              class="file-table-list"
              *ngIf="trashFiles && trashFiles.length > 0; else noFilesToShow"
            >
              <tr class="file-list__header">
                <th>#</th>
                <th>Name</th>
                <th>Original Location</th>
                <th>Trashed On</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let file of trashFiles; let iDx = index" class="file-list__file">
                <td>{{ iDx + 1 }}</td>
                <td>
                  <div class="file" [title]="file.title">
                    <img [src]="file.src" [alt]="file.title" />
                    <span class="image-title">{{ file.title }}</span>
                  </div>
                </td>
                <td>
                  {{ file.parentPath }}
                </td>
                <td>
                  {{ file.updatedAt | date : 'MMMM d, yyyy hh:mm a' }}
                </td>
                <td>
                  <div class="gl-toolbar-item" aria-disabled="false" title="Restore">
                    <ion-buttons slot="end">
                      <ion-button
                        style="transform: scaleX(-1)"
                        title="Restore"
                        color="success"
                        (click)="restoreDocument(file)"
                      >
                        <ion-icon slot="icon-only" name="reload-outline"></ion-icon>
                      </ion-button>
                      <ion-button
                        title="Delete Permanently"
                        color="danger"
                        (click)="permanentlyDeleteDocument(file)"
                      >
                        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                  </div>
                </td>
              </tr>
            </table>
            <ng-template #noFilesToShow>
              <div class="no-files">Trash is Empty!</div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
