import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-header-profilemenu-popover',
  template: `
    <ion-content>
      <ul class="profile-setting-dropdown">
        <li class="dropdown-header">
          <h6>{{ username }}</h6>
          <span>
            <ion-badge color="warning">
              {{ role === 'user' ? 'Hub Member' : role === 'moderator' ? 'Veteran' : 'Crew' }}
            </ion-badge>
          </span>
        </li>
        <li class="dropdown-menu-item" (click)="goToSettings()">
          <ion-icon name="person-outline"></ion-icon><span>Account Settings</span>
        </li>
        <li class="dropdown-menu-item" (click)="navigateUrl('/notifications')">
          <ion-icon name="notifications-outline"></ion-icon><span>Notifications</span>
        </li>
        <li
          (click)="impersonateModeSignin()"
          class="dropdown-menu-item"
          *ngIf="
            role &&
            (role === 'admin' || role === 'staff') &&
            (!impersonateModeEnabled || impersonateModeEnabled === 'false')
          "
        >
          <ion-icon name="log-in-outline"></ion-icon><span>Login As User</span>
        </li>
        <li
          *ngIf="role && (role === 'admin' || role === 'staff')"
          class="dropdown-menu-item"
          (click)="navigateUrl('/is-authorization')"
        >
          <ion-icon name="finger-print-outline"></ion-icon><span>Authorize Infusionsoft</span>
        </li>
        <li
          *ngIf="!impersonateModeEnabled || impersonateModeEnabled === 'false'"
          class="dropdown-menu-item"
          (click)="logout()"
        >
          <ion-icon name="log-out-outline"></ion-icon><span>Sign Out</span>
        </li>
        <li
          *ngIf="impersonateModeEnabled && impersonateModeEnabled === 'true'"
          class="dropdown-menu-item"
          (click)="logoutImporsonateMode()"
        >
          <ion-icon name="log-out-outline"></ion-icon><span>Sign Out</span>
        </li>
      </ul>
    </ion-content>
  `,
  styleUrls: ['./common.component.scss'],
})
export class ProfileMenuPopoverPage {
  @Input() username;
  @Input() role;
  @Input() impersonateModeEnabled;
  @Input() triggerGoToSettings;
  @Input() triggerImpersonateModeSignin;
  @Input() triggerNavigateUrl;
  @Input() triggerLogout;
  @Input() triggerLogoutImporsonateMode;

  constructor(private popoverCtrl: PopoverController) {}

  goToSettings() {
    this.close();
    this.triggerGoToSettings();
  }

  impersonateModeSignin() {
    this.close();
    this.triggerImpersonateModeSignin();
  }

  navigateUrl(url) {
    this.close();
    this.triggerNavigateUrl(url);
  }

  logout() {
    this.close();
    this.triggerLogout();
  }

  logoutImporsonateMode() {
    this.close();
    this.triggerLogoutImporsonateMode();
  }

  close() {
    this.popoverCtrl.dismiss();
  }
}
