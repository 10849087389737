import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { ErrorsService } from '../services/errors.service';
import { LoginService } from '../services/login.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    public toastController: ToastService,
    public errorServ: ErrorsService,
    private authService: LoginService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        let disableToast = false;
        if (
          request.headers.get('disableHttpResponseToast') &&
          request.headers.get('disableHttpResponseToast') === 'true'
        ) {
          disableToast = true;
        }

        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else if (!!error.error && !!error.error.info) {
          this.errorServ.onNotify(error.error);
          const tempErrorMessages = {};
          error.error.info.forEach((propError) => {
            tempErrorMessages[propError.property] = Object.values(propError.constraints).join(
              ' and '
            );
          });
          errorMsg = `${error.status}: ${Object.values(tempErrorMessages).join('\n')}`;
        } else if (!!error.error && error.error.message) {
          errorMsg = `${error.status}:  ${error.error.message}`;
        } else {
          errorMsg = `${error.status}:  ${error.message}`;
        }

        if (error.status === 401) {
          this.authService.logout();
        }

        if (errorMsg && !disableToast) {
          this.toastController.presentToast(errorMsg, 'danger');
        }

        if (errorMsg) {
          return throwError(errorMsg);
        }
      })
    );
  }
}
