<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <img class="app-logo header-logo" src="assets/icon/team-hub-logo.png" alt="" />
        </ion-toolbar>
      </ion-header>

      <ion-content appScrollbar>
        <div *ngFor="let p of getSortedPages()">
          <!-- normal menu without childrens -->
          <ion-menu-toogle *ngIf="p.url">
            <ion-item
              class="parent-menu"
              lines="none"
              (click)="p.callback && p.callback()"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="p.url"
              routerDirection="root"
              routerLinkActive="active"
              detail="false"
            >
              <ng-container *ngIf="p.icon.includes('/icons'); else defaultIconTemplate">
                <img [src]="p.icon" />
              </ng-container>
              <ng-template #defaultIconTemplate>
                <ion-icon [name]="p.icon" slot="start"></ion-icon>
              </ng-template>

              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toogle>
          <!-- menu without url and childrens -->
          <ion-menu-toogle *ngIf="!p.url && !p.childrens">
            <ion-item
              class="parent-menu"
              lines="none"
              button
              (click)="p.callback && p.callback()"
              detail="false"
            >
              <ng-container *ngIf="p.icon.includes('/icons'); else defaultIconTemplate">
                <img [src]="p.icon" />
              </ng-container>
              <ng-template #defaultIconTemplate>
                <ion-icon [name]="p.icon" slot="start"></ion-icon>
              </ng-template>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toogle>
          <!-- menu with childrens -->
          <ion-item
            class="parent-menu"
            lines="none"
            button
            *ngIf="p.childrens?.length > 0"
            (click)="p.open = !p.open; p.callback && p.callback()"
            [class.active-parent]="p.open"
            detail="false"
          >
            <ng-container *ngIf="p.icon.includes('/icons'); else defaultIconTemplate">
              <img [src]="p.icon" />
            </ng-container>
            <ng-template #defaultIconTemplate>
              <ion-icon [name]="p.icon" slot="start"></ion-icon>
            </ng-template>
            <ion-label>{{ p.title }}</ion-label>
            <ion-icon name="chevron-forward-outline" size="small" *ngIf="!p.open"></ion-icon>
            <ion-icon name="chevron-down-outline" size="small" *ngIf="p.open"></ion-icon>
            <!-- channel update dot -->
            <div *ngIf="p.slug && p.slug === 'chat' && !p.open">
              <span [ngClass]="{ 'update-channels-bedge': channelUpdates === true }"></span>
            </div>
          </ion-item>
          <!-- childrens -->
          <ion-list *ngIf="p.open" class="ion-no-padding">
            <ion-menu-toogle>
              <ion-item
                class="sub-item"
                [ngClass]="{
                  'spend-item': sub.title === 'Spend',
                  'website-category-item': sub.title === 'Category',
                  'lead-source-item': sub.title === 'Lead Source'
                }"
                *ngFor="let sub of p.childrens"
                lines="none"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="sub.url"
                routerDirection="root"
                routerLinkActive="active"
                detail="false"
              >
                <ion-label>{{ sub.title }}</ion-label>
                <!-- channel message count -->
                <ion-badge
                  *ngIf="sub.source && sub.source.count && sub.source.count > 0"
                  class="bedge"
                  color="primary"
                  slot="end"
                >
                  {{ sub.source.count }}</ion-badge
                >
              </ion-item>
            </ion-menu-toogle>
          </ion-list>
        </div>
      </ion-content>
      <div class="footer-logo">
        <h6>Powered By</h6>
        <img class="app-logo" src="assets/icon/tradersedgenetwork-transparent.png" alt="" />
        <h6 class="app-version">Version : {{ appVersion }}</h6>
      </div>
    </ion-menu>

    <div class="ion-page" id="main-content">
      <app-common></app-common>
      <ion-content>
        <ion-router-outlet> </ion-router-outlet>
      </ion-content>
    </div>
  </ion-split-pane>

  <ion-menu side="end" class="alert-menu-width" contentId="main-content">
    <ion-content class="ion-padding">
      <app-common-right-menu></app-common-right-menu>
    </ion-content>
  </ion-menu>
</ion-app>
