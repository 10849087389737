import { Injectable } from '@angular/core';
import { baseUrl } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  headers = new Headers();
  token: any;

  constructor(private http: HttpClient, private storagesrv: StorageService) {}

  getAllSetting() {
    return this.http.get(`${baseUrl}/setting/`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  createSetting(formData) {
    return this.http.post(`${baseUrl}/setting/`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  editSetting(settingSlug, formData) {
    return this.http.put(`${baseUrl}/setting/${settingSlug}`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  deleteSetting(settingId) {
    return this.http.delete(`${baseUrl}/setting/${settingId}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
}
