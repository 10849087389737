import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CommonRightMenuRoutingModule } from './common-right-menu-routing.module';
import { CommonRightMenuComponent } from './common-right-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from '../date-picker/date-picker.module';
import { TradeTrackerModule } from 'src/app/scenes/user/tools/trade-trackers/trade-trackers.module';
import { TickerAlertsModule } from '../ticker-alerts/ticker-alerts.module';
import { NotesModule } from '../notes/notes.module';
import { HelpSectionModule } from 'src/app/layouts/components/help-section/help-section.module';
import { WatchListModule } from 'src/app/scenes/user/tools/watch-list/watch-list.module';
import { SetupScannerModule } from 'src/app/scenes/user/tools/setup-scanner/setup-scanner.module';
import { ToolNotesModule } from 'src/app/scenes/user/tools/tool-dashboard/tool-notes/tool-notes.module';
import { ClimateModule } from 'src/app/scenes/administrator-common/climate/climate.module';
import { TickerAlertNotificationModule } from '../ticker-alert-notification/ticker-alert-notification.module';

@NgModule({
  declarations: [CommonRightMenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
    TickerAlertsModule,
    NotesModule,
    TradeTrackerModule,
    HelpSectionModule,
    WatchListModule,
    SetupScannerModule,
    ToolNotesModule,
    ClimateModule,
    TickerAlertNotificationModule,
  ],
  exports: [CommonRightMenuComponent],
})
export class CommonRightMenuModule {}
