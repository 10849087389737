import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HubInformationComponent } from './hub-information.component';

@NgModule({
  declarations: [HubInformationComponent],
  imports: [CommonModule, FormsModule, IonicModule],
})
export class HubInformationModule {}
