import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable } from 'rxjs/internal/Observable';
import { chatUrl } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { BroadcastService } from './broadcast.service';
import { LoaderService } from './loader.service';
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private socket;
  loaded: boolean;
  token: any;
  reconnecting = false;

  public channelsCounts: any = [];
  public channelError = new BehaviorSubject(null);
  public channelPostPermissionDenided = new BehaviorSubject(null);
  public channelHistory = new BehaviorSubject(null);
  public channelMessage = new BehaviorSubject(null);
  public channelMessageReply = new BehaviorSubject(null);
  public channelReaction = new BehaviorSubject(null);
  public channelDeleted = new BehaviorSubject(null);
  public channelUpdated = new BehaviorSubject(null);
  public channelsCountsSubject: any = new BehaviorSubject([]);
  public onChatSocketConnected = new BehaviorSubject(false);
  public roomJoined = new BehaviorSubject(null);

  constructor(
    public storageServ: StorageService,
    public broadcastService: BroadcastService,
    public loaderService: LoaderService
  ) {}

  establishSocketConnection = async (userId) => {
    this.socket = io(`${chatUrl}/chat`, {
      path: '/socket.io',
      forceNew: true,
      rejectUnauthorized: false,
      query: {
        user: userId,
      },
      transports: ['websocket'],
    });

    // Connection events
    this.socket.on('connect', () => {
      const engine = this.socket.io.engine;
      this.onChatSocketConnected.next(true);

      console.log(`socket connected...`);

      engine.once('upgrade', () => console.log('transport upgraded=>', engine.transport.name));
      engine.on('packetCreate', ({ type }) => console.log('packet sent =>', type));
      engine.on('packet', ({ type }) => {
        console.log('packet received =>', type);

        if (this.isChatActive && type !== 'noop' && this.reconnecting) {
          this.reconnecting = false;
          this.loaderService.HideLoader();
        }
      });

      engine.on('error', (error) => {
        console.log('engine error..', error);

        if (!this.reconnecting && this.isChatActive) {
          this.reconnecting = true;
          this.loaderService.showLoader('Connecting..');
        }
      });

      engine.on('close', (reason) => {
        console.log('socket underlying connection is closed =>', reason);

        if (!this.reconnecting && this.isChatActive) {
          this.reconnecting = true;
          this.loaderService.showLoader('Connecting..');
        }
      });
    });

    this.socket.on('connect_error', (err) => {
      console.log(`socket connection error => ${err}`);

      this.onChatSocketConnected.next(false);
      if (!this.reconnecting && this.isChatActive) {
        this.reconnecting = true;
        this.loaderService.showLoader('Connecting...');
      }
    });

    this.socket.on('error', (error) => {
      console.log(`socket error...`, error);

      if (!this.reconnecting && this.isChatActive) {
        this.reconnecting = true;
        this.loaderService.showLoader('Connecting..');
      }
    });

    this.socket.on('disconnect', () => {
      console.log(`socket disconnected...`);

      this.onChatSocketConnected.next(false);
      if (!this.reconnecting && this.isChatActive) {
        this.reconnecting = true;
        this.loaderService.showLoader('Connecting..');
      }
    });

    // Chat Events
    this.socket.on('channel-error', (channelId, errorMessage) => {
      this.channelError.next({ channelId, errorMessage });
    });

    this.socket.on('post-message-permission-denied', (channelId, permissionMessage) => {
      this.channelPostPermissionDenided.next({
        channelId,
        permissionMessage,
      });
    });

    this.socket.on('channel-history', (channel: any) => {
      this.channelError.next(null);
      this.channelPostPermissionDenided.next(null);
      this.channelHistory.next(channel);
    });

    this.socket.on('message-local', (message: any) => {
      this.updateChannelCount(message.channelId, message.authorId);
      this.channelMessage.next(message);
    });

    this.socket.on('message-reply', (message: any) => {
      this.updateChannelCount(message.channelId, message.authorId);
      this.channelMessageReply.next(message);
    });

    this.socket.on('reaction', (thread: any) => {
      this.channelReaction.next(thread);
    });

    this.socket.on('on-message-deleted', (thread: any) => {
      this.channelDeleted.next(thread);
    });

    this.socket.on('on-message-updated', (thread: any) => {
      this.channelUpdated.next(thread);
    });

    this.socket.on('room joined', (channelId) => {
      this.roomJoined.next(channelId);
    });
  };

  public clearChannelState() {
    this.channelError.next(null);
    this.channelPostPermissionDenided.next(null);
    this.channelHistory.next(null);
    this.channelMessage.next(null);
    this.channelMessageReply.next(null);
    this.channelReaction.next(null);
  }

  public pingOnChannelsLoaded() {
    const channelsCounts = !!localStorage.getItem('channelsCounts')
      ? JSON.parse(localStorage.getItem('channelsCounts') || '[]')
      : [];
    this.channelsCounts = channelsCounts;
    this.channelsCountsSubject.next(channelsCounts);
  }

  public updateChannelCount = (channelId, author) => {
    const loggedInUser = JSON.parse(localStorage.getItem('authorId') || '{}');
    const activatedChannel = JSON.parse(localStorage.getItem('channelId') || '{}');
    if (
      loggedInUser &&
      author._id !== loggedInUser.id &&
      (!activatedChannel || activatedChannel._id !== channelId)
    ) {
      const channelIndex = this.channelsCounts.findIndex((c) => c.channelId === channelId);
      if (channelIndex !== -1) {
        this.channelsCounts[channelIndex].count = this.channelsCounts[channelIndex].count + 1;
      } else {
        this.channelsCounts.push({
          channelId,
          count: 1,
        });
      }
      this.storageServ.set('channelsCounts', JSON.stringify(this.channelsCounts));
      this.channelsCountsSubject.next(this.channelsCounts);
    }
  };

  // emit events
  public emitSwitchRoom = (newChannelId: any) => {
    if (this.socket) {
      this.clearChannelState();
      this.socket.emit('switch room', newChannelId);
    }
  };

  public emitChannelHistory = (channelId, page) => {
    const channelsCounts = !!localStorage.getItem('channelsCounts')
      ? JSON.parse(localStorage.getItem('channelsCounts') || '[]')
      : [];
    this.channelsCounts = channelsCounts;

    const channelIndex = this.channelsCounts.findIndex((c) => c.channelId === channelId);
    if (channelIndex !== -1) {
      this.channelsCounts[channelIndex].count = 0;
    } else {
      this.channelsCounts.push({
        channelId,
        count: 0,
      });
    }
    this.channelsCountsSubject.next(this.channelsCounts);
    this.storageServ.set('channelsCounts', JSON.stringify(this.channelsCounts));

    if (this.socket) {
      this.socket.emit('load-channel-history', channelId, page);
    }
  };

  public emitMessage = (message, authorId, channelId, files = []) => {
    if (this.socket) {
      this.socket.emit('message', message, authorId, channelId, files);
    }
  };

  public emitMessageReply = (message, parentId, authorId, channelId, files = []) => {
    if (this.socket) {
      this.socket.emit('message-reply', message, parentId, authorId, channelId, files);
    }
  };

  public emitReaction = (channelId, threadId, userId, emoji) => {
    if (this.socket) {
      this.socket.emit('reaction', channelId, threadId, userId, emoji);
    }
  };

  public emitUpdate = (channelId, threadId, updatedMessage, files = []) => {
    if (this.socket) {
      this.socket.emit('edit-message', channelId, threadId, updatedMessage, files);
    }
  };

  public emitDelete = (channelId, threadId) => {
    if (this.socket) {
      this.socket.emit('delete-message', channelId, threadId);
    }
  };

  public emitFileDelete = (channelId, threadId, fileId) => {
    if (this.socket) {
      this.socket.emit('delete-thread-file', channelId, threadId, fileId);
    }
  };

  // on events
  public onSocketConnected(): Observable<any> {
    return this.onChatSocketConnected.asObservable();
  }

  public onChannelHistory(): Observable<any> {
    return this.channelHistory.asObservable();
  }

  public onMessage(): Observable<any> {
    return this.channelMessage.asObservable();
  }

  public onMessageReply(): Observable<any> {
    return this.channelMessageReply.asObservable();
  }

  public onReaction(): Observable<any> {
    return this.channelReaction.asObservable();
  }

  public onDeleted(): Observable<any> {
    return this.channelDeleted.asObservable();
  }

  public onUpdated(): Observable<any> {
    return this.channelUpdated.asObservable();
  }

  public onChannelError(): Observable<any> {
    return this.channelError.asObservable();
  }

  public onChannelPostPermissionDenided(): Observable<any> {
    return this.channelPostPermissionDenided.asObservable();
  }

  public onChannelCountUpdate(): Observable<any> {
    return this.channelsCountsSubject.asObservable();
  }

  public onRoomJoined(): Observable<any> {
    return this.roomJoined.asObservable();
  }

  get isChatActive() {
    return this.storageServ.get('channelId') ? true : false;
  }
}
