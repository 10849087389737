<div class="drawer-header">
  <div class="header-container">
    <div class="back-to">
      <ion-buttons slot="end" *ngIf="showForm !== false && !editSlug">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>
<ng-container *ngIf="!showForm">
  <div class="page-heading">
    <h2>{{ editSlug ? 'Edit' : 'Add' }} Notes</h2>
  </div>
  <div class="form-container">
    <div class="form-container">
      <form (ngSubmit)="onNotesCreateSubmit()" [formGroup]="toolNotesForm">
        <div class="form-group">
          <label style="font-size: 18px" for="notes">Note Description</label>
          <textarea
            class="note-description"
            formControlName="description"
            id="description"
            rows="4"
            cols="50"
          ></textarea>
        </div>
        <ion-button
          type="submit"
          [disabled]="toolNotesForm.invalid"
          color="primary"
          fill="solid"
          style="width: 100%"
        >
          {{ editSlug ? 'Update' : 'Add' }} Notes
        </ion-button>
      </form>
    </div>
  </div>
</ng-container>
