import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { uploadsS3BucketBasePath } from '../../../environments/environment';
import { imageExtensions, videoExtensions } from 'src/app/utils/constant';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit, OnChanges {
  @Input() layoutType = 'default';
  @Input() icon: any;
  @Output() emitSelectedFile = new EventEmitter<any>();
  @Output() disabled = new EventEmitter<any>();
  @Input() acceptedFileTypes = 'image/*, video/*';
  @Input() customCss = {};
  @Input() customClass = '';
  @Input() labelTitle = '';
  @Input() inputPlaceholder = '';
  @Input() previewType: any;
  @Output() onDeleteEvent = new EventEmitter<any>();
  @Input() loading: boolean = false;
  imageUrl: any;
  inputTypeError = false;

  fileToUpload: File | null;

  ngOnInit() {
    if (this.icon) {
      this.imageUrl = this.icon;
    }
  }

  ngOnChanges() {
    if (this.icon) {
      this.imageUrl = this.icon;
      this.checkFileType(this.imageUrl);
    }
  }

  onMediaLoad() {
    this.loading = false;
  }

  deleteVideo() {
    this.loading = false;
    this.imageUrl = null;
    this.previewType = 'image';
    this.onDeleteEvent.emit();
  }

  checkFileType(url: string) {
    let ext = url?.split('.').reverse()[0].toLowerCase();
    ext = ext.toLowerCase();
    if (videoExtensions.includes(ext)) this.previewType = 'video';
    else if (imageExtensions.includes(ext)) this.previewType = 'image';
  }

  handleFileInput(file: FileList) {
    this.imageUrl = null;
    this.fileToUpload = null;
    this.fileToUpload = file.item(0);
    let uploadedFileType = this.fileToUpload.type.split('/')[0];

    if (!uploadedFileType) {
      uploadedFileType = 'image';
    }
    if (uploadedFileType == 'image') this.previewType = 'image';
    else if (uploadedFileType == 'video') this.previewType = 'video';

    if (uploadedFileType !== 'image' && !this.acceptedFileTypes.includes('video/*')) {
      this.imageUrl = this.getThumbnailURLForExtension(
        /(?<ext>\.[\w]*)$/.exec(this.fileToUpload.name).groups?.ext
      );
      // TODO: Set image thumbnail to appropriate type.
      const imageData = {
        fileToUpload: this.fileToUpload,
        imageUrl: this.imageUrl,
      };
      this.emitSelectedFile.emit({ ...imageData });
      this.disabled.emit(false);
      this.inputTypeError = false;
    } else {
      let reader = getFileReader();
      // Start reading file

      // Prep to show image preview
      reader.onload = (event: any) => {
        this.imageUrl = event.target.result;
        if (uploadedFileType === 'image' || uploadedFileType === 'video') {
          const imageData = {
            fileToUpload: this.fileToUpload,
            imageUrl: this.imageUrl,
          };
          this.emitSelectedFile.emit({ ...imageData });
          this.disabled.emit(false);
          this.inputTypeError = false;
        } else {
          this.inputTypeError = true;
        }
      };
      this.loading = true;
      reader.readAsDataURL(this.fileToUpload);
    }
  }

  private getThumbnailURLForExtension(fileExtension: string): string {
    switch (fileExtension) {
      case '.pdf':
        return `${uploadsS3BucketBasePath}/file-types/file-type-pdf.svg`;

      case '.doc':
      case '.docx':
        return `${uploadsS3BucketBasePath}/file-types/file-type-doc.svg`;

      case '.xls':
      case '.xlsx':
        return `${uploadsS3BucketBasePath}/file-types/file-type-xls.svg`;

      case '.mp3':
      case '.wave':
      case '.wav':
      case '.ogg':
        return `${uploadsS3BucketBasePath}/file-types/file-type-audio.svg`;

      default:
        return `${uploadsS3BucketBasePath}/file-types/file-type-default.svg`;
    }
  }
}
export function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
  return zoneOriginalInstance || fileReader;
}
