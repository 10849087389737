import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateApplicationService {
  constructor(public updates: SwUpdate, private toastController: ToastController) {
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => {
        this.updates.checkForUpdate();
      });
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe((event) => this.promptUser());
  }

  public async promptUser() {
    const toast = await this.toastController.create({
      message: 'New version available. Load New Version?',
      color: 'success',
      position: 'bottom',
      buttons: [
        {
          text: 'Load Changes',
          handler: () => {
            this.updates.activateUpdate().then(async () => {
              window.location.reload();
            });
          },
        },
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    toast.present();
  }
}
