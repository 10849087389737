<ion-content class="ion-padding" appScrollbar>
  <div class="general-main" *ngIf="!isToolAccess">
    <div class="no-data-container">
      <div class="no-data">
        <img src="/assets/icon/locked.png" alt="" />
        <p *ngIf="!isToolAccess && !lockedPageContent">
          Please be patience, reviewing tool access.
        </p>
        <p
          *ngIf="!isToolAccess && lockedPageContent"
          [innerHTML]="lockedPageContent | keepHtml"
        ></p>
      </div>
    </div>
  </div>
  <div *ngIf="isToolAccess && !lockedPageContent">
    <ol class="cd-breadcrumb custom-separator custom-icons watchlist-breadcrumb0">
      <li><a routerLink="/{{ role }}/tools/dashboard/{{ activeToolSlug }}">Tools</a></li>
      <li>
        <a routerLink="/{{ role }}/tools/watchList/{{ activeToolSlug }}">WatchList</a>
      </li>
      <li class="current" *ngIf="watchListRow && watchListRow?.name">
        {{ watchListRow?.name }}
      </li>
    </ol>

    <div>
      <div class="page-heading">
        <span class="back-trade" routerLink="/{{ role }}/tools/watchList/{{ activeToolSlug }}">
          < Back To Watchlist</span
        >
      </div>
      <div class="main-content-area">
        <ion-grid class="icon-grid-block crypto">
          <ion-col class="cryptos">
            <ion-card class="crypto-info">
              <div class="crypto-details">
                <div class="crypto-details-info">
                  <div class="crypto-details-header">
                    <div>
                      <div>
                        <span class="show-watchlist-name">{{ watchListRow?.name }}</span>
                      </div>
                      <p class="show-description">{{ watchListRow?.description }}</p>
                    </div>
                    <div class="crypto-details-button">
                      <ion-button class="color-css1" fill="outline" (click)="addStock()">
                        <ion-icon name="add-outline" fill="outline"></ion-icon> Add Tickers
                      </ion-button>
                      <ion-button
                        class="color-css1"
                        fill="outline"
                        (click)="createEditWatchList('edit')"
                      >
                        <ion-icon name="create-outline" fill="outline"></ion-icon> Edit WatchList
                      </ion-button>
                      <ion-button class="color-css1" fill="outline" (click)="deleteWatchList()">
                        <ion-icon name="trash-outline" fill="outline"></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="actions">
                <ion-buttons slot="primary"></ion-buttons>
              </div>
            </ion-card>
          </ion-col>
        </ion-grid>

        <ion-card class="ion-no-margin table-content">
          <ion-card-header class="crypto-details-data">
            <ion-grid>
              <ion-row class="ion-justify-content-between ion-align-items-center color-css">
                <ion-col>
                  <p>Symbol</p>
                </ion-col>
                <ion-col>
                  <p>Last</p>
                </ion-col>
                <ion-col>
                  <p>Change</p>
                </ion-col>
                <ion-col>
                  <p>Change%</p>
                </ion-col>
                <ion-col>
                  <p>Action</p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-header>
          <ion-card-content class="ion-no-padding">
            <ng-container *ngIf="watchListSymbols.length; else noSymbols">
              <ion-list class="ion-no-padding">
                <ion-reorder-group
                  (ionItemReorder)="onReorderSymbols($event, watchListSymbols)"
                  disabled="false"
                >
                  <ion-item-group *ngFor="let row of watchListSymbols; let i = index">
                    <ion-item class="ion-item-no-inner-padding">
                      <ion-grid>
                        <ion-row class="ion-justify-content-between ion-align-items-center">
                          <ion-col>
                            <div class="symbol-container" (click)="viewSymbol(row)">
                              <ion-reorder></ion-reorder>
                              <div class="symbol-img-name symbol-image-width">
                                <img
                                  class="size-css"
                                  src="{{ row?.stockUrl }}"
                                  *ngIf="row?.stockUrl"
                                />
                                <span class="no-image" *ngIf="!row?.stockUrl"></span>
                                <p class="symbol-name">{{ row.displayName }} {{ row.symbol }}</p>
                              </div>
                            </div>
                          </ion-col>
                          <ion-col>
                            <p class="symbol-name">
                              {{ row?.regularMarketPrice | number : '1.2-2' }}
                            </p>
                          </ion-col>
                          <ion-col>
                            <span [ngClass]="getHeaderClassTodayChange({ row: row })">
                              {{ row?.regularMarketChange | number : '1.2-2' }}
                            </span>
                          </ion-col>
                          <ion-col>
                            <span [ngClass]="getHeaderClassTodayChangePer({ row: row })">
                              {{ row?.regularMarketChangePercent | number : '1.2-2' }}
                            </span>
                          </ion-col>
                          <ion-col>
                            <ion-buttons>
                              <span
                                class="span-wrapper-standalone-icon"
                                (click)="onRemoveSymbol(row)"
                              >
                                <ion-icon
                                  class="icon-button"
                                  color="black"
                                  slot="icon-only"
                                  name="trash-outline"
                                >
                                </ion-icon>
                              </span>
                            </ion-buttons>
                          </ion-col>
                        </ion-row>
                      </ion-grid>
                    </ion-item>
                  </ion-item-group>
                </ion-reorder-group>
              </ion-list>
            </ng-container>
            <ng-template #noSymbols>
              <ion-item lines="none" class="ion-padding item-no-data">
                <ion-grid>
                  <ion-row
                    class="ion-justify-content-center ion-align-items-center ion-text-center"
                  >
                    <ion-col>
                      <p>No stocks have been added to this watchlist.</p>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ng-template>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </div>
</ion-content>
