import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleOutputPipe } from './title-output.pipe';
import { InArrayPipe } from './in-arrayPipe';
import { EscapeHtmlPipe } from './keep-html.pipe';
import { SafePipe } from './safe.pipe';
import { MomentPipe } from './moment.pipe';
import { SortByOrderPipe } from './sort-by-order.pipe';
import { MomentCustomFormatPipe } from './moment-chat.pipe';
import { FormatToLocal } from './format-to-local-pipe';

@NgModule({
  declarations: [
    FormatToLocal,
    TitleOutputPipe,
    InArrayPipe,
    EscapeHtmlPipe,
    SafePipe,
    MomentPipe,
    MomentCustomFormatPipe,
    SortByOrderPipe,
  ],
  imports: [CommonModule],
  exports: [
    FormatToLocal,
    TitleOutputPipe,
    MomentPipe,
    MomentCustomFormatPipe,
    InArrayPipe,
    EscapeHtmlPipe,
    SortByOrderPipe,
    SafePipe,
  ],
})
export class PipesModule {}
