import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClimateComponent } from './climate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WalkwelDirectiveModules } from 'src/app/directives/walkwel-directive-modules.module';
import { EditorModuleModule } from 'src/app/components/editor/editor-module.module';
import { InfusionsoftTagsModule } from 'src/app/components/infusion-tags/infusion-tags.module';
import { IonIconModule } from 'src/app/components/ion-icon/ion-icon.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClimateRoutingModule } from './climate-routing.module';
import { ClimatesUpdateComponent } from './climates-update/climates-update.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
@NgModule({
  declarations: [ClimatesUpdateComponent, ClimateComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ClimateRoutingModule,
    ReactiveFormsModule,
    WalkwelDirectiveModules,
    EditorModuleModule,
    NgxDatatableModule,
    InfusionsoftTagsModule,
    IonIconModule,
    NgxSliderModule,
  ],
  exports: [ClimatesUpdateComponent, ClimateComponent],
  entryComponents: [ClimatesUpdateComponent],
})
export class ClimateModule {}
