<div class="drawer-header">
  <div class="header-container">
    <div class="back-to">
      <a *ngIf="showForm !== false && !editSlug" (click)="toggleView()"
        ><ion-icon name="chevron-back-outline"></ion-icon>Back to Notes
      </a>
      <a *ngIf="!showForm !== false && !editSlug" (click)="cancel()"
        ><ion-icon name="chevron-back-outline"></ion-icon>Back to Profile
      </a>
      <ion-button
        fill="outline"
        class=""
        *ngIf="!showForm !== false && !editSlug"
        (click)="toggleView()"
        >Create Note</ion-button
      >
      <ion-button
        fill="outline"
        class=""
        *ngIf="showForm == false && editSlug"
        (click)="toggleView()"
        >Create Note</ion-button
      >
      <ion-buttons slot="end" *ngIf="showForm !== false && !editSlug">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>

<ng-container *ngIf="!showForm">
  <div class="page-heading">
    <h2>Notes</h2>
  </div>
  <ng-container>
    <div *ngIf="noteData" class="notedata-block">
      <div class="list-container" *ngFor="let list of noteData">
        <div class="lsit-data-wrap" *ngIf="list.description">
          <div class="list-data">
            <div class="alert-info">
              {{ list.description }}
            </div>
          </div>
        </div>
        <div class="note-icon-date">
          <div *ngIf="list.createdAt">
            <div class="list-data">
              <div class="note-info" *ngIf="list.createdAt === list.updatedAt">
                {{ list.createdAt | date : 'MMM d, h:mm a' }}
              </div>
              <div class="note-info" *ngIf="list.createdAt !== list.updatedAt">
                {{ list.updatedAt | date : 'MMM d, h:mm a' }}
              </div>
            </div>
          </div>
          <div class="icon-group">
            <div class="edit">
              <button ion-button icon-only (click)="toggleView(list)" class="chat-menu-btn">
                <ion-icon name="create-outline"></ion-icon>
              </button>
            </div>
            <div class="delete">
              <button ion-button icon-only (click)="deleteNotes(list)" class="chat-menu-btn">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="noteData?.length === 0" class="empty-alert-list">
      <ion-icon name="notifications-off-outline"></ion-icon>
      <span>You don't have any Notes</span>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="showForm">
  <div class="page-heading">
    <h2>{{ editSlug ? 'Edit' : 'Add' }} Notes</h2>
  </div>
  <div class="form-container">
    <div class="form-container">
      <form (ngSubmit)="onNotesCreateSubmit()" [formGroup]="tickerNotesForm">
        <div class="form-group">
          <label style="font-size: 18px" for="notes">Note Description</label>
          <textarea
            class="note-description"
            formControlName="description"
            id="description"
            rows="4"
            cols="50"
          ></textarea>
        </div>
        <ion-button
          type="submit"
          [disabled]="tickerNotesForm.invalid"
          color="primary"
          fill="solid"
          style="width: 100%"
        >
          {{ editSlug ? 'Update' : 'Add' }} Notes
        </ion-button>
      </form>
    </div>
  </div>
</ng-container>
