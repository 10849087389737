import { Component, OnInit } from '@angular/core';
import { ToastrService, ToastPackage, Toast } from 'ngx-toastr';

@Component({
  selector: 'app-in-app-notification',
  templateUrl: './in-app-notifications.component.html',
  styleUrls: ['./in-app-notifications.component.scss'],
})
export class InAppNotificationComponent extends Toast implements OnInit {
  public notification;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    //@ts-ignore
    this.notification = this.toastPackage.config.payload;
  }

  onClosePopup() {
    this.toastPackage.triggerAction();
  }
}
