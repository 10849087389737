import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PopupInformationComponent } from './popup-information.component';

@NgModule({
  declarations: [PopupInformationComponent],
  imports: [CommonModule, FormsModule, IonicModule],
  exports: [PopupInformationComponent],
})
export class PopupInformationModule {}
