import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { walkthroughData } from 'src/app/utils/constant';
import { ProfileService } from 'src/app/services/profile.service';
import { StorageService } from 'src/app/services/storage.service';
declare var $: any;
@Component({
  selector: 'app-hub-information',
  templateUrl: './hub-information.component.html',
  styleUrls: ['./hub-information.component.scss'],
})
export class HubInformationComponent implements OnInit {
  @ViewChild('slideWithNav', { static: false }) slideWithNav: IonSlides;
  slides: any;
  currentSlideIndex: number = 0;
  getHubFlag: any;
  public isLoading = true;
  walkthroughData = walkthroughData;
  @Input() formType: string;
  @Input() userInfo: any;
  ionViewDidEnter() {
    this.slideWithNav.update();
  }

  constructor(
    private modalController: ModalController,
    private profileService: ProfileService,
    public storageServ: StorageService
  ) { }

  ngOnInit() {
    this.getSlidesFE();
  }

  handleLink(url) {
    url = url.includes('http://') || url.includes('https://') ? url : 'https://' + url;
    if (url) {
      window.open(url, '_blank');
    }
  }

  disableBack() {
    window.history.forward();
  }

  getSlidesFE() {
    this.isLoading = false;
    this.slides = walkthroughData[this.formType];
  }

  // slideNext() {
  //   this.slideWithNav.slideNext(700).then(() => {
  //     this.checkIfNavDisabled();
  //   });
  // }

  // slidePrev() {
  //   this.slideWithNav.slidePrev(700).then(() => {
  //     this.checkIfNavDisabled();
  //   });
  // }

  slideNext() {
    this.slideWithNav.slideNext(700).then(() => {
      this.checkIfNavDisabled();
      this.updateCurrentSlideIndex(); // Update the current slide index after sliding
    });
  }

  slidePrev() {
    this.slideWithNav.slidePrev(700).then(() => {
      this.checkIfNavDisabled();
      this.updateCurrentSlideIndex(); // Update the current slide index after sliding
    });
  }

  updateCurrentSlideIndex() {
    this.slideWithNav.getActiveIndex().then((index) => {
      this.currentSlideIndex = index;
    });
  }

  async slideFinish() {
    let updateClone: any = {};
    if (this.formType === 'wowInfo') {
      updateClone.isHubInfoVisited = true;
    } else if (this.formType === 'hubInformationType') {
      updateClone.isWalkThroughCompleted = true;
    }

    if (Object.keys(updateClone).length) {
      this.profileService.updateHubInfo(updateClone).subscribe((response: any) => {
        if (response?.data) {
          const [keyName] = Object.keys(updateClone);
          this.storageServ.set(
            'userData',
            JSON.stringify({ ...this.userInfo, [keyName]: response.data[keyName] })
          );
        }
      });
      await this.modalController.dismiss();
    }
  }

  checkIfNavDisabled() {
    this.checkisBeginning();
    this.checkisEnd();
  }

  checkisBeginning() {
    this.slideWithNav.isBeginning().then((istrue) => {
      this.slides.isBeginningSlide = istrue;
    });
  }
  checkisEnd() {
    this.slideWithNav.isEnd().then((istrue) => {
      this.slides.isEndSlide = istrue;
    });
  }
}
