import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WalkwelDirectiveModules } from '../../directives/walkwel-directive-modules.module';
import { ImageGalleryModule } from 'src/app/components/gallery/gallery.module';
import { NgxTinymceModule } from 'ngx-tinymce';

@NgModule({
  declarations: [EditorComponent],
  imports: [
    CommonModule,
    NgxTinymceModule,
    FormsModule,
    ReactiveFormsModule,
    ImageGalleryModule,
    WalkwelDirectiveModules,
  ],
  providers: [],
  exports: [EditorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EditorModuleModule {}
