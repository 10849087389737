import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { GalleryFilesService } from 'src/app/services/gallery-files-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GalleryFile } from 'src/app/models/gallery-file';

@Component({
  selector: 'app-gallery-uploads',
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button (click)="dismissModal()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>New Upload</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-row class="content-row">
        <ion-col size-xl="12" size-lg="12">
          <div id="upload-file">
            <form [formGroup]="newFileForm" (ngSubmit)="uploadFile()">
              <ion-item lines="none" class="ion-no-padding">
                <app-image-upload
                  [acceptedFileTypes]="'*/*'"
                  [icon]="urlToBlankFile"
                  [inputPlaceholder]="'Choose a file'"
                  (emitSelectedFile)="fileSelectedForUpload($event)"
                ></app-image-upload>
              </ion-item>
              <div *ngIf="newFile">
                <ion-item lines="inset" class="ion-no-padding">
                  <ion-label position="floating">Title</ion-label>
                  <ion-input
                    inputmode="text"
                    formControlName="title"
                    ngDefaultControl
                    [(ngModel)]="newFileToUpload.title"
                    name="title"
                  ></ion-input>
                </ion-item>
                <div *ngIf="newFileControls.title.errors?.required" class="error">
                  Title is required.
                </div>

                <ion-item lines="inset" class="ion-no-padding">
                  <ion-label position="floating">Caption</ion-label>
                  <ion-input
                    inputmode="text"
                    formControlName="caption"
                    ngDefaultControl
                    [(ngModel)]="newFileToUpload.caption"
                    name="caption"
                  ></ion-input>
                </ion-item>
              </div>
              <div *ngIf="newFileControls.caption.errors?.minlength" class="error">
                Caption should be at least 3 characters long.
              </div>
            </form>
          </div>
        </ion-col>
      </ion-row>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button
            size="default"
            fill="solid"
            color="primary"
            [disabled]="!newFileForm.valid"
            (click)="uploadFile()"
          >
            Upload
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  `,
  styleUrls: ['./gallery.component.scss'],
})
export class NewUploadsPage implements OnInit {
  @Input() currentFolder;
  public newFileForm: FormGroup;
  public newFile: File;
  public newFileToUpload: GalleryFile = new GalleryFile();
  private readonly toastSuccess = 'success';
  public readonly urlToBlankFile = null;

  constructor(
    private galleryService: GalleryFilesService,
    private toastCtrl: ToastController,
    public modalController: ModalController,
    private fb: FormBuilder
  ) {}

  get newFileControls() {
    return this.newFileForm.controls;
  }

  ngOnInit(): void {
    this.resetNewFileForm();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  fileSelectedForUpload(fileData) {
    this.newFile = fileData.fileToUpload;
    this.newFileToUpload.title = this.newFile.name;
    this.newFileToUpload.caption = this.newFile.name;

    this.newFileForm.setValue({
      title: this.newFile.name,
      caption: this.newFile.name,
    });
  }

  uploadFile() {
    const formData = new FormData();
    formData.append('title', this.newFileToUpload.title);
    formData.append('caption', this.newFileToUpload.caption);
    formData.append('file', this.newFile);

    if (this.currentFolder) {
      formData.append('parent', this.currentFolder._id);
    }

    this.galleryService.processFileUpload(formData).subscribe(() => {
      this.toastMessage('File uploaded', this.toastSuccess);
      this.modalController.dismiss();
      this.resetNewFileForm();
    });
  }

  private resetNewFileForm() {
    this.newFileToUpload = new GalleryFile();
    this.newFileForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(4)]],
      caption: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  async toastMessage(message, color) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'top',
      animated: true,
      color,
      cssClass: 'my custom class',
    });
    toast.present();

    return toast.onDidDismiss();
  }
}
