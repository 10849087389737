import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import moment from 'moment-timezone';

@Component({
  selector: 'app-alert-date-picker',
  templateUrl: './alert-date-picker.component.html',
  styleUrls: ['./alert-date-picker.component.scss'],
})
export class AlertDatePickerComponent implements OnInit {
  public formattedDateString: any;
  public today = new Date();
  @Input() feedDate = new Date();
  @Input() presentation = 'date-time';
  @Input() formatOfFomattedString = 'DD MMM YYYY, h:mm A';
  @Input() options = {
    classes: '',
    lines: '',
    labelPosition: "'floating'",
  };
  public dateValue = moment(
    new Date(this.today.getTime() - this.today.getTimezoneOffset() * 60000)
  ).toISOString();
  @Input() label = 'Date';
  @Input() hash: any;
  @Output() onDateChange = new EventEmitter<any>();
  @Input() min: Date;
  constructor() {}

  ngOnInit() {
    this.formattedDateString = moment(this.today).format(this.formatOfFomattedString);
  }

  ngOnChanges(changes) {
    if (
      changes.feedDate &&
      changes.feedDate?.currentValue &&
      changes.feedDate?.currentValue !== this.dateValue
    ) {
      this.feedDate = changes.feedDate?.currentValue;
      this.today = new Date(changes.feedDate?.currentValue);
      this.dateValue = moment(
        new Date(this.today.getTime() - this.today.getTimezoneOffset() * 60000)
      ).toISOString();
      this.formattedDateString = moment(this.today).format(this.formatOfFomattedString);
    }
  }

  dateChanged(value) {
    this.dateValue = value;
    this.formattedDateString = moment(value).format(this.formatOfFomattedString);
    this.onDateChange.emit(value);
  }
}
