import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppDatePicker } from './date-picker.component';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppDatePicker],
  imports: [CommonModule, IonicModule, PipesModule, FormsModule],
  exports: [AppDatePicker],
})
export class AppDatePickerModule {}
