import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { baseUrl } from '../../environments/environment';
import { GalleryFile } from '../models/gallery-file';

@Injectable({
  providedIn: 'root',
})
export class GalleryFilesService {
  token: string;

  constructor(private httpClient: HttpClient, private storageService: StorageService) {}

  processFileUpload(formData: FormData) {
    return this.httpClient.post(`${baseUrl}/gallery/`, formData, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storageService.get('token')}`),
    });
  }

  getDirectoryFiles(search: string, parent: GalleryFile) {
    const reqParams = new URLSearchParams();

    if (parent && parent._id) {
      reqParams.append('parent', parent._id);
    }

    if (search) {
      reqParams.append('search', search);
    }

    return this.httpClient.get<FileListResponse>(`${baseUrl}/gallery?${reqParams.toString()}`, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.storageService.get('token')}`)
        .set('disableHttpLoader', 'true'),
    });
  }

  getTrashFiles() {
    return this.httpClient.get<FileListResponse>(`${baseUrl}/gallery/trash`, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.storageService.get('token')}`)
        .set('disableHttpLoader', 'true'),
    });
  }

  updateFileData(file: GalleryFile) {
    return this.httpClient.patch(
      `${baseUrl}/gallery/${file._id}`,
      {
        title: file.title,
        caption: file.caption,
        parent: file.parent,
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Bearer ${this.storageService.get('token')}`
        ),
      }
    );
  }

  // move file to trash
  trashDocument(file: GalleryFile) {
    return this.httpClient.delete(`${baseUrl}/gallery/trash/${file._id}`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storageService.get('token')}`),
    });
  }

  // permanently delete (single file - from trash)
  deleteFile(file: GalleryFile) {
    return this.httpClient.delete(`${baseUrl}/gallery/${file._id}`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storageService.get('token')}`),
    });
  }

  // permanently delete (all files - from trash)
  clearTrashFiles() {
    return this.httpClient.get(`${baseUrl}/gallery/trash-clear`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storageService.get('token')}`),
    });
  }

  restoreTrashFile(file: GalleryFile) {
    return this.httpClient.get(`${baseUrl}/gallery/trash/restore/${file._id}`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storageService.get('token')}`),
    });
  }

  createFolder(title: string, parent: GalleryFile) {
    return this.httpClient.post(
      `${baseUrl}/gallery/folder`,
      {
        title,
        parent: parent ? parent._id : null,
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          `Bearer ${this.storageService.get('token')}`
        ),
      }
    );
  }
}

interface FileListResponse {
  data: GalleryFile[];
  message: string;
  meta: PageMeta;
}
interface PageMeta {
  total: number;
  perPage: number;
  current: number;
  totalPages: number;
}
