import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppGalleryComponent } from './gallery.component';
import { ImageUploadModule } from 'src/app/components/image-upload/image-upload.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NewUploadsPage } from './new-upload.component';
import { DocumentInfoPage } from './document-info.components';
import { MoveDirectoryPage } from './move-dir-selection.component';
import { WalkwelDirectiveModules } from 'src/app/directives/walkwel-directive-modules.module';

@NgModule({
  declarations: [AppGalleryComponent, NewUploadsPage, DocumentInfoPage, MoveDirectoryPage],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageUploadModule,
    PipesModule,
    WalkwelDirectiveModules,
  ],
  exports: [AppGalleryComponent, NewUploadsPage, DocumentInfoPage, MoveDirectoryPage],
})
export class ImageGalleryModule {}
