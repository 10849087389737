export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  offset = 0;
  // page: number = 0; // The current page number  //pageNumber
  // pages: number = 0; // The total number of pages   //totalPages
  // perPage: number = 0; // The number of elements in the page  //size
  // total: number = 0; // The total number of elements // totalElements
}
