<div class="drawer-header">
  <div class="header-container">
    <div class="page-header">
      <div class="page-heading-text">Trade History - {{ symbol }}</div>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</div>
<ng-container>
  <div
    *ngFor="let data of tradeHistoryList"
    class="trade-history-wrap"
    (click)="onClickHistory(data)"
  >
    <div
      [ngClass]="
        tradeHistoryData.slug == data.slug ? 'trade-history-block active ' : 'trade-history-block'
      "
    >
      <div class="current-view" *ngIf="tradeHistoryData.slug == data.slug">Currently Viewing</div>
      <div class="trader-data">
        <span>Date Opened :</span>
        <span class="value">{{ data.createdAt | date : 'd MMM yy' }}</span>
      </div>
      <div class="trader-data">
        <span>Trade Duration :</span>
        <span class="value"
          >{{
            calculateDaysDifference(data.createdAt, data.tradeCloseDate, data.status)
          }}
          Days</span
        >
      </div>
      <div class="trader-data">
        <span>POS. P/L :</span>
        <div *ngIf="data.cumulativeProfitLoss < 0">
          <span class="value red-text">{{ data.cumulativeProfitLoss | currency }}</span>
        </div>
        <div *ngIf="data.cumulativeProfitLoss > 0">
          <span class="value green-text">{{ data.cumulativeProfitLoss | currency }}</span>
        </div>
        <div *ngIf="data.cumulativeProfitLoss == 0">
          <span class="value">{{ data.cumulativeProfitLoss | currency }}</span>
        </div>
      </div>
      <div class="trader-data">
        <span>Notes :</span>
        <span class="value">
          <ion-icon slot="start" name="document-text-outline"></ion-icon>
          {{ data.totalNotes }} Note</span
        >
      </div>
      <div class="trader-data">
        <span>Status :</span>
        <ion-button [ngClass]="data.status == 'open' ? 'open-btn' : 'close-btn'">{{
          data.status
        }}</ion-button>
      </div>
    </div>
  </div>
</ng-container>
