<ion-content class="ion-padding md content-ltr hydrated"
  ><div class="page-heading">
    <h2>Treasure Hunting Climate</h2>
  </div>

  <ion-row>
    <ion-col>
      <div class="climate-iframe-view">
        <ion-card class="ion-no-padding">
          <ion-card-content class="ion-no-padding">
            <ion-list>
              <div class="row climate-row">
                <div class="video-wrapper">
                  <iframe
                    [src]="iframeSrc"
                    width="560"
                    height="340"
                    frameborder="1"
                    allowfullscreen="allowfullscreen"
                    data-mce-fragment="1"
                  ></iframe>
                </div>
                <div class="text-climate-iframe" *ngFor="let item of row">
                  <div class="title-cllimate">
                    <span>Hunting Climate: {{ item?.value }}%</span>
                  </div>
                  <div class="text-climate-description">
                    <span>{{ item?.description }}</span>
                  </div>
                  <div>
                    <ion-button fill="outline" color="dark" (click)="getUpdatedClimate('edit')">
                      Change Climates
                    </ion-button>
                  </div>
                </div>
              </div>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
