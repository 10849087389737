<ng-container>
  <div class="form-container" [formGroup]="setupScannerList">
    <div class="page-heading">
      <ion-title
        >{{ setupScannerData?.mode === 'edit' ? 'Edit' : 'Create' }} Custom Scanner
      </ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
    <ng-container>
      <ion-col size-xl="12" size-lg="12" class="p-0">
        <div class="input-block-custom custom-opacity">
          <ion-label position="floating">Scanner Name<sup>*</sup></ion-label>
          <ion-input class="border-design" formControlName="name" type="text"></ion-input>
        </div>
      </ion-col>
      <div class="input-container">
        <ion-label position="floating">Scanner Description</ion-label>
        <textarea
          class="border-design"
          formControlName="description"
          id="message"
          rows="4"
          cols="50"
        ></textarea>
      </div>
      <div class="input-container" style="display: none">
        <ion-label position="floating">Upload</ion-label>
        <app-image-upload
          [layoutType]="'poster'"
          [acceptedFileTypes]="acceptFile"
          [icon]="imagePreview"
          [labelTitle]="label"
          [inputPlaceholder]="placeholder"
          (emitSelectedFile)="getImage($event)"
        ></app-image-upload>
        <!-- <input type="file" class="file-upload" (change)="onFileChange($event)" /> -->
        <!-- <input type="file" class="file-upload" onchange="console.log(event.target.files)" /> -->
      </div>

      <ion-footer class="ion-no-border">
        <ion-toolbar>
          <ion-button
            type="submit"
            color="primary"
            fill="solid"
            (click)="createSetupScanner(setupScannerData.mode)"
            [disabled]="setupScannerList.invalid"
            style="width: 100%"
          >
            {{ setupScannerData?.mode === 'edit' ? 'Update' : 'Create' }} Scanner
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </ng-container>
  </div>
</ng-container>
