import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageUploadComponent } from './image-upload.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [ImageUploadComponent],
  imports: [CommonModule, IonicModule],
  exports: [ImageUploadComponent],
})
export class ImageUploadModule {}
