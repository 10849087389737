import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-tool-notes',
  templateUrl: './tool-notes.component.html',
  styleUrls: ['./tool-notes.component.scss'],
})
export class ToolNotesComponent implements OnInit {

  @Input() toolNotesData: any;
  showForm = false;
  mode;
  public toolNotesForm: FormGroup;
  editSlug: string;
  noteData: any;

  constructor(
    private formBuilder: FormBuilder,
    private toolService: ToolServiceService,
    private toastServ: ToastService,
    public menuController: MenuController,
    private menuService: commonRightMenuService
  ) {
    this.toolNotesForm = this.formBuilder.group({
      description: ['', [Validators.required, this.noWhitespaceValidator]],
    });
  }

  ngOnInit() {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['toolNotesData'] && this.toolNotesData) {
      this.initializeForm();
    }
  }

  onNotesCreateSubmit() {
    if (this.toolNotesForm.invalid) {
      this.toastServ.presentToast('Please provide a valid description.', 'danger');
      return;
    }

    const description = this.toolNotesForm.get('description').value;
    const status = 'active';
    const slug = this.toolNotesData.slug;    
    const payload = {
      tickerSlug: slug,
      status: status,
      description: description,
    };
    if (!this.editSlug) {
      this.toolService.createNote(payload).subscribe((res: any) => {
        if (res) {
          this.menuService.setCloseMenuSubject(payload);
          this.toolNotesForm.reset();
          this.showForm = false;
          this.toastServ.presentToast(res?.message, 'success');
          this.menuService.triggerChildResetAdd(description);
          this.resetFormState();
          this.cancel();
        }
      });
    } else {
      this.toolService.updateNote(payload, this.editSlug).subscribe((res: any) => {
        if (res) {
          this.menuService.setCloseMenuSubject(payload);
          this.toolNotesForm.reset();
          this.showForm = false;
          this.toastServ.presentToast(res?.message, 'success');
          this.menuService.triggerChildResetAdd(description);
          this.resetFormState();
          this.cancel();
        }
      });
    }
  }
  resetFormState() {
    this.toolNotesForm.reset();
    this.showForm = false;
    this.mode = 'create';  // Reset mode to create
    this.editSlug = null;  // Clear edit slug
    this.menuService.triggerChildResetAdd('');
    this.cancel();  // Close the menu
  }


  initializeForm() {
   if (this.toolNotesData?.noteDetail) {
      this.mode = 'edit';
      this.editSlug = this.toolNotesData.noteDetail.slug;
  
      this.toolNotesForm.patchValue({
        description: this.toolNotesData.noteDetail.description,
      });
  } else {
      this.mode = 'create';
      this.editSlug = null;
      this.toolNotesForm.reset();
    }
  }
  

  deleteNotes(noteDetail) {
    this.toastServ
      .confirmationAlert('Are you sure you want to delete this note?', 'Delete')
      .then((confirm) => {
        if (confirm) {
          this.toolService.deleteNote(noteDetail.slug).subscribe((response: any) => {
            this.toastServ.presentToast(response.message, 'success');
          });
        }
      });
  }

  cancel() {
    this.menuController.close();
  }

  // Custom validator to disallow only white spaces
  noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { whitespace: true } : null;
  }
}
