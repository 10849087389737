<div class="notification" *ngIf="notification">
  <div class="notification-title-container" *ngIf="title">
    <div class="notification-title">{{ title }}</div>
  </div>
  <div [ngSwitch]="notification.type">
    <div *ngSwitchCase="'ChatComment'">
      <div class="left-section">
        <div class="user-profile">
          <img
            ngxGravatar
            [email]="notification.commentAuthor.email"
            size="45"
            src="{{ notification.commentAuthor.image }}"
          />
        </div>
        <div class="notification-info chat-notification-info">
          <div class="message-container" [innerHtml]="notification.message | keepHtml"></div>
          <div
            class="comment"
            *ngIf="notification.comment"
            [innerHtml]="notification.comment.commentText | keepHtml"
          ></div>
          <div class="time">{{ notification.dateTime | moment }}</div>
        </div>
      </div>
      <div class="close" (click)="onClosePopup()">&#x2715;</div>
    </div>
    <div *ngSwitchCase="'Broadcast'">
      <div class="left-section">
        <div class="broadcast-logo">
          <div class="image-container">
            <img src="/assets/icon/broadcast-speaker.png" />
          </div>
        </div>
        <div class="notification-info broadcast-notification-info">
          <div class="broadcast-subject">{{ notification.subject }}</div>
          <div class="broadcast-message" [innerHtml]="notification.message | keepHtml"></div>
          <div class="time">{{ notification.dateTime | moment }}</div>
        </div>
      </div>
      <div class="close" (click)="onClosePopup()">&#x2715;</div>
    </div>
    <div *ngSwitchCase="'InsiderChat'">
      <div class="left-section">
        <div class="user-profile">
          <img
            ngxGravatar
            [email]="notification.threadAuthor.email"
            size="45"
            src="{{ notification.threadAuthor.image }}"
          />
        </div>
        <div class="notification-info chat-notification-info">
          <div class="message-container" [innerHtml]="notification.message | keepHtml"></div>
          <div
            class="comment"
            *ngIf="notification.thread"
            [innerHtml]="notification.thread.threadText | keepHtml"
          ></div>
          <div class="time">{{ notification.dateTime | moment }}</div>
        </div>
      </div>
      <div class="close" (click)="onClosePopup()">&#x2715;</div>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</div>
