<ng-container class="ng-container-block">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close(popupInfo?._id)">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <div class="image-block-content">
      <div class="image-block" *ngIf="previewType === 'image'">
        <img
          class="slider-image"
          [src]="popupInfo?.image"
          (click)="popupInfo?.imageRedirectUrl && openImageRedirectUrl(popupInfo?.imageRedirectUrl)"
          [class.pointer-cursor]="popupInfo?.imageRedirectUrl"
        />
      </div>
      <div *ngIf="previewType === 'video'">
        <video
          class="video-wrapper"
          *ngIf="subType === 'video'"
          width="200"
          controls
          autoplay
          muted
          loop
        >
          <source [src]="popupInfo?.image" type="video/mp4" />
        </video>
        <div class="iframe-block" *ngIf="subType === 'iframe'">
          <iframe
            class="video-set"
            [src]="cleanURL(popupInfo?.image)"
            frameborder="1"
            allowfullscreen="allowfullscreen"
            data-mce-fragment="1"
          ></iframe>
        </div>
      </div>
      <ion-title [innerHTML]="popupInfo?.description"></ion-title>
    </div>
    <div class="dismiss-block">
      <ion-button
        class="next-btn"
        color="medium"
        size="large"
        fill="clear"
        (click)="$event.stopPropagation(); close(popupInfo?._id)"
      >
        DISMISS
      </ion-button>
    </div>
  </ion-toolbar>
</ng-container>
