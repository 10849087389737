<ion-content class="ion-padding">
  <div class="page-heading">
    <h2>Profile</h2>
    <div class="actions">
      <ion-buttons slot="primary">
        <ion-button color="medium" fill="outline" (click)="back()">
          <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
          Back
        </ion-button>
      </ion-buttons>
    </div>
  </div>
  <ion-row>
    <ion-col size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12">
      <ion-card class="ion-no-margin">
        <ion-card-content class="ion-padding">
          <div
            class="container-user-details ion-flex-container ion-align-items-center ion-flex-direction-column ion-justify-content-center"
          >
            <ion-avatar>
              <img ngxGravatar [email]="userDetails?.email" src="{{ userDetails?.image }}" />
            </ion-avatar>
            <div
              class="basic-details ion-flex-container ion-align-items-center ion-flex-direction-column"
            >
              <h3>{{ getUserName }}</h3>
              <span class="ion-text-lowercase">{{ userDetails.email }}</span>
              <span>
                <ion-badge color="warning"> Hub Member </ion-badge>
              </span>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
      <ion-card class="ion-no-margin">
        <ion-card-header>
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-align-items-center">
              <ion-col size="auto">
                <ion-segment
                  class="ion-flex-container ion-flex-wrap ion-flex-gap-default"
                  color="primary"
                  [(ngModel)]="activeTab"
                  (ionChange)="segmentChanged($event)"
                >
                  <ion-segment-button
                    class="ion-text-capitalize"
                    value="profile"
                    checked
                    layout="icon-start"
                  >
                    <ion-icon size="small" name="person-outline"></ion-icon>
                    <ion-label>Edit Profile</ion-label>
                  </ion-segment-button>
                  <ion-segment-button
                    class="ion-text-capitalize"
                    value="account-size"
                    layout="icon-start"
                  >
                    <ion-icon size="small" name="expand-outline"></ion-icon>
                    <ion-label>Size Of Account</ion-label>
                  </ion-segment-button>
                  <ion-segment-button
                    class="ion-text-capitalize"
                    value="settings"
                    layout="icon-start"
                  >
                    <ion-icon size="small" name="settings-outline"></ion-icon>
                    <ion-label>Settings</ion-label>
                  </ion-segment-button>
                  <ion-segment-button
                    class="ion-text-capitalize"
                    value="change-password"
                    layout="icon-start"
                  >
                    <ion-icon size="small" name="lock-open-outline"></ion-icon>
                    <ion-label>Change Password</ion-label>
                  </ion-segment-button>
                </ion-segment>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-header>
        <ion-card-content>
          <div [ngSwitch]="activeTab">
            <div
              class="container-edit-profile"
              appScrollbar
              [directInject]="true"
              *ngSwitchCase="'profile'"
            >
              <ion-row>
                <ion-col>
                  <ion-item lines="none" class="ion-item-no-inner-padding">
                    <div class="image-upload-profile">
                      <app-image-upload
                        [layoutType]="'profile'"
                        [acceptedFileTypes]="'image/*'"
                        [inputPlaceholder]="'Choose Profile'"
                        [icon]="imagePreview"
                        (emitSelectedFile)="onProfileImageChange($event)"
                      >
                      </app-image-upload>
                    </div>
                  </ion-item>
                </ion-col>
              </ion-row>
              <form [formGroup]="profileForm">
                <ion-row>
                  <ion-col>
                    <ion-item lines="inset" class="ion-item-no-inner-padding">
                      <ion-label position="floating">First Name</ion-label>
                      <ion-input formControlName="firstName"></ion-input>
                    </ion-item>
                    <span class="error" *ngIf="formControls.firstName.errors?.required">
                      First name is required field.
                    </span>
                    <span class="error" *ngIf="formControls.firstName.errors?.minlength">
                      First name must be at least 2 characters long.
                    </span>
                    <span class="error" *ngIf="formControls.firstName.errors?.pattern">
                      First name should only contain alphabets.
                    </span>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="inset" class="ion-item-no-inner-padding">
                      <ion-label position="floating">Last Name</ion-label>
                      <ion-input formControlName="lastName"></ion-input>
                    </ion-item>
                    <span class="error" *ngIf="formControls.lastName.errors?.required">
                      Last name is required field.
                    </span>
                    <span class="error" *ngIf="formControls.lastName.errors?.minlength">
                      Last name must be at least 2 characters long.
                    </span>
                    <span class="error" *ngIf="formControls.lastName.errors?.pattern">
                      Last name should only contain alphabets.
                    </span>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="inset" class="ion-item-no-inner-padding">
                      <ion-label position="floating">Email</ion-label>
                      <ion-input formControlName="email"></ion-input>
                    </ion-item>
                    <span class="error" *ngIf="formControls.email.errors?.required">
                      Email is required field.
                    </span>
                    <span class="error" *ngIf="formControls.email.errors?.pattern">
                      Please enter a valid email address.
                    </span>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none" class="ion-item-no-inner-padding">
                      <ion-label>Theme</ion-label>
                      <ion-select
                        [interfaceOptions]="customPopoverOptions"
                        okText="Okay"
                        cancelText="Dismiss"
                        formControlName="theme"
                        (ionChange)="activateTheme()"
                        interface="popover"
                      >
                        <ion-select-option *ngFor="let theme of themes" value="{{ theme.value }}"
                          >{{ theme.name }}
                        </ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none" class="ion-item-no-inner-padding">
                      <ion-button
                        [disabled]="profileForm.invalid"
                        color="primary"
                        size="default"
                        slot="end"
                        (click)="onSubmit()"
                        >Update
                      </ion-button>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </form>
            </div>

            <div
              class="container-profile-settings"
              appScrollbar
              [directInject]="true"
              *ngSwitchCase="'settings'"
            >
              <form [formGroup]="profileSettingForm">
                <ion-row formGroupName="notifications">
                  <ion-col>
                    <h3 class="notification-label">Notification Settings</h3>
                    <div class="block-container" formGroupName="comment">
                      <div class="block-label">
                        <ion-icon name="chatbox-ellipses-outline"></ion-icon>
                        <label>Comment Reply Notifications</label>
                      </div>
                      <div class="block-content">
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="email"
                          ></ion-checkbox>
                          <label>Email</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="webPush"
                          ></ion-checkbox>
                          <label>Browser Push</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="nativePush"
                          ></ion-checkbox>
                          <label>Native App Push</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="inApp"
                          ></ion-checkbox>
                          <label>In App Notification</label>
                        </div>
                      </div>
                    </div>
                    <div class="block-container" formGroupName="chat">
                      <div class="block-label">
                        <ion-icon name="chatbubbles-outline"></ion-icon>
                        <label>Insider Room Reply Notifications</label>
                      </div>
                      <div class="block-content">
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="email"
                          ></ion-checkbox>
                          <label>Email</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="webPush"
                          ></ion-checkbox>
                          <label>Browser Push</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="nativePush"
                          ></ion-checkbox>
                          <label>Native App Push</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="inApp"
                          ></ion-checkbox>
                          <label>In App Notification</label>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="block-container" formGroupName="broadcast">
                      <div class="block-label">
                        <ion-icon name="radio-outline"></ion-icon>
                        <label>Broadcast Notifications</label>
                      </div>
                      <div class="block-content">
                        <div class="checkbox-item-block">
                          <ion-checkbox slot="start" color="primary" formControlName="email"></ion-checkbox>
                          <label>Email</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox slot="start" color="primary" formControlName="webPush"></ion-checkbox>
                          <label>Browser Push</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox slot="start" color="primary" formControlName="nativePush"></ion-checkbox>
                          <label>Native App Push</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox slot="start" color="primary" formControlName="inApp"></ion-checkbox>
                          <label>In App Notification</label>
                        </div>
                      </div>
                    </div> -->
                    <div class="block-container" formGroupName="alert">
                      <div class="block-label">
                        <ion-icon name="notifications-outline"></ion-icon>
                        <label>Alert Notifications</label>
                      </div>
                      <div class="block-content">
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="email"
                          ></ion-checkbox>
                          <label>Email</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="nativePush"
                          ></ion-checkbox>
                          <label>Native App Push</label>
                        </div>
                        <div class="checkbox-item-block">
                          <ion-checkbox
                            slot="start"
                            color="primary"
                            formControlName="text"
                            (click)="checkContact()"
                          ></ion-checkbox>
                          <label>Text</label>
                        </div>
                        <ng-container *ngIf="enableContactInput">
                          <div class="contact-number-dropdown">
                            <label class="w-full">Contact Number</label>
                            <!-- <ion-row> -->
                            <!-- <ion-col> -->
                            <!-- <ion-label position="floating">Contact Number</ion-label> -->
                            <!-- <ion-item lines="inset" class="ion-item-no-inner-padding"> -->
                            <ngx-intl-tel-input
                              #phoneInput
                              [cssClass]="'custom'"
                              [preferredCountries]="preferredCountries"
                              [enableAutoCountrySelect]="true"
                              [enablePlaceholder]="true"
                              [searchCountryFlag]="true"
                              [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                              ]"
                              [selectFirstCountry]="false"
                              [selectedCountryISO]="countryCode"
                              [maxLength]="15"
                              [phoneValidation]="true"
                              [separateDialCode]="true"
                              [numberFormat]="PhoneNumberFormat.National"
                              name="contact"
                              formControlName="contact"
                            >
                            </ngx-intl-tel-input>
                            <!-- </ion-item> -->
                            <!-- </ion-col> -->
                            <!-- </ion-row> -->
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none" class="ion-item-no-inner-padding">
                      <ion-button
                        [disabled]="profileSettingForm.invalid"
                        color="primary"
                        size="default"
                        slot="end"
                        (click)="onSettingSave()"
                        >Save Changes
                      </ion-button>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </form>
            </div>

            <div appScrollbar [directInject]="true" *ngSwitchCase="'change-password'">
              <form (ngSubmit)="changePassword()" [formGroup]="changePasswordForm">
                <ion-row>
                  <ion-col>
                    <ion-item lines="inset" class="ion-item-no-inner-padding">
                      <ion-label position="floating">Current Password</ion-label>
                      <ion-input formControlName="currentPassword" type="password"></ion-input>
                    </ion-item>
                    <ng-container
                      *ngIf="this.changePasswordForm.invalid && this.changePasswordForm.touched"
                    >
                      <span
                        class="error"
                        *ngIf="changePasswordFormControls.currentPassword.errors?.required"
                      >
                        Current password is required field.
                      </span>
                    </ng-container>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="inset" class="ion-item-no-inner-padding">
                      <ion-label position="floating">New Password</ion-label>
                      <ion-input type="password" formControlName="password"></ion-input>
                    </ion-item>
                    <ng-container
                      *ngIf="this.changePasswordForm.invalid && this.changePasswordForm.touched"
                    >
                      <span
                        class="error"
                        *ngIf="changePasswordFormControls.password.errors?.required"
                      >
                        New password is required field.
                      </span>
                      <span
                        class="error"
                        *ngIf="changePasswordFormControls.password.errors?.minlength"
                      >
                        The password must be at least 6 characters long.
                      </span>
                    </ng-container>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="inset" class="ion-item-no-inner-padding">
                      <ion-label position="floating">Confirm Password</ion-label>
                      <ion-input formControlName="confirmpassword" type="password"></ion-input>
                    </ion-item>
                    <ng-container
                      *ngIf="this.changePasswordForm.invalid && this.changePasswordForm.touched"
                    >
                      <span
                        class="error"
                        *ngIf="changePasswordFormControls.confirmpassword.errors?.required"
                      >
                        Confirm password is required field.
                      </span>
                      <span
                        class="error"
                        *ngIf="changePasswordFormControls.confirmpassword.errors?.minlength"
                      >
                        The password must be at least 6 characters long.
                      </span>
                      <span class="error" *ngIf="changePasswordForm.hasError('passwordNotMatch')">
                        The password and confirm password must be the same.
                      </span>
                    </ng-container>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none" class="ion-item-no-inner-padding">
                      <ion-button
                        size="default"
                        color="primary"
                        slot="end"
                        (click)="changePassword()"
                        [disabled]="changePasswordForm.invalid"
                        >Change Password</ion-button
                      >
                    </ion-item>
                  </ion-col>
                </ion-row>
              </form>
            </div>

            <div appScrollbar [directInject]="true" *ngSwitchCase="'account-size'">
              <form (ngSubmit)="submitAccountSize()" [formGroup]="accountSizeForm">
                <ion-row>
                  <ion-col>
                    <ion-item lines="inset" class="ion-item-no-inner-padding">
                      <ion-label position="floating">Size</ion-label>
                      <ion-input formControlName="accountSize" type="number"></ion-input>
                    </ion-item>
                    <ng-container
                      *ngIf="this.accountSizeForm.invalid && this.accountSizeForm.touched"
                    >
                      <span
                        class="error"
                        *ngIf="accountSizeFormControls.accountSize.errors?.required"
                      >
                        Account size required.
                      </span>
                    </ng-container>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-item lines="none" class="ion-item-no-inner-padding">
                      <ion-button
                        size="default"
                        color="primary"
                        slot="end"
                        (click)="submitAccountSize()"
                        [disabled]="accountSizeForm.invalid"
                        >SAVE CHANGES</ion-button
                      >
                    </ion-item>
                  </ion-col>
                </ion-row>
              </form>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-content>
