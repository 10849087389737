import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { PopupInformationComponent } from 'src/app/layouts/popup-information/popup-information.component';
import { StorageService } from '../../services/storage.service';
// import
@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();
  constructor(
    private modalController: ModalController,
    private popupInformation: PopupInformationComponent,
    private storageService: StorageService
  ) {}

  parse(url: string): UrlTree {
    // Encode parentheses
    url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
    // Use the default serializer.
    this.checkPopUp(url);
    return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    return this._defaultUrlSerializer.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')');
  }

  async checkPopUp(url: string): Promise<void> {
    const urlChunks = url.split('/');
    const is_user_side = urlChunks.find((chunk) => chunk == 'user');
    if (!is_user_side) return;
    const userData: any = JSON.parse(this.storageService.get('userData') || '{}');
    if (!userData?.isWalkThroughCompleted) return;
    await this.popupInformation.manageUserPopup(urlChunks);
  }
}
