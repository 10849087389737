export const ANALYTICS_CRONS: any = {
  syncLeadSourceContacts: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncProduct: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncProductRefunds: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncProductSales: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncProductSubscriptionPlans: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncSubscription: {
    inprogress: false,
    completed: false,
    lastSyncbutton: null,
  },
  syncTagAppliedInfo: {
    inprogress: false,
    completed: false,
    lastSyncbutton: null,
  },
};

export const BROADCAST_CRONS: any = {
  syncEmail: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncSms: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
};

export const BACKUP_CRONS: any = {
  syncDatabaseBackup: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncUpdateBaseTag: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncInfusionsoftTags: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
};

export const DROPLET_CRONS: any = {
  recurringEventScheduler: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  refreshAnalyticsPHPServerAccessToken: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  deleteOldBackups: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  deleteOldApiLogs: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  createDropletSnapshot: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
};

export const TEMPLATE_CRONS: any = {
  syncInfusionsoftAudiences: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncInfusionsoftTemplates: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
  syncInfusionsoftAudienceContacts: {
    inprogress: false,
    completed: false,
    lastSync: null,
  },
};

export const ANALYTICS_CRON_TYPES: any = {
  SYNC_LEAD_SOURCE_CONTACTS: 'syncLeadSourceContacts',
  SYNC_PRODUCT: 'syncProduct',
  SYNC_PRODUCT_REFUNDS: 'syncProductRefunds',
  SYNC_PRODUCT_SALES: 'syncProductSales',
  SYNC_PRODUCT_SUBSCRIPTION_PLAN: 'syncProductSubscriptionPlans',
  SYNC_SUBSCRIPTION: 'syncSubscription',
  SYNC_TAG_APPLIED_INFO: 'syncTagAppliedInfo',
};

export const BROADCAST_CRON_TYPES: any = {
  SYNC_EMAIL: 'syncEmail',
  SYNC_SMS: 'syncSms',
};

export const BACKUP_CRON_TYPES: any = {
  SYNC_DATABASEBACKUP: 'syncDatabaseBackup',
  SYNC_UPDATEBASETAG: 'syncUpdateBaseTag',
  SYNC_INFUSIONSOFTTAGS: 'syncInfusionsoftTags',
};

export const DROPLET_CRON_TYPES: any = {
  RECURRING_EVENT_SCHEDULER: 'recurringEventScheduler',
  DELETE_OLD_BACKUPS: 'deleteOldBackups',
  DELETE_OLD_API_LOGS: 'deleteOldApiLogs',
  CREATE_DROPLET_SNAPSHOT: 'createDropletSnapshot',
  DELETE_DROPLET_SNAPSHOT: 'deleteDropletSnapshot',
};
export const TEMPLATE_CRON_TYPES: any = {
  SYNC_INFUSIONSOFTAUDIENCES: 'syncInfusionsoftAudiences',
  SYNC_INFUSIONSOFTTEMPLATES: 'syncInfusionsoftTemplates',
  SYNC_INFUSIONSOFTAUDIENCESCONTACTS: 'syncInfusionsoftAudienceContacts',
};

export const ANALYTICS_DETAILS: any = {
  syncLeadSourceContacts: {
    title: 'Sync LeadSource Contacts',
  },
  syncProduct: {
    title: 'Sync Product',
  },
  syncProductRefunds: {
    title: 'Sync Product Refunds',
  },
  syncProductSales: {
    title: 'Sync Product Sales',
  },
  syncProductSubscriptionPlans: {
    title: 'Sync Product Subscription Plans',
  },
  syncSubscription: {
    title: 'Sync Subscription',
  },
  syncTagAppliedInfo: {
    title: 'Sync Tag Applied Info',
  },
};

export const BROADCAST_DETAILS: any = {
  syncEmail: {
    title: 'Sync Email',
  },
  syncSms: {
    title: 'Sync Sms',
  },
};

export const BACKUP_DETAILS: any = {
  syncDatabaseBackup: {
    title: 'Sync Database Backup',
  },
  syncUpdateBaseTag: {
    title: 'Sync Updatebase Tag',
  },
  syncInfusionsoftTags: {
    title: 'Sync Infusionsoft Tags',
  },
};

export const DROPLET_DETAILS: any = {
  recurringEventScheduler: {
    title: 'Sync Recurring Event Scheduler',
  },
  deleteOldBackups: {
    title: 'Sync Delete Old Backups',
  },
  deleteOldApiLogs: {
    title: 'Sync Delete Old ApiLogs',
  },
  createDropletSnapshot: {
    title: 'Sync Create Droplet Snapshot',
  },
  deleteDropletSnapshot: {
    title: 'Sync Delete Droplet Snapshot',
  },
};

export const TEMPLATE_DETAILS: any = {
  syncInfusionsoftAudiences: {
    title: 'Sync Infusionsoft Audiences',
  },
  syncInfusionsoftTemplates: {
    title: 'Sync Infusionsoft Templates',
  },
  syncInfusionsoftAudienceContacts: {
    title: 'Sync Infusionsoft Audience Contacts',
  },
};

export const BUYERS_REDIRECTION_CONFIG: any = {
  urlTemplate: 'https://ssbt.infusionsoft.com/Contact/manageContact.jsp?view=edit&ID=${contactId}',
  generateUrl: function (contactId) {
    return this.urlTemplate.replace('${contactId}', contactId);
  },
};

export const CONDITION_TYPES = [
  { label: 'Moves Above', value: 'move_above' },
  { label: 'Moves Below', value: 'move_below' },
];

export const ALERT_TYPES = [
  { label: 'Date/Time', value: 'on_date' },
  { label: 'Stock Price', value: 'stock_price' },
];

export const walkthroughData = {
  wowInfo: {
    isBeginningSlide: true,
    isEndSlide: false,
    slidesItems: [
      {
        sortOrder: 1,
        enable: true,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '64812482f9d7cac7f9733fb8',
        title: 'WELCOME TO THE W.O.W. INSIDER MEMBERS AREA!',
        description:
          'Please take a moment to  review how you can get the most out of your W.O.W. Insider membership!',
        image: 'assets/icon/WOW_insider.jpg',
        __v: 0,
      },
      {
        sortOrder: 2,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '610da3cfc7b4f3053c1056bc',
        title: 'MAKE SURE YOU GO THROUGH THE FOUNDATION FIRST!',
        description:
          "We're impatient too, but going through The Foundation section first ensures that you have the base understanding to get the most out of your insider Membership.",
        link: 'assets/mp3/Money Press Method(MP4)/insider-1.mp4',
        ogg: 'assets/mp3/Money Press Method(MP4)/insider-1.ogg',
        __v: 0,
        svg: 'assets/icons/shape.svg',
      },
      {
        sortOrder: 3,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '61c10a2a92e8107632232893',
        title: "INSIDER STEP 1: WATCH PRESTON'S WELCOME VIDEO",
        description:
          'First, be sure to watch the brief welcome video from your fearless "Captain", Preston!',
        __v: 0,
        link: 'assets/mp3/Money Press Method(MP4)/insider-2.mp4',
        ogg: 'assets/mp3/Money Press Method(MP4)/insider-2.ogg',
        scheduleEnd: '2023-12-13T17:01:06.134Z',
        scheduleStart: '2022-12-14T17:00:06.134Z',
      },
      {
        sortOrder: 4,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '648124daf9d7cac7f9733fcb',
        title: 'INSIDER STEP 2: THE MONEY PRESS METHOD TAB',
        description:
          'Next, head over to the Money Press Method tab and go through the section for more in depth trainings.',
        link: 'assets/mp3/Money Press Method(MP4)/insider-mpm.mp4',
        ogg: 'assets/mp3/Money Press Method(MP4)/insider-mpm.ogg',
        __v: 0,
      },
      {
        sortOrder: 5,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '648124daf9d7cac7f9733fcb',
        title: "MAKE SURE TO REGISTER FOR FRIDAY'S WEBINAR WITH PRESTON",
        description:
          "Don't miss out on the action! Here is how you can register for the Friday webinars.",
        link: 'assets/mp3/Money Press Method(MP4)/webinar-registration.mp4',
        ogg: 'assets/mp3/Money Press Method(MP4)/webinar-registration.ogg',
        __v: 0,
      },
      {
        sortOrder: 6,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '648124daf9d7cac7f9733fcb',
        title: "FOLLOW PRESTON'S TRADES & THE PATH TO YOUR MONEY PRESS SUCCESS!",
        description:
          "This is how you can see Preston's trades and follow along so you don't miss a thing.",
        link: 'assets/mp3/Money Press Method(MP4)/current-trades-updates.mp4',
        ogg: 'assets/mp3/Money Press Method(MP4)/current-trades-updates.ogg',
        __v: 0,
      },
    ],
  },
  hubInformationType: {
    isBeginningSlide: true,
    isEndSlide: false,
    slidesItems: [
      {
        sortOrder: 1,
        enable: true,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '64812482f9d7cac7f9733fb8',
        title: 'WELCOME TO THE T.E.A.M. HUB!',
        description:
          'The Hub is where you access all of your current and future purchases, receive timely updates, and connect with other traders.',
        image: 'assets/icon/team-hub-logo.png',
        __v: 0,
      },
      {
        sortOrder: 2,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '610da3cfc7b4f3053c1056bc',
        title: 'STEP 1 : THE FOUNDATION',
        description:
          "Be sure to go first to 'The Foundation' section and review the Money Press Method guidebook and training.",
        link: 'assets/mp3/Money Press Method(MP4)/s1-1(mp4).mp4',
        __v: 0,
      },
      {
        sortOrder: 3,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '61c10a2a92e8107632232893',
        title: 'GET TRADING THE MONEY PRESS ASAP!',
        description:
          "The 'Money Press Onramp' was created with the single purpose of helping you get set up to trade your first Money Press as quickly as possible. Be sure to check it out!",
        image: '',
        __v: 0,
        link: 'assets/mp3/Money Press Method(MP4)/foundation.mp4',
        scheduleEnd: '2023-12-13T17:01:06.134Z',
        scheduleStart: '2022-12-14T17:00:06.134Z',
      },
      {
        sortOrder: 4,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '648124daf9d7cac7f9733fcb',
        title: 'VIDEO: A QUICK HUB WALKTHROUGH',
        description:
          'In this video, you will see the Hub in action and how to consume the information inside.',
        link: 'https://player.vimeo.com/video/885745458',
        image: '',
        __v: 0,
      },
      {
        sortOrder: 5,
        enable: false,
        scheduled: false,
        locked: false,
        tags: [],
        _id: '648124daf9d7cac7f9733fcb',
        title: 'NEED HELP?',
        description:
          "Be sure to use the 'Help' button for any questions about the platform. Enjoy!",
        link: 'assets/mp3/Money Press Method(MP4)/help-center.mp4',
        ogg: 'assets/mp3/Money Press Method(MP4)/help-center.ogg',
        image: '',
        __v: 0,
      },
    ],
  },
};

export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp'];
export const videoExtensions = [
  'mp4',
  'avi',
  'mov',
  'wmv',
  'flv',
  'mkv',
  'mpg',
  'mpeg',
  'm4v',
  'webm',
  '3gp',
  'rm',
  'rmvb',
  'ts',
  'vob',
  'ogg',
  'divx',
];

export const MARKET_CAP = [
  {
    name: 'Mega: 200B+',
    value: 'Mega: 200B+',
  },
  {
    name: 'Large: 10-200B',
    value: 'Large: 10-200B',
  },
  {
    name: 'Mid: 2-10B',
    value: 'Mid: 2-10B',
  },
  {
    name: 'Small: 300M-2B',
    value: 'Small: 300M-2B',
  },
  {
    name: 'Nano: Under 300M',
    value: 'Nano: under 300M',
  },
];

export const PRICE = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Over 5',
    value: 'over 5',
  },
  {
    name: 'Over 10',
    value: 'over 10',
  },
  {
    name: 'Over 50',
    value: 'over 50',
  },
  {
    name: 'Over 100',
    value: 'over 100',
  },
];

export const CHANGE1DAY = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Up',
    value: 'up',
  },
  {
    name: 'Down',
    value: 'down',
  },
  {
    name: 'Under 10%',
    value: 'under 10%',
  },
  {
    name: 'Under 25%',
    value: 'under 25%',
  },
  {
    name: 'Under 50%',
    value: 'under 50%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 5% to 10%',
    value: 'up 5% to 10%',
  },
  {
    name: 'Up 10% to 25%',
    value: 'up 10% to 25%',
  },
  {
    name: '10 to 50',
    value: '10 to 50',
  },
  {
    name: '50 to 100',
    value: '50 to 100',
  },
  {
    name: 'Up 25% to 50%',
    value: 'up 25% to 50%',
  },
  {
    name: 'Up 50% to 100%',
    value: 'up 50% to 100%',
  },
  {
    name: 'Up +100%',
    value: 'up +100%',
  },
  {
    name: 'Down 0% to 5%',
    value: 'down 0% to 5%',
  },
  {
    name: 'Down 5% to 10%',
    value: 'down 5% to 10%',
  },
  {
    name: 'Down 10% to 25%',
    value: 'down 10% to 25%',
  },
  {
    name: 'Down 25% to 50%',
    value: 'down 25% to 50%',
  },
  {
    name: 'Down 50% to 100%',
    value: 'down 50% to 100%',
  },
  {
    name: 'Down -100%',
    value: 'down -100%',
  },
  {
    name: 'Over 10%',
    value: 'over 10%',
  },
  {
    name: 'Over 25%',
    value: 'over 25%',
  },
  {
    name: 'Over 50%',
    value: 'over 50%',
  },
];
export const CHANGELAST7DAYS = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Up',
    value: 'up',
  },
  {
    name: 'Down',
    value: 'down',
  },
  {
    name: 'Under 10%',
    value: 'under 10%',
  },
  {
    name: 'Under 25%',
    value: 'under 25%',
  },
  {
    name: 'Under 50%',
    value: 'under 50%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 5% to 10%',
    value: 'up 5% to 10%',
  },
  {
    name: 'Up 10% to 25%',
    value: 'up 10% to 25%',
  },
  {
    name: '10 to 50',
    value: '10 to 50',
  },
  {
    name: '50 to 100',
    value: '50 to 100',
  },
  {
    name: 'Up 25% to 50%',
    value: 'up 25% to 50%',
  },
  {
    name: 'Up 50% to 100%',
    value: 'up 50% to 100%',
  },
  {
    name: 'Up +100%',
    value: 'up +100%',
  },
  {
    name: 'Down 0% to 5%',
    value: 'down 0% to 5%',
  },
  {
    name: 'Down 5% to 10%',
    value: 'down 5% to 10%',
  },
  {
    name: 'Down 10% to 25%',
    value: 'down 10% to 25%',
  },
  {
    name: 'Down 25% to 50%',
    value: 'down 25% to 50%',
  },
  {
    name: 'Down 50% to 100%',
    value: 'down 50% to 100%',
  },
  {
    name: 'Down -100%',
    value: 'down -100%',
  },
  {
    name: 'Over 10%',
    value: 'over 10%',
  },
  {
    name: 'Over 25%',
    value: 'over 25%',
  },
  {
    name: 'Over 50%',
    value: 'over 50%',
  },
];
export const CHANGE30DAYS = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Up',
    value: 'up',
  },
  {
    name: 'Down',
    value: 'down',
  },
  {
    name: 'Under 10%',
    value: 'under 10%',
  },
  {
    name: 'Under 25%',
    value: 'under 25%',
  },
  {
    name: 'Under 50%',
    value: 'under 50%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 5% to 10%',
    value: 'up 5% to 10%',
  },
  {
    name: 'Up 10% to 25%',
    value: 'up 10% to 25%',
  },
  {
    name: '10 to 50',
    value: '10 to 50',
  },
  {
    name: '50 to 100',
    value: '50 to 100',
  },
  {
    name: 'Up 25% to 50%',
    value: 'up 25% to 50%',
  },
  {
    name: 'Up 50% to 100%',
    value: 'up 50% to 100%',
  },
  {
    name: 'Up +100%',
    value: 'up +100%',
  },
  {
    name: 'Down 0% to 5%',
    value: 'down 0% to 5%',
  },
  {
    name: 'Down 5% to 10%',
    value: 'down 5% to 10%',
  },
  {
    name: 'Down 10% to 25%',
    value: 'down 10% to 25%',
  },
  {
    name: 'Down 25% to 50%',
    value: 'down 25% to 50%',
  },
  {
    name: 'Down 50% to 100%',
    value: 'down 50% to 100%',
  },
  {
    name: 'Down -100%',
    value: 'down -100%',
  },
  {
    name: 'Over 10%',
    value: 'over 10%',
  },
  {
    name: 'Over 25%',
    value: 'over 25%',
  },
  {
    name: 'Over 50%',
    value: 'over 50%',
  },
];
export const CHANGE60DAYS = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Up',
    value: 'up',
  },
  {
    name: 'Down',
    value: 'down',
  },
  {
    name: 'Under 10%',
    value: 'under 10%',
  },
  {
    name: 'Under 25%',
    value: 'under 25%',
  },
  {
    name: 'Under 50%',
    value: 'under 50%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 5% to 10%',
    value: 'up 5% to 10%',
  },
  {
    name: 'Up 10% to 25%',
    value: 'up 10% to 25%',
  },
  {
    name: '10 to 50',
    value: '10 to 50',
  },
  {
    name: '50 to 100',
    value: '50 to 100',
  },
  {
    name: 'Up 25% to 50%',
    value: 'up 25% to 50%',
  },
  {
    name: 'Up 50% to 100%',
    value: 'up 50% to 100%',
  },
  {
    name: 'Up +100%',
    value: 'up +100%',
  },
  {
    name: 'Down 0% to 5%',
    value: 'down 0% to 5%',
  },
  {
    name: 'Down 5% to 10%',
    value: 'down 5% to 10%',
  },
  {
    name: 'Down 10% to 25%',
    value: 'down 10% to 25%',
  },
  {
    name: 'Down 25% to 50%',
    value: 'down 25% to 50%',
  },
  {
    name: 'Down 50% to 100%',
    value: 'down 50% to 100%',
  },
  {
    name: 'Down -100%',
    value: 'down -100%',
  },
  {
    name: 'Over 10%',
    value: 'over 10%',
  },
  {
    name: 'Over 25%',
    value: 'over 25%',
  },
  {
    name: 'Over 50%',
    value: 'over 50%',
  },
];
export const CHANGE90DAYS = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Up',
    value: 'up',
  },
  {
    name: 'Down',
    value: 'down',
  },
  {
    name: 'Under 10%',
    value: 'under 10%',
  },
  {
    name: 'Under 25%',
    value: 'under 25%',
  },
  {
    name: 'Under 50%',
    value: 'under 50%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 5% to 10%',
    value: 'up 5% to 10%',
  },
  {
    name: 'Up 10% to 25%',
    value: 'up 10% to 25%',
  },
  {
    name: '10 to 50',
    value: '10 to 50',
  },
  {
    name: '50 to 100',
    value: '50 to 100',
  },
  {
    name: 'Up 25% to 50%',
    value: 'up 25% to 50%',
  },
  {
    name: 'Up 50% to 100%',
    value: 'up 50% to 100%',
  },
  {
    name: 'Up +100%',
    value: 'up +100%',
  },
  {
    name: 'Down 0% to 5%',
    value: 'down 0% to 5%',
  },
  {
    name: 'Down 5% to 10%',
    value: 'down 5% to 10%',
  },
  {
    name: 'Down 10% to 25%',
    value: 'down 10% to 25%',
  },
  {
    name: 'Down 25% to 50%',
    value: 'down 25% to 50%',
  },
  {
    name: 'Down 50% to 100%',
    value: 'down 50% to 100%',
  },
  {
    name: 'Down -100%',
    value: 'down -100%',
  },
  {
    name: 'Over 10%',
    value: 'over 10%',
  },
  {
    name: 'Over 25%',
    value: 'over 25%',
  },
  {
    name: 'Over 50%',
    value: 'over 50%',
  },
];
export const CHANGE180DAYS = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Up',
    value: 'up',
  },
  {
    name: 'Down',
    value: 'down',
  },
  {
    name: 'Under 10%',
    value: 'under 10%',
  },
  {
    name: 'Under 25%',
    value: 'under 25%',
  },
  {
    name: 'Under 50%',
    value: 'under 50%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 5% to 10%',
    value: 'up 5% to 10%',
  },
  {
    name: 'Up 10% to 25%',
    value: 'up 10% to 25%',
  },
  {
    name: '10 to 50',
    value: '10 to 50',
  },
  {
    name: '50 to 100',
    value: '50 to 100',
  },
  {
    name: 'Up 25% to 50%',
    value: 'up 25% to 50%',
  },
  {
    name: 'Up 50% to 100%',
    value: 'up 50% to 100%',
  },
  {
    name: 'Up +100%',
    value: 'up +100%',
  },
  {
    name: 'Down 0% to 5%',
    value: 'down 0% to 5%',
  },
  {
    name: 'Down 5% to 10%',
    value: 'down 5% to 10%',
  },
  {
    name: 'Down 10% to 25%',
    value: 'down 10% to 25%',
  },
  {
    name: 'Down 25% to 50%',
    value: 'down 25% to 50%',
  },
  {
    name: 'Down 50% to 100%',
    value: 'down 50% to 100%',
  },
  {
    name: 'Down -100%',
    value: 'down -100%',
  },
  {
    name: 'Over 10%',
    value: 'over 10%',
  },
  {
    name: 'Over 25%',
    value: 'over 25%',
  },
  {
    name: 'Over 50%',
    value: 'over 50%',
  },
];
export const CHANGE1YTD = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Up',
    value: 'up',
  },
  {
    name: 'Down',
    value: 'down',
  },
  {
    name: 'Under 10%',
    value: 'under 10%',
  },
  {
    name: 'Under 25%',
    value: 'under 25%',
  },
  {
    name: 'Under 50%',
    value: 'under 50%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 5% to 10%',
    value: 'up 5% to 10%',
  },
  {
    name: 'Up 10% to 25%',
    value: 'up 10% to 25%',
  },
  {
    name: '10 to 50',
    value: '10 to 50',
  },
  {
    name: '50 to 100',
    value: '50 to 100',
  },
  {
    name: 'Up 25% to 50%',
    value: 'up 25% to 50%',
  },
  {
    name: 'Up 50% to 100%',
    value: 'up 50% to 100%',
  },
  {
    name: 'Up +100%',
    value: 'up +100%',
  },
  {
    name: 'Down 0% to 5%',
    value: 'down 0% to 5%',
  },
  {
    name: 'Down 5% to 10%',
    value: 'down 5% to 10%',
  },
  {
    name: 'Down 10% to 25%',
    value: 'down 10% to 25%',
  },
  {
    name: 'Down 25% to 50%',
    value: 'down 25% to 50%',
  },
  {
    name: 'Down 50% to 100%',
    value: 'down 50% to 100%',
  },
  {
    name: 'Down -100%',
    value: 'down -100%',
  },
  {
    name: 'Over 10%',
    value: 'over 10%',
  },
  {
    name: 'Over 25%',
    value: 'over 25%',
  },
  {
    name: 'Over 50%',
    value: 'over 50%',
  },
];
export const CHANGE365DAYS = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Between',
    value: 'between',
  },
  {
    name: 'Up',
    value: 'up',
  },
  {
    name: 'Down',
    value: 'down',
  },
  {
    name: 'Under 10%',
    value: 'under 10%',
  },
  {
    name: 'Under 25%',
    value: 'under 25%',
  },
  {
    name: 'Under 50%',
    value: 'under 50%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 0% to 5%',
    value: 'up 0% to 5%',
  },
  {
    name: 'Up 5% to 10%',
    value: 'up 5% to 10%',
  },
  {
    name: 'Up 10% to 25%',
    value: 'up 10% to 25%',
  },
  {
    name: '10 to 50',
    value: '10 to 50',
  },
  {
    name: '50 to 100',
    value: '50 to 100',
  },
  {
    name: 'Up 25% to 50%',
    value: 'up 25% to 50%',
  },
  {
    name: 'Up 50% to 100%',
    value: 'up 50% to 100%',
  },
  {
    name: 'Up +100%',
    value: 'up +100%',
  },
  {
    name: 'Down 0% to 5%',
    value: 'down 0% to 5%',
  },
  {
    name: 'Down 5% to 10%',
    value: 'down 5% to 10%',
  },
  {
    name: 'Down 10% to 25%',
    value: 'down 10% to 25%',
  },
  {
    name: 'Down 25% to 50%',
    value: 'down 25% to 50%',
  },
  {
    name: 'Down 50% to 100%',
    value: 'down 50% to 100%',
  },
  {
    name: 'Down -100%',
    value: 'down -100%',
  },
  {
    name: 'Over 10%',
    value: 'over 10%',
  },
  {
    name: 'Over 25%',
    value: 'over 25%',
  },
  {
    name: 'Over 50%',
    value: 'over 50%',
  },
];
export const VOLUME = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Under 50k',
    value: 'under 50k',
  },
  {
    name: 'Under 100k',
    value: 'under 100k',
  },
  {
    name: 'Under 500k',
    value: 'under 500k',
  },
  {
    name: 'Under 1M',
    value: 'under 1M',
  },
  {
    name: '50k to 100k',
    value: '50k to 100k',
  },
  {
    name: '50k to 500k',
    value: '50k to 500k',
  },
  {
    name: '500k to 1M',
    value: '500k to 1M',
  },
  {
    name: '1M to 2M',
    value: '1M to 2M',
  },
  {
    name: '1M to 5M',
    value: '1M to 5M',
  },
  {
    name: '5M to 10M',
    value: '5M to 10M',
  },
  {
    name: '10M to 20M',
    value: '10M to 20M',
  },
  {
    name: 'Over 50k',
    value: 'over 50k',
  },
  {
    name: 'Over 100k',
    value: 'over 100k',
  },
  {
    name: 'Over 500k',
    value: 'over 500k',
  },
  {
    name: 'Over 1M',
    value: 'over 1M',
  },
  {
    name: 'Over 5M',
    value: 'over 5M',
  },
  {
    name: 'Over 10M',
    value: 'over 10M',
  },
];

export const WEEKLYOPTION = [
  {
    name: 'Any',
    value: 'any',
  },
  {
    name: 'Yes',
    value: 'yes',
  },
  {
    name: 'No',
    value: 'no',
  },
];

export const TRADE_SYMBOL = [
  { symbol: 'AA', name: 'ALCOA CORP COM' },
  { symbol: 'AAL', name: 'AMERICAN AIRLS GROUP INC COM' },
  { symbol: 'AAOI', name: 'APPLIED OPTOELECTRONICS INC COM' },
  { symbol: 'AAP', name: 'ADVANCE AUTO PARTS INC COM' },
  { symbol: 'AAPL', name: 'APPLE INC COM' },
  { symbol: 'ABBV', name: 'ABBVIE INC COM' },
  { symbol: 'ABNB', name: 'AIRBNB INC COM CL A' },
  { symbol: 'ABR', name: 'ARBOR REALTY TRUST INC COM' },
  { symbol: 'ABT', name: 'ABBOTT LABS COM' },
  { symbol: 'ACB', name: 'AURORA CANNABIS INC COM' },
  { symbol: 'ACHR', name: 'ARCHER AVIATION INC COM CL A' },
  { symbol: 'ACMR', name: 'ACM RESH INC COM CL A' },
  { symbol: 'ACN', name: 'ACCENTURE PLC IRELAND SHS CLASS A' },
  { symbol: 'ADBE', name: 'ADOBE INC COM' },
  { symbol: 'ADI', name: 'ANALOG DEVICES INC COM' },
  { symbol: 'ADM', name: 'ARCHER DANIELS MIDLAND CO COM' },
  { symbol: 'ADP', name: 'AUTOMATIC DATA PROCESSING INC COM' },
  { symbol: 'ADSK', name: 'AUTODESK INC COM' },
  { symbol: 'AEO', name: 'AMERICAN EAGLE OUTFITTERS INC COM' },
  { symbol: 'AFL', name: 'AFLAC INC COM' },
  { symbol: 'AFRM', name: 'AFFIRM HLDGS INC COM CL A' },
  { symbol: 'AG', name: 'FIRST MAJESTIC SILVER CORP COM' },
  { symbol: 'AGNC', name: 'AGNC INVT CORP COM' },
  { symbol: 'AI', name: 'C3 AI INC CL A' },
  { symbol: 'AIG', name: 'AMERICAN INTL GROUP INC COM NEW' },
  { symbol: 'AKAM', name: 'AKAMAI TECHNOLOGIES INC COM' },
  { symbol: 'ALB', name: 'ALBEMARLE CORP COM' },
  { symbol: 'ALGN', name: 'ALIGN TECHNOLOGY INC COM' },
  { symbol: 'ALLY', name: 'ALLY FINL INC COM' },
  { symbol: 'ALT', name: 'ALTIMMUNE INC COM NEW' },
  { symbol: 'AMAT', name: 'APPLIED MATLS INC COM' },
  { symbol: 'AMBA', name: 'AMBARELLA INC SHS' },
  { symbol: 'AMC', name: 'AMC ENTMT HLDGS INC CL A NEW' },
  { symbol: 'AMD', name: 'ADVANCED MICRO DEVICES INC COM' },
  { symbol: 'AMGN', name: 'AMGEN INC COM' },
  { symbol: 'AMZN', name: 'AMAZON COM INC COM' },
  { symbol: 'ANET', name: 'ARISTA NETWORKS INC COM' },
  { symbol: 'ANF', name: 'ABERCROMBIE & FITCH CO CL A' },
  { symbol: 'ANVS', name: 'ANNOVIS BIO INC COM' },
  { symbol: 'APA', name: 'APA CORPORATION COM' },
  { symbol: 'APLD', name: 'APPLIED DIGITAL CORP COM NEW' },
  { symbol: 'APO', name: 'APOLLO GLOBAL MGMT INC COM' },
  { symbol: 'APP', name: 'APPLOVIN CORP COM CL A' },
  { symbol: 'APT', name: 'ALPHA PRO TECH LTD COM' },
  { symbol: 'AR', name: 'ANTERO RESOURCES CORP COM' },
  { symbol: 'ARDX', name: 'ARDELYX INC COM' },
  { symbol: 'ARM', name: 'ARM HOLDINGS PLC SPONSORED ADS' },
  { symbol: 'ASAN', name: 'ASANA INC CL A' },
  { symbol: 'ASML', name: 'ASML HOLDING N V N Y REGISTRY SHS' },
  { symbol: 'ASO', name: 'ACADEMY SPORTS & OUTDOORS INC COM' },
  { symbol: 'ASTS', name: 'AST SPACEMOBILE INC COM CL A' },
  { symbol: 'ATMU', name: 'ATMUS FILTRATION TECHNOLOGIES COM' },
  { symbol: 'AVGO', name: 'BROADCOM INC COM' },
  { symbol: 'AVTR', name: 'AVANTOR INC COM' },
  { symbol: 'AVXL', name: 'ANAVEX LIFE SCIENCES CORP COM NEW' },
  { symbol: 'AXP', name: 'AMERICAN EXPRESS CO COM' },
  { symbol: 'AZN', name: 'ASTRAZENECA PLC SPONSORED ADR' },
  { symbol: 'BA', name: 'BOEING CO COM' },
  { symbol: 'BABA', name: 'ALIBABA GROUP HLDG LTD SPONSORED ADS' },
  { symbol: 'BAC', name: 'BANK AMERICA CORP COM' },
  { symbol: 'BAX', name: 'BAXTER INTL INC COM' },
  { symbol: 'BB', name: 'BLACKBERRY LTD COM' },
  { symbol: 'BBAI', name: 'BIGBEAR AI HLDGS INC COM' },
  { symbol: 'BBIO', name: 'BRIDGEBIO PHARMA INC COM' },
  { symbol: 'BBWI', name: 'BATH & BODY WORKS INC COM' },
  { symbol: 'BBY', name: 'BEST BUY INC COM' },
  { symbol: 'BE', name: 'BLOOM ENERGY CORP COM CL A' },
  { symbol: 'BEKE', name: 'KE HLDGS INC SPONSORED ADS' },
  { symbol: 'BHC', name: 'BAUSCH HEALTH COS INC COM' },
  { symbol: 'BIDU', name: 'BAIDU INC SPON ADR REP A' },
  { symbol: 'BIIB', name: 'BIOGEN INC COM' },
  { symbol: 'BILI', name: 'BILIBILI INC SPONS ADS REP Z' },
  { symbol: 'BILL', name: 'BILL HOLDINGS INC COM' },
  { symbol: 'BITF', name: 'BITFARMS LTD COM' },
  { symbol: 'BKNG', name: 'BOOKING HOLDINGS INC COM' },
  { symbol: 'BLK', name: 'BLACKROCK INC COM' },
  { symbol: 'BLNK', name: 'BLINK CHARGING CO COM' },
  { symbol: 'BMBL', name: 'BUMBLE INC COM CL A' },
  { symbol: 'BMY', name: 'BRISTOL-MYERS SQUIBB CO COM' },
  { symbol: 'BP', name: 'BP PLC SPONSORED ADR' },
  { symbol: 'BSX', name: 'BOSTON SCIENTIFIC CORP COM' },
  { symbol: 'BTBT', name: 'BIT DIGITAL INC SHS' },
  { symbol: 'BTU', name: 'PEABODY ENERGY CORP COM' },
  { symbol: 'BUD', name: 'ANHEUSER BUSCH INBEV SA/NV SPONSORED ADR' },
  { symbol: 'BURL', name: 'BURLINGTON STORES INC COM' },
  { symbol: 'BX', name: 'BLACKSTONE INC COM' },
  { symbol: 'BYND', name: 'BEYOND MEAT INC COM' },
  { symbol: 'BYON', name: 'BEYOND INC COM' },
  { symbol: 'C', name: 'CITIGROUP INC COM NEW' },
  { symbol: 'CAG', name: 'CONAGRA BRANDS INC COM' },
  { symbol: 'CAH', name: 'CARDINAL HEALTH INC COM' },
  { symbol: 'CAN', name: 'CANAAN INC SPONSORED ADS' },
  { symbol: 'CAR', name: 'AVIS BUDGET GROUP COM' },
  { symbol: 'CART', name: 'MAPLEBEAR INC COM' },
  { symbol: 'CAT', name: 'CATERPILLAR INC COM' },
  { symbol: 'CAVA', name: 'CAVA GROUP INC COM' },
  { symbol: 'CBOE', name: 'Cboe Global Markets, Inc.' },
  { symbol: 'CC', name: 'CHEMOURS CO COM' },
  { symbol: 'CCJ', name: 'CAMECO CORP COM' },
  { symbol: 'CCL', name: 'CARNIVAL CORP PAIRED CTF' },
  { symbol: 'CELH', name: 'CELSIUS HLDGS INC COM NEW' },
  { symbol: 'CF', name: 'CF INDS HLDGS INC COM' },
  { symbol: 'CGC', name: 'CANOPY GROWTH CORP COM NEW' },
  { symbol: 'CHPT', name: 'CHARGEPOINT HOLDINGS INC COM CL A' },
  { symbol: 'CHTR', name: 'CHARTER COMMUNICATIONS INC NEW CL A' },
  { symbol: 'CHWY', name: 'CHEWY INC CL A' },
  { symbol: 'CI', name: 'THE CIGNA GROUP COM' },
  { symbol: 'CIFR', name: 'CIPHER MINING INC COM' },
  { symbol: 'CL', name: 'COLGATE PALMOLIVE CO COM' },
  { symbol: 'CLF', name: 'CLEVELAND-CLIFFS INC NEW COM' },
  { symbol: 'CLOV', name: 'CLOVER HEALTH INVESTMENTS CORP COM CL A' },
  { symbol: 'CLS', name: 'CELESTICA INC COM' },
  { symbol: 'CLSK', name: 'CLEANSPARK INC COM NEW' },
  { symbol: 'CLX', name: 'CLOROX CO DEL COM' },
  { symbol: 'CMA', name: 'COMERICA INC COM' },
  { symbol: 'CMCSA', name: 'COMCAST CORP NEW CL A' },
  { symbol: 'CMG', name: 'CHIPOTLE MEXICAN GRILL INC COM' },
  { symbol: 'CMI', name: 'CUMMINS INC COM' },
  { symbol: 'CNC', name: 'CENTENE CORP DEL COM' },
  { symbol: 'COF', name: 'CAPITAL ONE FINL CORP COM' },
  { symbol: 'COHR', name: 'COHERENT CORP COM' },
  { symbol: 'COIN', name: 'COINBASE GLOBAL INC COM CL A' },
  { symbol: 'COP', name: 'CONOCOPHILLIPS COM' },
  { symbol: 'CORZ', name: 'CORE SCIENTIFIC INC NEW COM' },
  { symbol: 'COST', name: 'COSTCO WHSL CORP NEW COM' },
  { symbol: 'COTY', name: 'COTY INC COM CL A' },
  { symbol: 'CPB', name: 'CAMPBELL SOUP CO COM' },
  { symbol: 'CPNG', name: 'COUPANG INC CL A' },
  { symbol: 'CPRI', name: 'CAPRI HOLDINGS LIMITED SHS' },
  { symbol: 'CRM', name: 'SALESFORCE INC COM' },
  { symbol: 'CROX', name: 'CROCS INC COM' },
  { symbol: 'CRSP', name: 'CRISPR THERAPEUTICS AG NAMEN AKT' },
  { symbol: 'CRWD', name: 'CROWDSTRIKE HLDGS INC CL A' },
  { symbol: 'CSCO', name: 'CISCO SYS INC COM' },
  { symbol: 'CSIQ', name: 'CANADIAN SOLAR INC COM' },
  { symbol: 'CSTM', name: 'CONSTELLIUM SE CL A SHS' },
  { symbol: 'CSX', name: 'CSX CORP COM' },
  { symbol: 'CTRA', name: 'COTERRA ENERGY INC COM' },
  { symbol: 'CVE', name: 'CENOVUS ENERGY INC COM' },
  { symbol: 'CVNA', name: 'CARVANA CO CL A' },
  { symbol: 'CVS', name: 'CVS HEALTH CORP COM' },
  { symbol: 'CVX', name: 'CHEVRON CORP NEW COM' },
  { symbol: 'CZR', name: 'CAESARS ENTERTAINMENT INC NEW COM' },
  { symbol: 'DAL', name: 'DELTA AIR LINES INC DEL COM NEW' },
  { symbol: 'DASH', name: 'DOORDASH INC CL A' },
  { symbol: 'DB', name: 'DEUTSCHE BANK A G NAMEN AKT' },
  { symbol: 'DBX', name: 'DROPBOX INC CL A' },
  { symbol: 'DD', name: 'DUPONT DE NEMOURS INC COM' },
  { symbol: 'DDOG', name: 'DATADOG INC CL A COM' },
  { symbol: 'DE', name: 'DEERE & CO COM' },
  { symbol: 'DELL', name: 'DELL TECHNOLOGIES INC CL C' },
  { symbol: 'DFS', name: 'DISCOVER FINL SVCS COM' },
  { symbol: 'DG', name: 'DOLLAR GEN CORP NEW COM' },
  { symbol: 'DHI', name: 'D R HORTON INC COM' },
  { symbol: 'DHR', name: 'DANAHER CORPORATION COM' },
  { symbol: 'DIS', name: 'DISNEY WALT CO COM' },
  { symbol: 'DJT', name: 'TRUMP MEDIA & TECHNOLOGY GROUP COM' },
  { symbol: 'DKNG', name: 'DRAFTKINGS INC NEW COM CL A' },
  { symbol: 'DKS', name: 'DICKS SPORTING GOODS INC COM' },
  { symbol: 'DLR', name: 'DIGITAL RLTY TR INC COM' },
  { symbol: 'DLTR', name: 'DOLLAR TREE INC COM' },
  { symbol: 'DOCU', name: 'DOCUSIGN INC COM' },
  { symbol: 'DOW', name: 'DOW INC COM' },
  { symbol: 'DPZ', name: 'DOMINOS PIZZA INC COM' },
  { symbol: 'DVN', name: 'DEVON ENERGY CORP NEW COM' },
  { symbol: 'DXCM', name: 'DEXCOM INC COM' },
  { symbol: 'EA', name: 'ELECTRONIC ARTS INC COM' },
  { symbol: 'EBAY', name: 'EBAY INC. COM' },
  { symbol: 'EDIT', name: 'EDITAS MEDICINE INC COM' },
  { symbol: 'EDU', name: 'NEW ORIENTAL ED & TECHNOLOGY G SPON ADR' },
  { symbol: 'EL', name: 'LAUDER ESTEE COS INC CL A' },
  { symbol: 'ELF', name: 'E L F BEAUTY INC COM' },
  { symbol: 'EMR', name: 'EMERSON ELEC CO COM' },
  { symbol: 'ENPH', name: 'ENPHASE ENERGY INC COM' },
  { symbol: 'ENVX', name: 'ENOVIX CORPORATION COM' },
  { symbol: 'EOG', name: 'EOG RES INC COM' },
  { symbol: 'EPD', name: 'ENTERPRISE PRODS PARTNERS L P COM' },
  { symbol: 'EQT', name: 'EQT CORP COM' },
  { symbol: 'ET', name: 'ENERGY TRANSFER L P COM UT LTD PTN' },
  { symbol: 'ETSY', name: 'ETSY INC COM' },
  { symbol: 'EXPE', name: 'EXPEDIA GROUP INC COM NEW' },
  { symbol: 'F', name: 'FORD MTR CO DEL COM' },
  { symbol: 'FANG', name: 'DIAMONDBACK ENERGY INC COM' },
  { symbol: 'FCEL', name: 'FUELCELL ENERGY INC COM' },
  { symbol: 'FCX', name: 'FREEPORT-MCMORAN INC CL B' },
  { symbol: 'FDX', name: 'FEDEX CORP COM' },
  { symbol: 'FFIE', name: 'FARADAY FUTURE INTLGT ELEC INC COM CL A' },
  { symbol: 'FI', name: 'FISERV INC COM' },
  { symbol: 'FIS', name: 'FIDELITY NATL INFORMATION SVCS COM' },
  { symbol: 'FL', name: 'FOOT LOCKER INC COM' },
  { symbol: 'FOXA', name: 'FOX CORP CL A COM' },
  { symbol: 'FSLR', name: 'FIRST SOLAR INC COM' },
  { symbol: 'FSLY', name: 'FASTLY INC CL A' },
  { symbol: 'FTNT', name: 'FORTINET INC COM' },
  { symbol: 'FUBO', name: 'FUBOTV INC COM' },
  { symbol: 'FUTU', name: 'FUTU HLDGS LTD SPON ADS CL A' },
  { symbol: 'GD', name: 'GENERAL DYNAMICS CORP COM' },
  { symbol: 'GDDY', name: 'GODADDY INC CL A' },
  { symbol: 'GE', name: 'GE AEROSPACE COM NEW' },
  { symbol: 'GEHC', name: 'GE HEALTHCARE TECHNOLOGIES INC COMMON STOCK' },
  { symbol: 'GEO', name: 'GEO GROUP INC NEW COM' },
  { symbol: 'GERN', name: 'GERON CORP COM' },
  { symbol: 'GILD', name: 'GILEAD SCIENCES INC COM' },
  { symbol: 'GIS', name: 'GENERAL MLS INC COM' },
  { symbol: 'GL', name: 'GLOBE LIFE INC COM' },
  { symbol: 'GLW', name: 'CORNING INC COM' },
  { symbol: 'GM', name: 'GENERAL MTRS CO COM' },
  { symbol: 'GME', name: 'GAMESTOP CORP NEW CL A' },
  { symbol: 'GNRC', name: 'GENERAC HLDGS INC COM' },
  { symbol: 'GOLD', name: 'BARRICK GOLD CORP COM' },
  { symbol: 'GOOG', name: 'ALPHABET INC CAP STK CL C' },
  { symbol: 'GOOGL', name: 'ALPHABET INC CAP STK CL A' },
  { symbol: 'GOOS', name: 'CANADA GOOSE HLDGS INC SHS SUB VTG' },
  { symbol: 'GPS', name: 'GAP INC COM' },
  { symbol: 'GRPN', name: 'GROUPON INC COM NEW' },
  { symbol: 'GS', name: 'GOLDMAN SACHS GROUP INC COM' },
  { symbol: 'GSAT', name: 'GLOBALSTAR INC COM' },
  { symbol: 'GSK', name: 'GSK PLC SPONSORED ADR' },
  { symbol: 'GTLB', name: 'GITLAB INC CLASS A COM' },
  { symbol: 'HAL', name: 'HALLIBURTON CO COM' },
  { symbol: 'HD', name: 'HOME DEPOT INC COM' },
  { symbol: 'HE', name: 'HAWAIIAN ELEC INDUSTRIES COM' },
  { symbol: 'HES', name: 'HESS CORP COM' },
  { symbol: 'HIMS', name: 'HIMS & HERS HEALTH INC COM CL A' },
  { symbol: 'HIVE', name: 'HIVE DIGITAL TECHNOLOGIES LTD COM NEW' },
  { symbol: 'HL', name: 'HECLA MNG CO COM' },
  { symbol: 'HLF', name: 'HERBALIFE LTD COM SHS' },
  { symbol: 'HOG', name: 'HARLEY DAVIDSON INC COM' },
  { symbol: 'HON', name: 'HONEYWELL INTL INC COM' },
  { symbol: 'HOOD', name: 'ROBINHOOD MKTS INC COM CL A' },
  { symbol: 'HPE', name: 'HEWLETT PACKARD ENTERPRISE CO COM' },
  { symbol: 'HPQ', name: 'HP INC COM' },
  { symbol: 'HRL', name: 'HORMEL FOODS CORP COM' },
  { symbol: 'HSBC', name: 'HSBC HLDGS PLC SPON ADR NEW' },
  { symbol: 'HSY', name: 'HERSHEY CO COM' },
  { symbol: 'HTZ', name: 'HERTZ GLOBAL HLDGS INC COM NEW' },
  { symbol: 'HUM', name: 'HUMANA INC COM' },
  { symbol: 'HUT', name: 'HUT 8 CORP COM' },
  { symbol: 'IBM', name: 'INTERNATIONAL BUSINESS MACHS COM' },
  { symbol: 'IBRX', name: 'IMMUNITYBIO INC COM' },
  { symbol: 'IEP', name: 'ICAHN ENTERPRISES LP DEPOSITARY UNIT' },
  { symbol: 'ILMN', name: 'ILLUMINA INC COM' },
  { symbol: 'INO', name: 'INOVIO PHARMACEUTICALS INC COM SHS' },
  { symbol: 'INTC', name: 'INTEL CORP COM' },
  { symbol: 'INTU', name: 'INTUIT COM' },
  { symbol: 'IONQ', name: 'IONQ INC COM' },
  { symbol: 'IOT', name: 'SAMSARA INC COM CL A' },
  { symbol: 'IOVA', name: 'IOVANCE BIOTHERAPEUTICS INC COM' },
  { symbol: 'IP', name: 'INTERNATIONAL PAPER CO COM' },
  { symbol: 'IQ', name: 'IQIYI INC SPONSORED ADS' },
  { symbol: 'IRBT', name: 'IROBOT CORP COM' },
  { symbol: 'IREN', name: 'IRIS ENERGY LTD ORDINARY SHARES' },
  { symbol: 'ISRG', name: 'INTUITIVE SURGICAL INC COM NEW' },
  { symbol: 'IVR', name: 'INVESCO MORTGAGE CAPITAL INC COM' },
  { symbol: 'JBLU', name: 'JETBLUE AWYS CORP COM' },
  { symbol: 'JD', name: 'JD.COM INC SPON ADS CL A' },
  { symbol: 'JMIA', name: 'JUMIA TECHNOLOGIES AG SPONSORED ADS' },
  { symbol: 'JNJ', name: 'JOHNSON & JOHNSON COM' },
  { symbol: 'JOBY', name: 'JOBY AVIATION INC COMMON STOCK' },
  { symbol: 'JPM', name: 'JPMORGAN CHASE & CO. COM' },
  { symbol: 'JWN', name: 'NORDSTROM INC COM' },
  { symbol: 'KEY', name: 'KEYCORP COM' },
  { symbol: 'KGC', name: 'KINROSS GOLD CORP COM' },
  { symbol: 'KHC', name: 'KRAFT HEINZ CO COM' },
  { symbol: 'KKR', name: 'KKR & CO INC COM' },
  { symbol: 'KLAC', name: 'KLA CORP COM NEW' },
  { symbol: 'KMB', name: 'KIMBERLY-CLARK CORP COM' },
  { symbol: 'KMI', name: 'KINDER MORGAN INC DEL COM' },
  { symbol: 'KO', name: 'COCA COLA CO COM' },
  { symbol: 'KR', name: 'KROGER CO COM' },
  { symbol: 'KSS', name: 'KOHLS CORP COM' },
  { symbol: 'KVUE', name: 'KENVUE INC COM' },
  { symbol: 'LAZR', name: 'LUMINAR TECHNOLOGIES INC COM CL A' },
  { symbol: 'LCID', name: 'LUCID GROUP INC COM' },
  { symbol: 'LEN', name: 'LENNAR CORP CL A' },
  { symbol: 'LI', name: 'LI AUTO INC SPONSORED ADS' },
  { symbol: 'LII', name: 'LENNOX INTL INC COM' },
  { symbol: 'LLY', name: 'ELI LILLY & CO COM' },
  { symbol: 'LMND', name: 'LEMONADE INC COM' },
  { symbol: 'LMT', name: 'LOCKHEED MARTIN CORP COM' },
  { symbol: 'LNG', name: 'CHENIERE ENERGY INC COM NEW' },
  { symbol: 'LOW', name: 'LOWES COS INC COM' },
  { symbol: 'LRCX', name: 'LAM RESEARCH CORP COM' },
  { symbol: 'LULU', name: 'LULULEMON ATHLETICA INC COM' },
  { symbol: 'LUMN', name: 'LUMEN TECHNOLOGIES INC COM' },
  { symbol: 'LUNR', name: 'INTUITIVE MACHINES INC CLASS A COM' },
  { symbol: 'LUV', name: 'SOUTHWEST AIRLS CO COM' },
  { symbol: 'LVS', name: 'LAS VEGAS SANDS CORP COM' },
  { symbol: 'LYFT', name: 'LYFT INC CL A COM' },
  { symbol: 'M', name: 'MACYS INC COM' },
  { symbol: 'MA', name: 'MASTERCARD INCORPORATED CL A' },
  { symbol: 'MANU', name: 'MANCHESTER UTD PLC NEW ORD CL A' },
  { symbol: 'MAR', name: 'MARRIOTT INTL INC NEW CL A' },
  { symbol: 'MARA', name: 'MARATHON DIGITAL HOLDINGS INC COM' },
  { symbol: 'MAXN', name: 'MAXEON SOLAR TECHNOLOGIES LTD SHS' },
  { symbol: 'MBLY', name: 'MOBILEYE GLOBAL INC COMMON CLASS A' },
  { symbol: 'MCD', name: 'MCDONALDS CORP COM' },
  { symbol: 'MCK', name: 'MCKESSON CORP COM' },
  { symbol: 'MDB', name: 'MONGODB INC CL A' },
  { symbol: 'MDLZ', name: 'MONDELEZ INTL INC CL A' },
  { symbol: 'MDT', name: 'MEDTRONIC PLC SHS' },
  { symbol: 'MELI', name: 'MERCADOLIBRE INC COM' },
  { symbol: 'MET', name: 'METLIFE INC COM' },
  { symbol: 'META', name: 'META PLATFORMS INC CL A' },
  { symbol: 'MGM', name: 'MGM RESORTS INTERNATIONAL COM' },
  { symbol: 'MLCO', name: 'MELCO RESORTS AND ENTMNT LTD ADR' },
  { symbol: 'MMM', name: '3M CO COM' },
  { symbol: 'MNDY', name: 'MONDAY COM LTD SHS' },
  { symbol: 'MNKD', name: 'MANNKIND CORP COM NEW' },
  { symbol: 'MNST', name: 'MONSTER BEVERAGE CORP NEW COM' },
  { symbol: 'MO', name: 'ALTRIA GROUP INC COM' },
  { symbol: 'MOS', name: 'MOSAIC CO NEW COM' },
  { symbol: 'MPC', name: 'MARATHON PETE CORP COM' },
  { symbol: 'MPW', name: 'MEDICAL PPTYS TRUST INC COM' },
  { symbol: 'MRK', name: 'MERCK & CO INC COM' },
  { symbol: 'MRNA', name: 'MODERNA INC COM' },
  { symbol: 'MRO', name: 'MARATHON OIL CORP COM' },
  { symbol: 'MRVL', name: 'MARVELL TECHNOLOGY INC COM' },
  { symbol: 'MS', name: 'MORGAN STANLEY COM NEW' },
  { symbol: 'MSFT', name: 'MICROSOFT CORP COM' },
  { symbol: 'MSTR', name: 'MICROSTRATEGY INC CL A NEW' },
  { symbol: 'MT', name: 'ARCELORMITTAL SA LUXEMBOURG NY REGISTRY SH' },
  { symbol: 'MTCH', name: 'MATCH GROUP INC NEW COM' },
  { symbol: 'MU', name: 'MICRON TECHNOLOGY INC COM' },
  { symbol: 'MVIS', name: 'MICROVISION INC DEL COM NEW' },
  { symbol: 'NCLH', name: 'NORWEGIAN CRUISE LINE HLDG LTD SHS' },
  { symbol: 'NEE', name: 'NEXTERA ENERGY INC COM' },
  { symbol: 'NEM', name: 'NEWMONT CORP COM' },
  { symbol: 'NEP', name: 'NEXTERA ENERGY PARTNERS LP COM UNIT PART IN' },
  { symbol: 'NET', name: 'CLOUDFLARE INC CL A COM' },
  { symbol: 'NFE', name: 'NEW FORTRESS ENERGY INC COM CL A' },
  { symbol: 'NFLX', name: 'NETFLIX INC COM' },
  { symbol: 'NIO', name: 'NIO INC SPON ADS' },
  { symbol: 'NKE', name: 'NIKE INC CL B' },
  { symbol: 'NKLA', name: 'NIKOLA CORP COM NEW' },
  { symbol: 'NLY', name: 'ANNALY CAPITAL MANAGEMENT INC COM NEW' },
  { symbol: 'NOC', name: 'NORTHROP GRUMMAN CORP COM' },
  { symbol: 'NOK', name: 'NOKIA CORP SPONSORED ADR' },
  { symbol: 'NOW', name: 'SERVICENOW INC COM' },
  { symbol: 'NSC', name: 'NORFOLK SOUTHN CORP COM' },
  { symbol: 'NTAP', name: 'NETAPP INC COM' },
  { symbol: 'NTES', name: 'NETEASE INC SPONSORED ADS' },
  { symbol: 'NTR', name: 'NUTRIEN LTD COM' },
  { symbol: 'NU', name: 'NU HLDGS LTD ORD SHS CL A' },
  { symbol: 'NUE', name: 'NUCOR CORP COM' },
  { symbol: 'NVAX', name: 'NOVAVAX INC COM NEW' },
  { symbol: 'NVDA', name: 'NVIDIA CORPORATION COM' },
  { symbol: 'NVO', name: 'NOVO-NORDISK A S ADR' },
  { symbol: 'NXPI', name: 'NXP SEMICONDUCTORS N V COM' },
  { symbol: 'NYCB', name: 'NEW YORK CMNTY BANCORP INC COM NEW' },
  { symbol: 'OKTA', name: 'OKTA INC CL A' },
  { symbol: 'ON', name: 'ON SEMICONDUCTOR CORP COM' },
  { symbol: 'ONON', name: 'ON HLDG AG NAMEN AKT A' },
  { symbol: 'OPEN', name: 'OPENDOOR TECHNOLOGIES INC COM' },
  { symbol: 'ORCL', name: 'ORACLE CORP COM' },
  { symbol: 'OXY', name: 'OCCIDENTAL PETE CORP COM' },
  { symbol: 'OZK', name: 'BANK OZK LITTLE ROCK ARK COM' },
  { symbol: 'PAA', name: 'PLAINS ALL AMERN PIPELINE L P UNIT LTD PARTN' },
  { symbol: 'PAAS', name: 'PAN AMERN SILVER CORP COM' },
  { symbol: 'PANW', name: 'PALO ALTO NETWORKS INC COM' },
  { symbol: 'PARA', name: 'PARAMOUNT GLOBAL CLASS B COM' },
  { symbol: 'PATH', name: 'UIPATH INC CL A' },
  { symbol: 'PBI', name: 'PITNEY BOWES INC COM' },
  { symbol: 'PBR', name: 'PETROLEO BRASILEIRO SA PETROBR SPONSORED ADR' },
  { symbol: 'PCG', name: 'PG&E CORP COM' },
  { symbol: 'PCT', name: 'PURECYCLE TECHNOLOGIES INC COM' },
  { symbol: 'PDD', name: 'PDD HOLDINGS INC SPONSORED ADS' },
  { symbol: 'PENN', name: 'PENN ENTERTAINMENT INC COM' },
  { symbol: 'PEP', name: 'PEPSICO INC COM' },
  { symbol: 'PFE', name: 'PFIZER INC COM' },
  { symbol: 'PG', name: 'PROCTER AND GAMBLE CO COM' },
  { symbol: 'PHM', name: 'PULTE GROUP INC COM' },
  { symbol: 'PINS', name: 'PINTEREST INC CL A' },
  { symbol: 'PLCE', name: 'CHILDRENS PL INC NEW COM' },
  { symbol: 'PLTR', name: 'PALANTIR TECHNOLOGIES INC CL A' },
  { symbol: 'PLUG', name: 'PLUG POWER INC COM NEW' },
  { symbol: 'PM', name: 'PHILIP MORRIS INTL INC COM' },
  { symbol: 'PNC', name: 'PNC FINL SVCS GROUP INC COM' },
  { symbol: 'PPG', name: 'PPG INDS INC COM' },
  { symbol: 'PSX', name: 'PHILLIPS 66 COM' },
  { symbol: 'PTON', name: 'PELOTON INTERACTIVE INC CL A COM' },
  { symbol: 'PYPL', name: 'PAYPAL HLDGS INC COM' },
  { symbol: 'QCOM', name: 'QUALCOMM INC COM' },
  { symbol: 'QS', name: 'QUANTUMSCAPE CORP COM CL A' },
  { symbol: 'RBLX', name: 'ROBLOX CORP CL A' },
  { symbol: 'RCL', name: 'ROYAL CARIBBEAN GROUP COM' },
  { symbol: 'RDDT', name: 'REDDIT INC CL A' },
  { symbol: 'RDFN', name: 'REDFIN CORP COM' },
  { symbol: 'REGN', name: 'REGENERON PHARMACEUTICALS COM' },
  { symbol: 'RH', name: 'RH COM' },
  { symbol: 'RIG', name: 'TRANSOCEAN LTD REGISTERED SHS' },
  { symbol: 'RILY', name: 'B. RILEY FINANCIAL INC COM' },
  { symbol: 'RIOT', name: 'RIOT PLATFORMS INC COM' },
  { symbol: 'RIVN', name: 'RIVIAN AUTOMOTIVE INC COM CL A' },
  { symbol: 'RKLB', name: 'ROCKET LAB USA INC COM' },
  { symbol: 'RKT', name: 'ROCKET COS INC COM CL A' },
  { symbol: 'RNG', name: 'RINGCENTRAL INC CL A' },
  { symbol: 'ROKU', name: 'ROKU INC COM CL A' },
  { symbol: 'ROST', name: 'ROSS STORES INC COM' },
  { symbol: 'RTX', name: 'RTX CORPORATION COM' },
  { symbol: 'RUM', name: 'RUMBLE INC COM CL A' },
  { symbol: 'RUN', name: 'SUNRUN INC COM' },
  { symbol: 'S', name: 'SENTINELONE INC CL A' },
  { symbol: 'SAVA', name: 'CASSAVA SCIENCES INC COM' },
  { symbol: 'SAVE', name: 'SPIRIT AIRLS INC COM' },
  { symbol: 'SBUX', name: 'STARBUCKS CORP COM' },
  { symbol: 'SCHW', name: 'SCHWAB CHARLES CORP COM' },
  { symbol: 'SE', name: 'SEA LTD SPONSORD ADS' },
  { symbol: 'SEDG', name: 'SOLAREDGE TECHNOLOGIES INC COM' },
  { symbol: 'SFIX', name: 'STITCH FIX INC COM CL A' },
  { symbol: 'SHAK', name: 'SHAKE SHACK INC CL A' },
  { symbol: 'SHEL', name: 'SHELL PLC SPON ADS' },
  { symbol: 'SHOP', name: 'SHOPIFY INC CL A' },
  { symbol: 'SHOT', name: 'SAFETY SHOT INC COM NEW' },
  { symbol: 'SIG', name: 'SIGNET JEWELERS LIMITED SHS' },
  { symbol: 'SIRI', name: 'SIRIUS XM HOLDINGS INC COM' },
  { symbol: 'SLB', name: 'SCHLUMBERGER LTD COM STK' },
  { symbol: 'SMCI', name: 'SUPER MICRO COMPUTER INC COM' },
  { symbol: 'SMR', name: 'NUSCALE PWR CORP CL A COM' },
  { symbol: 'SNAP', name: 'SNAP INC CL A' },
  { symbol: 'SNDL', name: 'SNDL INC COM' },
  { symbol: 'SNOW', name: 'SNOWFLAKE INC CL A' },
  { symbol: 'SNV', name: 'SYNOVUS FINL CORP COM NEW' },
  { symbol: 'SO', name: 'SOUTHERN CO COM' },
  { symbol: 'SOFI', name: 'SOFI TECHNOLOGIES INC COM' },
  { symbol: 'SONY', name: 'SONY GROUP CORP SPONSORED ADR' },
  { symbol: 'SOUN', name: 'SOUNDHOUND AI INC CLASS A COM' },
  { symbol: 'SPCE', name: 'VIRGIN GALACTIC HOLDINGS INC COM NEW' },
  { symbol: 'SPGI', name: 'S&P GLOBAL INC COM' },
  { symbol: 'SPHR', name: 'SPHERE ENTERTAINMENT CO CL A' },
  { symbol: 'SPOT', name: 'SPOTIFY TECHNOLOGY S A SHS' },
  { symbol: 'SPR', name: 'SPIRIT AEROSYSTEMS HLDGS INC COM CL A' },
  { symbol: 'SPWR', name: 'SUNPOWER CORP COM' },
  { symbol: 'SQ', name: 'BLOCK INC CL A' },
  { symbol: 'SRPT', name: 'SAREPTA THERAPEUTICS INC COM' },
  { symbol: 'STNE', name: 'STONECO LTD COM CL A' },
  { symbol: 'STNG', name: 'SCORPIO TANKERS INC SHS' },
  { symbol: 'STX', name: 'SEAGATE TECHNOLOGY HLDNGS PLC ORD SHS' },
  { symbol: 'STZ', name: 'CONSTELLATION BRANDS INC CL A' },
  { symbol: 'SU', name: 'SUNCOR ENERGY INC NEW COM' },
  { symbol: 'SWKS', name: 'SKYWORKS SOLUTIONS INC COM' },
  { symbol: 'SWN', name: 'SOUTHWESTERN ENERGY CO COM' },
  { symbol: 'SYM', name: 'SYMBOTIC INC CLASS A COM' },
  { symbol: 'T', name: 'AT&T INC COM' },
  { symbol: 'TAL', name: 'TAL EDUCATION GROUP SPONSORED ADS' },
  { symbol: 'TDOC', name: 'TELADOC HEALTH INC COM' },
  { symbol: 'TEAM', name: 'ATLASSIAN CORPORATION CL A' },
  { symbol: 'TECK', name: 'TECK RESOURCES LTD CL B' },
  { symbol: 'TELL', name: 'TELLURIAN INC NEW COM' },
  { symbol: 'TEVA', name: 'TEVA PHARMACEUTICAL INDS LTD SPONSORED ADS' },
  { symbol: 'TFC', name: 'TRUIST FINL CORP COM' },
  { symbol: 'TGT', name: 'TARGET CORP COM' },
  { symbol: 'TGTX', name: 'TG THERAPEUTICS INC COM' },
  { symbol: 'TJX', name: 'TJX COS INC NEW COM' },
  { symbol: 'TLRY', name: 'TILRAY BRANDS INC COM' },
  { symbol: 'TMO', name: 'THERMO FISHER SCIENTIFIC INC COM' },
  { symbol: 'TMUS', name: 'T-MOBILE US INC COM' },
  { symbol: 'TOL', name: 'TOLL BROTHERS INC COM' },
  { symbol: 'TOST', name: 'TOAST INC CL A' },
  { symbol: 'TPR', name: 'TAPESTRY INC COM' },
  { symbol: 'TRIP', name: 'TRIPADVISOR INC COM' },
  { symbol: 'TSCO', name: 'TRACTOR SUPPLY CO COM' },
  { symbol: 'TSLA', name: 'TESLA INC COM' },
  { symbol: 'TSM', name: 'TAIWAN SEMICONDUCTOR MFG LTD SPONSORED ADS' },
  { symbol: 'TSN', name: 'TYSON FOODS INC CL A' },
  { symbol: 'TTD', name: 'THE TRADE DESK INC COM CL A' },
  { symbol: 'TTWO', name: 'TAKE-TWO INTERACTIVE SOFTWARE COM' },
  { symbol: 'TUP', name: 'TUPPERWARE BRANDS CORP COM' },
  { symbol: 'TWLO', name: 'TWILIO INC CL A' },
  { symbol: 'TXN', name: 'TEXAS INSTRS INC COM' },
  { symbol: 'U', name: 'UNITY SOFTWARE INC COM' },
  { symbol: 'UAA', name: 'UNDER ARMOUR INC CL A' },
  { symbol: 'UAL', name: 'UNITED AIRLS HLDGS INC COM' },
  { symbol: 'UBER', name: 'UBER TECHNOLOGIES INC COM' },
  { symbol: 'UEC', name: 'URANIUM ENERGY CORP COM' },
  { symbol: 'ULTA', name: 'ULTA BEAUTY INC COM' },
  { symbol: 'UNH', name: 'UNITEDHEALTH GROUP INC COM' },
  { symbol: 'UNP', name: 'UNION PAC CORP COM' },
  { symbol: 'UPS', name: 'UNITED PARCEL SERVICE INC CL B' },
  { symbol: 'UPST', name: 'UPSTART HLDGS INC COM' },
  { symbol: 'URBN', name: 'URBAN OUTFITTERS INC COM' },
  { symbol: 'URI', name: 'UNITED RENTALS INC COM' },
  { symbol: 'USB', name: 'US BANCORP DEL COM NEW' },
  { symbol: 'UWMC', name: 'UWM HOLDINGS CORPORATION COM CL A' },
  { symbol: 'V', name: 'VISA INC COM CL A' },
  { symbol: 'VALE', name: 'VALE S A SPONSORED ADS' },
  { symbol: 'VERU', name: 'VERU INC COM' },
  { symbol: 'VFC', name: 'V F CORP COM' },
  { symbol: 'VFS', name: 'VINFAST AUTO LTD SHS' },
  { symbol: 'VKTX', name: 'VIKING THERAPEUTICS INC COM' },
  { symbol: 'VLO', name: 'VALERO ENERGY CORP COM' },
  { symbol: 'VOD', name: 'VODAFONE GROUP PLC NEW SPONSORED ADR' },
  { symbol: 'VRT', name: 'VERTIV HOLDINGS CO COM CL A' },
  { symbol: 'VRTX', name: 'VERTEX PHARMACEUTICALS INC COM' },
  { symbol: 'VST', name: 'VISTRA CORP COM' },
  { symbol: 'VZ', name: 'VERIZON COMMUNICATIONS INC COM' },
  { symbol: 'W', name: 'WAYFAIR INC CL A' },
  { symbol: 'WAL', name: 'WESTERN ALLIANCE BANCORP COM' },
  { symbol: 'WB', name: 'WEIBO CORP SPONSORED ADR' },
  { symbol: 'WBA', name: 'WALGREENS BOOTS ALLIANCE INC COM' },
  { symbol: 'WBD', name: 'WARNER BROS DISCOVERY INC COM SER A' },
  { symbol: 'WDAY', name: 'WORKDAY INC CL A' },
  { symbol: 'WDC', name: 'WESTERN DIGITAL CORP. COM' },
  { symbol: 'WFC', name: 'WELLS FARGO CO NEW COM' },
  { symbol: 'WHR', name: 'WHIRLPOOL CORP COM' },
  { symbol: 'WKHS', name: 'WORKHORSE GROUP INC COM SHS' },
  { symbol: 'WM', name: 'WASTE MGMT INC DEL COM' },
  { symbol: 'WMB', name: 'WILLIAMS COS INC COM' },
  { symbol: 'WMT', name: 'WALMART INC COM' },
  { symbol: 'WOLF', name: 'WOLFSPEED INC COM' },
  { symbol: 'WPM', name: 'WHEATON PRECIOUS METALS CORP COM' },
  { symbol: 'WULF', name: 'TERAWULF INC COM' },
  { symbol: 'WW', name: 'WW INTL INC COM' },
  { symbol: 'WYNN', name: 'WYNN RESORTS LTD COM' },
  { symbol: 'X', name: 'UNITED STATES STL CORP NEW COM' },
  { symbol: 'XOM', name: 'EXXON MOBIL CORP COM' },
  { symbol: 'XP', name: 'XP INC CL A' },
  { symbol: 'XPEV', name: 'XPENG INC ADS' },
  { symbol: 'YPF', name: 'YPF SOCIEDAD ANONIMA SPON ADR CL D' },
  { symbol: 'Z', name: 'ZILLOW GROUP INC CL C CAP STK' },
  { symbol: 'ZIM', name: 'ZIM INTEGRATED SHIPPING SERV SHS' },
  { symbol: 'ZION', name: 'ZIONS BANCORPORATION N A COM' },
  { symbol: 'ZM', name: 'ZOOM VIDEO COMMUNICATIONS INC CL A' },
  { symbol: 'ZS', name: 'ZSCALER INC COM' },
];

export const EARNINGS = [
  {
    name: 'Today',
    value: 'today',
  },
  {
    name: 'Tomorrow',
    value: 'tomorrow',
  },
  {
    name: 'Next 7D',
    value: 'Next 7D',
  },
  {
    name: 'Next 30D',
    value: 'Next 30D',
  },
  {
    name: 'Past 7D',
    value: 'Past 7D',
  },
  {
    name: 'Past 30D',
    value: 'Past 30D',
  },
  {
    name: 'This Month',
    value: 'thisMonth',
  },
  {
    name: 'Next Month',
    value: 'nextMonth',
  },
];

export const FILTERLIST = [
  {
    name: 'Price',
    value: 'price',
  },
  {
    name: 'Volume',
    value: 'volume',
  },
  {
    name: 'Earnings',
    value: 'earnings',
  },
  {
    name: 'Market Cap',
    value: 'marketcap',
  },
  {
    name: 'Price Change 1 Day',
    value: 'change1Day',
  },
  {
    name: 'Price Change Last 7 Day',
    value: 'change7Days',
  },
  {
    name: 'Price Change 30 Day',
    value: 'change30Days',
  },
  {
    name: 'Price Change 60 Day',
    value: 'change60Days',
  },
  {
    name: 'Price Change 90 Day',
    value: 'change90Days',
  },
  {
    name: 'Price Change 180 Day',
    value: 'change180Days',
  },
  {
    name: 'Price Change  Year To CurrentDate',
    value: 'change1YTD',
  },
  {
    name: 'Price Change 365 Day',
    value: 'change365Days',
  },
];

export const COUNTRY = [
  { name: 'United States', value: 'united-states' },
  { name: 'China', value: 'china' },
  { name: 'Ireland', value: 'ireland' },
  { name: 'Luxembourg', value: 'luxembourg' },
  { name: 'Germany', value: 'germany' },
  { name: 'Canada', value: 'canada' },
  { name: 'Switzerland', value: 'switzerland' },
  { name: 'United Kingdom', value: 'united-kingdom' },
  { name: 'Belgium', value: 'belgium' },
  { name: 'Denmark', value: 'denmark' },
  { name: 'Bermuda', value: 'bermuda' },
  { name: 'Netherlands', value: 'netherlands' },
  { name: 'South Africa', value: 'south-africa' },
  { name: 'Mexico', value: 'mexico' },
  { name: 'Taiwan', value: 'taiwan' },
  { name: 'Uruguay', value: 'uruguay' },
  { name: 'Spain', value: 'spain' },
  { name: 'Singapore', value: 'singapore' },
  { name: 'Sweden', value: 'sweden' },
  { name: 'Brazil', value: 'brazil' },
  { name: 'Israel', value: 'israel' },
  { name: 'Argentina', value: 'argentina' },
  { name: 'Monaco', value: 'monaco' },
  { name: 'Australia', value: 'australia' },
  { name: 'Turkey', value: 'turkey' },
  { name: 'Colombia', value: 'colombia' },
  { name: 'Chile', value: 'chile' },
  { name: 'New Zealand', value: 'new-zealand' },
  { name: 'Japan', value: 'japan' },
  { name: 'Norway', value: 'norway' },
  { name: 'Hong Kong', value: 'hong-kong' },
  { name: 'France', value: 'france' },
  { name: 'Austria', value: 'austria' },
  { name: 'Italy', value: 'italy' },
  { name: 'Thailand', value: 'thailand' },
  { name: 'Malaysia', value: 'malaysia' },
  { name: 'India', value: 'india' },
  { name: 'Greece', value: 'greece' },
  { name: 'South Korea', value: 'south-korea' },
  { name: 'Peru', value: 'peru' },
  { name: 'Panama', value: 'panama' },
  { name: 'Cayman Islands', value: 'cayman-islands' },
  { name: 'Indonesia', value: 'indonesia' },
  { name: 'Finland', value: 'finland' },
  { name: 'Bahamas', value: 'bahamas' },
  { name: 'Russia', value: 'russia' },
  { name: 'Philippines', value: 'philippines' },
  { name: 'Macau', value: 'macau' },
  { name: 'Portugal', value: 'portugal' },
  { name: 'United Arab Emirates', value: 'united-arab-emirates' },
  { name: 'Romania', value: 'romania' },
  { name: 'Guernsey', value: 'guernsey' },
  { name: 'Jersey', value: 'jersey' },
  { name: 'Netherlands Antilles', value: 'netherlands-antilles' },
  { name: 'Belize', value: 'belize' },
  { name: 'Poland', value: 'poland' },
  { name: 'Cambodia', value: 'cambodia' },
  { name: 'British Virgin Islands', value: 'british-virgin-islands' },
  { name: 'Papua New Guinea', value: 'papua-new-guinea' },
  { name: 'Gibraltar', value: 'gibraltar' },
  { name: 'Tanzania', value: 'tanzania' },
  { name: 'Cyprus', value: 'cyprus' },
  { name: 'Czech Republic', value: 'czech-republic' },
  { name: 'Lebanon', value: 'lebanon' },
  { name: 'Myanmar', value: 'myanmar' },
  { name: 'Malta', value: 'malta' },
  { name: 'Ghana', value: 'ghana' },
  { name: 'Mauritius', value: 'mauritius' },
  { name: 'Isle of Man', value: 'isle-of-man' },
  { name: 'Ukraine', value: 'ukraine' },
  { name: 'Dominican Republic', value: 'dominican-republic' },
  { name: 'Egypt', value: 'egypt' },
  { name: 'Anguilla', value: 'anguilla' },
  { name: 'Macedonia', value: 'macedonia' },
  { name: 'Hungary', value: 'hungary' },
  { name: 'Vietnam', value: 'vietnam' },
  { name: 'Channel Islands', value: 'channel-islands' },
  { name: 'Kazakhstan', value: 'kazakhstan' },
];
export const INDUSTRY = [
  {
    name: 'Consumer Electronics',
    value: 'consumerelectronics',
  },
  {
    name: 'Banks—Diversified',
    value: 'banks-diversified',
  },
];
export const SECTOR = [
  {
    name: 'Technology',
    value: 'technology',
  },
  {
    name: 'Financial Services',
    value: 'financialservices',
  },
];
export const DAVGCHG50 = [
  {
    name: 'Postive (>0%)',
    value: 'postive(>0%)',
  },
  {
    name: 'Negative (<0%)',
    value: 'negative(<0%)',
  },
];
export const DAVGCHG200 = [
  {
    name: 'Postive (>0%)',
    value: 'postive(>0%)',
  },
  {
    name: 'Negative (<0%)',
    value: 'negative(<0%)',
  },
];
export const WHIGHCHG52 = [
  {
    name: 'Postive (>0%)',
    value: 'postive(>0%)',
  },
  {
    name: 'Negative (<0%)',
    value: 'negative(<0%)',
  },
];

export const COLORSETUPSCANNER = [
  {
    name: 'A',
    value: '#EAF7E3',
    color: '#CCECBB',
  },
  {
    name: 'B',
    value: '#F7E3F5',
    color: '#ECBBE7',
  },
  {
    name: 'C',
    value: '#E5F7E3',
    color: '#C0ECBB',
  },
  {
    name: 'D',
    value: '#F7F6E3',
    color: '#C0B937',
  },
  {
    name: 'E',
    value: '#E3F7E5',
    color: '#D1CA5B',
  },
  {
    name: 'F',
    value: '#E3F6F7',
    color: '#70D1D6',
  },
  {
    name: 'G',
    value: '#E4E3F7',
    color: '#9692E0',
  },
  {
    name: 'H',
    value: '#E3E8F7',
    color: '#7790D8',
  },
  {
    name: 'I',
    value: '#F7F2E3',
    color: '#D5BB6C',
  },
  {
    name: 'J',
    value: '#F0E3F7',
    color: '#B779D9',
  },
  {
    name: 'K',
    value: '#F7E3E6',
    color: '#D6707F',
  },
  {
    name: 'L',
    value: '#E3F7F4',
    color: '#58CFBD',
  },
  {
    name: 'M',
    value: '#e3f3f7',
    color: '#71C2D6',
  },
  {
    name: 'N',
    value: '#F0E3F7',
    color: '#B779D9',
  },
  {
    name: 'O',
    value: '#E5E3F7',
    color: '#7065D3',
  },
  {
    name: 'p',
    value: '#E3EBF7',
    color: '#6591D3',
  },
  {
    name: 'Q',
    value: '#F5F7E3',
    color: '#CCD670',
  },
  {
    name: 'R',
    value: '#E5E3F7',
    color: '#7D73D7',
  },
  {
    name: 'S',
    value: '#F7E3EF',
    color: '#D671AE',
  },
  {
    name: 'T',
    value: '#E3F7EB',
    color: '#65D391',
  },
  {
    name: 'U',
    value: '#E3E9F7',
    color: '#6A8AD5',
  },
  {
    name: 'V',
    value: '#F7F1E3',
    color: '#D2B061',
  },
  {
    name: 'W',
    value: '#ECE3F7',
    color: '#9766D3',
  },
  {
    name: 'X',
    value: '#EEF7E3',
    color: '#A6D56D',
  },
  {
    name: 'Y',
    value: '#E3F1F7',
    color: '#69B4D4',
  },
  {
    name: 'Z',
    value: '#E3F7EE',
    color: '#53CE96',
  },
];

export const FILTERSETUPDATA = [
  {
    name: 'Price',
    currentValue: '',
  },
  {
    name: 'Volume',
    currentValue: '',
  },
  {
    name: 'WeeklyOption',
    currentValue: '',
  },
  {
    name: 'Earnings',
    currentValue: '',
  },
  {
    name: 'MarketCap',
    currentValue: '',
  },
  {
    name: 'PriceChange1Days',
    currentValue: '',
  },
  {
    name: 'PriceChangeLast7Days',
    currentValue: '',
  },
  {
    name: 'PriceChange30Days',
    currentValue: '',
  },
  {
    name: 'PriceChange60Days',
    currentValue: '',
  },
  {
    name: 'PriceChange90Days',
    currentValue: '',
  },
  {
    name: 'PriceChange1YTD',
    currentValue: '',
  },
  {
    name: 'PriceChange365Days',
    currentValue: '',
  },
  {
    name: 'Country',
    currentValue: '',
  },
  {
    name: 'Industry',
    currentValue: '',
  },
  {
    name: 'MarketCap',
    currentValue: '',
  },
  {
    name: '50DAvgChg',
    currentValue: '',
  },
  {
    name: '200DAvgChg',
    currentValue: '',
  },
  {
    name: '52WHighChg',
    currentValue: '',
  },
];
