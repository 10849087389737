import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NotesRoutingModule } from './notes-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from '../date-picker/date-picker.module';
import { NotesComponent } from './notes.component';

@NgModule({
  declarations: [NotesComponent],
  imports: [
    CommonModule,
    IonicModule,
    NotesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
  ],
  exports: [NotesComponent],
})
export class NotesModule {}
