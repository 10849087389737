<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Have questions or need help?</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card>
    <ion-card-content>
      <p>
        <span
          ><b
            >Email:
            <a href="mailto:thecrew@tradersedgenetwork.com">thecrew@tradersedgenetwork.com</a>
          </b></span
        >
        <br />
        <span
          >(Our team checks this email regularly and will respond within 24 business hours)</span
        >
      </p>
      <p>
        <b>Phone: <a href="tel:801-733-4190"> 801-733-4190</a></b>
      </p>
    </ion-card-content>
  </ion-card>
</ion-content>
