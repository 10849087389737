import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserProfilePageRoutingModule } from './profile-routing.module';
import { ImageUploadModule } from 'src/app/components/image-upload/image-upload.module';
import { GravatarModule } from 'ngx-gravatar';
import { WalkwelDirectiveModules } from 'src/app/directives/walkwel-directive-modules.module';
import { ProfilePage } from './profile.page';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    UserProfilePageRoutingModule,
    ImageUploadModule,
    GravatarModule,
    WalkwelDirectiveModules,
    NgxIntlTelInputModule,
  ],
  declarations: [ProfilePage],
})
export class UserProfilePageModule {}
