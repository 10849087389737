import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StorageService } from './storage.service';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private storageService: StorageService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  currentThemePrimaryColor(theme) {
    switch (theme) {
      case 'theme-default':
        return '#8abf47';
      case 'theme-bootstrap':
        return '#0d6efd';
      case 'theme-material':
        return '#1976d2';
      case 'theme-ionic':
        return '#3880ff';
      default:
        return '#8abf47';
    }
  }

  async activeTheme(theme) {
    const existingTheme = this.storageService.get('currentTheme');
    if (existingTheme) {
      this.renderer.removeClass(this.document.body, existingTheme);
    }
    this.storageService.set('currentTheme', theme);
    this.renderer.addClass(this.document.body, theme);

    if (Capacitor.getPlatform() !== 'web') {
      const statusbarColor = this.currentThemePrimaryColor(theme);
      await StatusBar.setBackgroundColor({ color: statusbarColor });
    }
  }
}
