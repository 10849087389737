import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { baseUrl } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class commonRightMenuService {
  private openMenuSubject: Subject<void> = new Subject<void>();
  private closeRightMenuSubject: Subject<void> = new Subject<void>();
  private childResetSubject: Subject<any> = new Subject<void>();
  constructor(
    private menuController: MenuController,
    private http: HttpClient,
    private storagesrv: StorageService
  ) {}
  openRightMenu(data?) {
    this.menuController.enable(true, 'end');
    this.menuController.open('end');
    if (data) {
      this.openMenuSubject.next(data);
    }
  }

  getOpenMenuSubject() {
    return this.openMenuSubject.asObservable();
  }

  setCloseMenuSubject(data) {
    this.closeRightMenuSubject.next(data);
  }

  getCloseMenuSubject() {
    return this.closeRightMenuSubject.asObservable();
  }

  getTriggerChildReset() {
    return this.childResetSubject.asObservable();
  }

  getTriggerChildSetupScannerReset() {
    return this.childResetSubject.asObservable();
  }

  getTriggerChildResetClimate() {
    return this.childResetSubject.asObservable();
  }

  getTriggerChildResetAdd() {
    return this.childResetSubject.asObservable();
  }

  getTriggerChildResetAlert() {
    return this.childResetSubject.asObservable();
  }

  getTriggerNameReset() {
    return this.childResetSubject.asObservable();
  }

  getTriggerSymbolReset() {
    return this.childResetSubject.asObservable();
  }

  getTriggerResetFilter() {
    return this.childResetSubject.asObservable();
  }

  triggerChildReset(name, description) {
    this.childResetSubject.next({ name, description });
  }

  triggerChildSetupScannerReset(name, description) {
    this.childResetSubject.next({ name, description });
  }

  triggerChildClimateReset(value, description) {
    this.childResetSubject.next({ value, description });
  }

  triggerChildResetAdd(description) {
    this.childResetSubject.next({ description });
  }

  triggerChildResetAlert(triggerPrice, triggerDate) {
    this.childResetSubject.next({ triggerPrice, triggerDate });
  }

  triggerNameReset(name) {
    this.childResetSubject.next({ name });
  }

  triggerChildResetFilter(filterObject) {
    this.childResetSubject.next({ filterObject });
  }

  triggerSymbolReset() {
    this.childResetSubject.next({});
  }

  triggerResetSetupScanner() {
    this.childResetSubject.next({});
  }

  unsubscribeSubjects() {
    this.closeRightMenuSubject.unsubscribe();
  }
}
