<ng-container>
  <div class="form-container" [formGroup]="watchListList">
    <div class="page-heading">
      <ion-title>{{ watchListData?.mode === 'edit' ? 'Edit' : 'Create' }} Watchlist</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
    <ng-container>
      <ion-col size-xl="12" size-lg="12" class="p-0">
        <div class="input-block-custom custom-opacity">
          <ion-label position="floating">Watchlist Name<sup>*</sup></ion-label>
          <ion-input class="border-design" formControlName="name" type="text"></ion-input>
        </div>
      </ion-col>
      <div class="input-container">
        <ion-label position="floating">Description</ion-label>
        <textarea
          class="border-design"
          formControlName="description"
          id="message"
          rows="4"
          cols="50"
        ></textarea>
      </div>
      <ion-footer class="ion-no-border">
        <ion-toolbar>
          <ion-button
            type="submit"
            color="primary"
            fill="solid"
            (click)="createWatchList(watchListData.mode)"
            [disabled]="watchListList.invalid"
            style="width: 100%"
          >
            {{ watchListData?.mode === 'edit' ? 'Update' : 'Create' }} WatchList
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </ng-container>
  </div>
</ng-container>
