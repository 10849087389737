import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';

@Component({
  selector: 'app-trade-history',
  templateUrl: './trade-history.component.html',
  styleUrls: ['./trade-history.component.scss'],
})
export class TradeHistoryComponent implements OnInit {
  @Input() tradeHistoryData: any;
  isActiveCurrentView: Boolean = false;
  tradeHistoryList: any = [];
  daysDifference: number;
  symbol: any = '';
  role: any;
  activeToolSlug: any;
  constructor(
    public menuController: MenuController,
    private toolsApi: ToolServiceService,
    private menuService: commonRightMenuService,
    private router: Router,
    public storageServ: StorageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.role = this.storageServ.get('role');
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
  }

  ngOnInit() {
    this.getTradeHistoryList();
    this.menuService.getOpenMenuSubject().subscribe((data) => {
      this.tradeHistoryData = data;
      this.getTradeHistoryList();
    });
  }
  getTradeHistoryList() {
    const symbol = { symbol: this.tradeHistoryData.symbol };
    this.toolsApi.getTradeSymbol(symbol).subscribe((response: any) => {
      this.tradeHistoryList = response.data.tickerList;
      this.symbol = response.data.tickerList[0].symbol;
    });
  }
  calculateDaysDifference(startDate: any, endDate: any, status: any) {
    this.daysDifference = 0;
    const start = new Date(startDate);
    const end = status == closed ? (endDate ? new Date(endDate) : new Date()) : new Date();
    const timeDifference = end.getTime() - start.getTime();

    this.daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return this.daysDifference;
  }
  close() {
    this.menuController.close();
  }
  onClickHistory(data: any) {
    this.toolsApi.setContext('dashboard');
    this.close();
    this.router.navigate([
      `/${this.role}/tools/trade-trackers/${this.tradeHistoryData.toolSlug}/product/${this.tradeHistoryData.productSlug}/ticker/${data.slug}`,
    ]);
  }
}
