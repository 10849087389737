<div *ngIf="!showForm">
  <ion-header class="ion-no-border header-top-custom">
    <ion-toolbar>
      <div class="close-heading-block">
        <div class="page-heading">
          <h2>Help Desk</h2>
        </div>
        <ion-buttons slot="end">
          <ion-button (click)="cancel()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-grid class="icon-grid-block">
    <ion-row *ngFor="let item of helpDeskTopCardContent">
      <ion-col>
        <ion-card>
          <ion-card-header (click)="getHelpDeskList(item.slug)">
            <ion-icon name="{{ item.icon }}"></ion-icon>
            <ion-card-subtitle>{{ item?.title }}</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div class="page-heading">
    <h3 class="help-title-block">We're here to help.</h3>
  </div>
  <ion-searchbar
    search-icon="search-outline"
    debounce="1000"
    id="search"
    class="ion-no-padding"
    placeholder="Search help articles"
    (ionChange)="searchHelpDesk($event)"
  >
  </ion-searchbar>
  <div class="popular-block">
    <div class="page-heading">
      <h5>Popular Articles</h5>
    </div>
    <ul class="content-custom list-content-block" *ngFor="let item of helpDeskContent">
      <li class="background-change" (click)="getHelpDeskList(item.slug)">
        {{ item?.title }}
        <ion-icon class="icon-color" name="arrow-forward-outline"></ion-icon>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="showForm">
  <div class="back-to">
    <a (click)="goBack()"><ion-icon name="chevron-back-outline"></ion-icon>Back</a>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
  <div class="back-close-header-block" *ngIf="helpDeskDetail">
    <ng-container>
      <div class="page-heading">
        <h2>{{ helpDeskDetail?.title }}</h2>
      </div>
      <span [innerHTML]="getSanitizedContent(helpDeskDetail?.content)"></span>
    </ng-container>
  </div>
  <div class="back-close-header-block" *ngIf="!helpDeskDetail">
    <ng-container>
      <div class="page-heading">
        <h2>No content available.</h2>
      </div>
    </ng-container>
  </div>
</div>
