import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SetupScannerComponent } from './setup-scanner.component';
import { SetupScannerDataListingComponent } from './setup-scanner-data-listing/setup-scanner-data-listing.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SetupScannerComponent,
  },
  // {
  //   path: 'setup-scanner/:id',
  //   component: SetupScannerComponent,
  // },
  {
    path: 'product/:productName',
    component: SetupScannerDataListingComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SetupScannerRoutingModule {}
