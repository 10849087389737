import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ClimateService } from 'src/app/services/climate.service';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-climate',
  templateUrl: './climate.component.html',
  styleUrls: ['./climate.component.scss'],
})
export class ClimateComponent implements OnInit {
  public role = 'admin';
  row: any;
  mode: string;
  safeUrl: SafeResourceUrl;
  iframeSrc!: SafeResourceUrl;
  climatesRow: ClimatesRow = {
    value: 0,
    description: '',
  };
  constructor(
    private storageService: StorageService,
    private climateService: ClimateService,
    private menuService: commonRightMenuService,
    private sanitizer: DomSanitizer
  ) {
    this.role = this.storageService.get('role');
  }

  ngOnInit() {
    this.menuService.getTriggerChildResetClimate().subscribe((data) => {
      this.climatesRow.value = data.value ?? this.climatesRow.value;
      this.climatesRow.description = data.description ?? this.climatesRow.description;
      this.getClimateData();
    });
    this.getClimateData();
  }

  getClimateData() {
    this.climateService.getAllClimate().subscribe((response: any) => {
      if (response && response?.data) {
        this.row = response?.data?.climates;
        const value = this.row[0]?.value;
        const dynamicUrl = `https://tradersedgenetwork.com/dynamic_image_creater/guage/combine-image.html?value=${value}`;
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(dynamicUrl);
      }
    });
  }

  getUpdatedClimate(mode) {
    this.mode = mode; // Update mode in the current component
    const climatesProps: any = {
      mode,
      componentType: 'ClimatesUpdateComponent',
    };
    if (mode == 'edit') {
      climatesProps.editData = this.row;
    }
    this.menuService.openRightMenu(climatesProps);
  }
}

export interface ClimatesRow {
  value: number;
  description: string;
}
