import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageService } from './services/storage.service';
import { Title } from '@angular/platform-browser';
import { UpdateApplicationService } from './services/update-application.service';
import { ThemeService } from './services/theme.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { PopupInformationService } from 'src/app/services/popup-information.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    public storageserv: StorageService,
    private titleService: Title,
    private updateService: UpdateApplicationService,
    private themeService: ThemeService,
    private popupInfoService: PopupInformationService
  ) {
    const currentTheme = this.storageserv.get('currentTheme') || 'theme-default';
    if (currentTheme) {
      this.themeService.activeTheme(currentTheme);
    }

    this.initializeApp(currentTheme);
    this.updateService.checkForUpdates();
  }
  ngOnInit(): void {
    //this.popupInfoService.checkEligiblePopup();
  }

  currentThemePrimaryColor(theme) {
    switch (theme) {
      case 'theme-default':
        return '#8abf47';
      case 'theme-bootstrap':
        return '#0d6efd';
      case 'theme-material':
        return '#1976d2';
      case 'theme-ionic':
        return '#3880ff';
      default:
        return '#8abf47';
    }
  }

  initializeApp(currentTheme) {
    this.platform
      .ready()
      .then(async () => {
        if (Capacitor.getPlatform() !== 'web') {
          setTimeout(async () => {
            await SplashScreen.hide();
            const statusbarColor = this.currentThemePrimaryColor(currentTheme);
            await StatusBar.setBackgroundColor({ color: statusbarColor });
          }, 3500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
