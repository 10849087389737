import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { SetupScannerService } from 'src/app/services/setup-scanner.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-setup-scanner-add-update',
  templateUrl: './setup-scanner-add-update.component.html',
  styleUrls: ['./setup-scanner-add-update.component.scss'],
})
export class SetupScannerAddUpdateComponent implements OnInit {
  @Input() setupScannerData: any;
  mode;
  name: string;
  public coverURL: File;
  description: string;
  setupScannerList: FormGroup;
  selectedFile: any;
  uploadedFile: File | null = null;
  acceptFile: string;
  public imagePreview: any;
  label: string;
  placeholder: string;
  role: any;
  public image: any;
  filterObjectValue: {};
  constructor(
    private formBuilder: FormBuilder,
    private toastServ: ToastService,
    public menuController: MenuController,
    private menuService: commonRightMenuService,
    private setupScannerService: SetupScannerService,
    private router: Router
  ) {
    this.setupScannerList = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      // coverURL : [''],
    });
  }

  ngOnInit() {
    if (this.setupScannerData.mode === 'edit') {
      this.setInitData();
    }
    if (this.setupScannerData.mode === 'add') {
      this.setupScannerList.patchValue({
        name: '',
        description: '',
      });
    }
  }

  ngOnChanges(): void {
    if (this.setupScannerData.mode === 'edit') {
      this.setInitData();
    }
    if (this.setupScannerData.mode === 'add') {
      this.setupScannerList.patchValue({
        name: '',
        description: '',
      });
    }
  }

  setInitData() {
    this.setupScannerList.reset();
    if (this.setupScannerData) {
      this.setupScannerList.patchValue({
        name: this.setupScannerData.editData.name,
        description: this.setupScannerData.editData.description,
        // coverURL: this.setupScannerData.editData.coverURL,
      });
    }
  }

  getImage(image) {
    this.image = image.fileToUpload ?? image.imageUrl;
    this.imagePreview = image.imageUrl;
  }

  // onFileChange(event){
  //   const input = event.target as HTMLInputElement; // Type assertion
  //   if (input.files && input.files.length > 0) {
  //     this.uploadedFile = input.files[0]; // Store the first file
  //     const fileName = this.uploadedFile.name; // Extract the file name
  //     this.selectedFile = fileName;
  //   }
  // }

  createSetupScanner(mode) {
    const name = this.setupScannerList.get('name').value;
    const description = this.setupScannerList.get('description').value;
    const setupScannerId = this.setupScannerData?.editData?._id;
    this.setupScannerService.filterObject$.subscribe((filters) => {
      this.filterObjectValue = filters;
    });
    const payload = {
      name: name,
      description: description,
      presets: this.filterObjectValue || {},
    };
    const updatePayload = {
      name: name,
      description: description,
      setupScannerId: setupScannerId,
      presets: this.filterObjectValue || {},
    };
    if (this.setupScannerData.mode == 'add') {
      this.setupScannerService.createSetupScanner(payload).subscribe((res: any) => {
        const lastId = res.data._id;
        if (res) {
          this.menuController.close('payload');
          this.setupScannerList.reset();
          this.cancel('false');
          this.toastServ.presentToast(res?.message, 'success');
          this.menuService.triggerChildSetupScannerReset(name, description);
          this.router.navigate([
            `/user/tools/setup-scanner/${this.setupScannerData.activeToolSlug}/product/${lastId}`,
          ]);
        }
      });
    }
    if (this.setupScannerData.mode == 'edit') {
      this.setupScannerService
        .updateSetupScanner(setupScannerId, updatePayload)
        .subscribe((res: any) => {
          if (res) {
            this.setupScannerList.patchValue({
              name: res?.name || name,
              description: res?.description || description,
              presets: res?.presets || {},
            });

            this.menuController.close('updatePayload');
            this.setupScannerList.reset();
            this.cancel('false');
            this.toastServ.presentToast(res?.message, 'success');
            this.menuService.triggerChildSetupScannerReset(name, description);
          }
        });
    }
  }

  cancel(isNotify = 'true') {
    this.menuController.close();
    if (isNotify === 'true') {
      this.menuService.setCloseMenuSubject({});
    }
  }
}
