import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { GalleryFilesService } from 'src/app/services/gallery-files-service.service';
import { FormGroup } from '@angular/forms';
import { GalleryFile } from 'src/app/models/gallery-file';
import { FileType } from 'src/app/models/enum.file-types';
import { Subject } from 'rxjs';
import { NewUploadsPage } from './new-upload.component';
import { DocumentInfoPage } from './document-info.components';

enum FileGalleryTab {
  List = 'list',
  Trash = 'trash',
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class AppGalleryComponent implements OnInit, AfterViewInit {
  @Input() onFileSelection;
  public currentTab: FileGalleryTab = FileGalleryTab.List;
  private readonly toastSuccess = 'success';
  private readonly toastWarning = 'danger';
  public readonly urlToBlankFile = null;
  public readonly iconNewFolder = GalleryFile.iconNewFolder;
  public readonly iconFolder = GalleryFile.iconFolder;
  public readonly iconHome = GalleryFile.iconHome;
  public files: GalleryFile[];
  public folders: GalleryFile[];
  public selectedFile: GalleryFile;
  public currentFolder: GalleryFile;
  public totalFiles = 0;
  public searchUpdated: Subject<string> = new Subject<string>();
  public searchTerm = '';
  public fileForm: FormGroup;
  public numberOfClicks = 0;
  public dirsArray = [];
  public folderDirs = [];

  public trashFiles = []; // used for trash

  constructor(
    private galleryService: GalleryFilesService,
    public alertController: AlertController,
    private toastCtrl: ToastController,
    public modalController: ModalController
  ) {
    this.searchUpdated.subscribe((query) => {
      if (query !== this.searchTerm) {
        this.searchTerm = query;
        this.folders = [];
        this.files = [];
        this.loadGalleryFiles();
      }
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.navigateBackHome();
  }

  pickTab(type: string) {
    switch (type.toLowerCase()) {
      case 'trash':
        this.currentTab = FileGalleryTab.Trash;
        this.loadTrashFiles();
        break;
      case 'list':
      default:
        this.currentTab = FileGalleryTab.List;
        this.navigateBackHome();
        break;
    }
  }

  performSearch(searchTerm) {
    if (searchTerm !== this.searchTerm) {
      this.searchUpdated.next(searchTerm);
    }
  }

  loadGalleryFiles(
    updateCountMode = false,
    updateCountPreviousParent = false,
    previoursParentId = null
  ) {
    this.galleryService
      .getDirectoryFiles(this.searchTerm, this.currentFolder)
      .subscribe((response: any) => {
        if (response) {
          this.totalFiles = response.meta.total;

          if (updateCountMode && this.currentFolder) {
            this.dirsArray
              .filter((i) => i._id === this.currentFolder._id)
              .map((item) => {
                item.childrenCount = item.childrenCount + 1;
                return item;
              });
          }

          if (updateCountPreviousParent && previoursParentId) {
            this.dirsArray
              .filter((i) => i._id === previoursParentId)
              .map((item) => {
                item.childrenCount = item.childrenCount - 1;
                return item;
              });
          }

          this.folders = [];
          const folders = response.data.filter((file: any) => file.fileType === 'folder');
          this.folderDirs = this.prepareSidebarDirs(folders, this.currentFolder);

          response.data.forEach((f) => {
            this.files.push(Object.assign(new GalleryFile(), f));
          });
        }
      });
  }

  prepareSidebarDirs = (folders, currentFolder) => {
    if (!this.currentFolder) {
      this.dirsArray = [...folders].map((item: any) => {
        item.selected = false;
        return item;
      });
    } else {
      const sampleArray = [...this.dirsArray, ...folders];
      const ids = sampleArray.map((o) => o._id);
      this.dirsArray = sampleArray
        .filter(({ _id }, index) => !ids.includes(_id, index + 1))
        .map((item: any) => {
          return item;
        });
    }

    const markAsSelected = (parent) => {
      if (!parent) {
        return this.dirsArray;
      }

      const crFolderIndex = this.dirsArray.findIndex((item: any) => item._id === parent._id);
      if (crFolderIndex === -1) {
        return this.dirsArray;
      }

      if (this.dirsArray[crFolderIndex]._id === (currentFolder && currentFolder._id)) {
        this.dirsArray[crFolderIndex].selected = !this.dirsArray[crFolderIndex].selected;
      } else {
        this.dirsArray[crFolderIndex].selected = true;
      }

      this.folders.unshift(this.dirsArray[crFolderIndex]);

      if (this.dirsArray[crFolderIndex].parent) {
        const crParentFolderIndex = this.dirsArray.findIndex(
          (item: any) => item._id === this.dirsArray[crFolderIndex].parent
        );
        return markAsSelected(this.dirsArray[crParentFolderIndex]);
      }

      return this.dirsArray;
    };

    this.dirsArray = markAsSelected(currentFolder);

    const files = [...this.dirsArray];
    const map: any = {};
    let file: any;
    const dirs = [];
    let i;

    for (i = 0; i < files.length; i++) {
      map[files[i]._id] = i; // initialize the map
      files[i].children = []; // initialize the children
    }

    for (i = 0; i < files.length; i++) {
      file = files[i];

      if (file.parent && map[file.parent] !== -1) {
        if (files[map[file.parent]]) {
          files[map[file.parent]].children.push(file);
        }
      } else {
        dirs.push(file);
      }
    }

    return dirs;
  };

  dirSelection(folder) {
    this.navigateBackTo(folder);
  }

  async openUploadModal() {
    const modal = await this.modalController.create({
      component: NewUploadsPage,
      cssClass: 'gallery-new-uploads-modal layerd-modal-backdrop',
      componentProps: {
        currentFolder: this.currentFolder,
      },
    });

    modal.onDidDismiss().then(() => {
      this.folders = [];
      this.files = [];
      this.loadGalleryFiles();
    });

    return await modal.present();
  }

  async selectFile(file: GalleryFile) {
    this.searchTerm = '';
    this.selectedFile = Object.assign(new GalleryFile(), file);

    const modal = await this.modalController.create({
      component: DocumentInfoPage,
      cssClass: 'gallery-document-info-modal layerd-modal-backdrop',
      showBackdrop: true,
      componentProps: {
        selectedFile: this.selectedFile,
        insertFile: this.insertFile,
        folderDirs: this.folderDirs,
        moveUpdate: this.moveUpdate,
      },
    });

    modal.onDidDismiss().then((data: any) => {
      if (data && data.data && data.data.shouldLoad) {
        if (data.data.deleteFile && data.data.deleteFile.fileType === FileType.Folder) {
          const deletedFileIDx = this.dirsArray.findIndex(
            (i) => i._id === data.data.deleteFile._id
          );
          if (deletedFileIDx > -1) {
            this.dirsArray.splice(deletedFileIDx, 1);
          }
          this.folders = [];
          this.files = [];
          if (data.data.deleteFile.parent) {
            this.loadGalleryFiles(false, true, data.data.deleteFile.parent);
          } else {
            this.loadGalleryFiles();
          }
          this.selectedFile = null;
        } else {
          this.folders = [];
          this.files = [];
          this.loadGalleryFiles();
          this.selectedFile = null;
        }
      }
    });

    return await modal.present();
  }

  openFolder(folder: GalleryFile) {
    this.searchTerm = '';
    if (folder.fileType !== FileType.Folder) {
      return;
    }

    if (this.currentFolder !== null && this.currentFolder._id === folder._id) {
      this.folders = [];
      this.prepareSidebarDirs([], folder);
      return;
    }

    this.currentFolder = folder;
    this.files = [];
    this.loadGalleryFiles();
  }

  moveUpdate = (folder, previousParentId = null, dirs, file) => {
    this.searchTerm = '';
    this.dirsArray = [...this.dirsArray, ...dirs];

    if (folder) {
      if (folder.fileType !== FileType.Folder) {
        return;
      }

      if (this.currentFolder !== null && this.currentFolder._id === folder._id) {
        return;
      }

      this.files = [];
      this.folders = [];
      this.currentFolder = folder;
      const updateCountFlag = file && file.fileType === FileType.Folder ? true : false;
      this.loadGalleryFiles(updateCountFlag, updateCountFlag, previousParentId);
    } else {
      this.folders = [];
      this.files = [];
      this.currentFolder = null;
      const updateCountFlag = file && file.fileType === FileType.Folder ? true : false;
      this.loadGalleryFiles(false, updateCountFlag, previousParentId);
    }
  };

  navigateBackHome() {
    this.searchTerm = '';
    this.folders = [];
    this.files = [];
    this.currentFolder = null;
    this.loadGalleryFiles();
  }

  navigateBackTo(folder: GalleryFile) {
    this.searchTerm = '';
    if (
      folder.fileType !== FileType.Folder ||
      (this.currentFolder !== null && this.currentFolder._id === folder._id)
    ) {
      this.folders = [];
      this.prepareSidebarDirs([], folder);
      return;
    }

    this.files = [];
    this.currentFolder = folder;
    this.loadGalleryFiles();
  }

  filePickedAndChosen(file: GalleryFile) {
    this.searchTerm = '';
    if (FileType.Folder === (file.fileType as FileType)) {
      this.openFolder(file);
    } else {
      this.selectFile(file);
    }
  }

  insertFile = (file) => {
    this.onFileSelection(file);
  };

  requestGalleryClose() {
    this.modalController.dismiss();
  }

  async createNewFolder() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      message: '<strong>Create New Folder</strong>',
      inputs: [
        {
          name: 'title',
          cssClass: 'solid-input',
          type: 'text',
          placeholder: 'Folder Name',
        },
      ],
      buttons: [
        {
          text: 'Create',
          cssClass: 'solid-button',
          handler: (alertData) => {
            if (alertData.title.trim()) {
              this.galleryService
                .createFolder(alertData.title.trim(), this.currentFolder)
                .subscribe((res: any) => {
                  if (res && res.data) {
                    this.searchTerm = '';
                    this.toastMessage(res.message, this.toastSuccess);
                    this.folders = [];
                    this.files = [];
                    this.loadGalleryFiles(true);
                  }
                });
            } else {
              this.toastMessage('Folder name required', this.toastWarning);
            }
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async toastMessage(message, color) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'top',
      animated: true,
      color,
      cssClass: 'my custom class',
    });
    toast.present();

    return toast.onDidDismiss();
  }

  // trash tab methods
  loadTrashFiles() {
    this.trashFiles = [];
    this.galleryService.getTrashFiles().subscribe((response: any) => {
      if (response && response.data) {
        response.data.forEach((f) => {
          this.trashFiles.push(Object.assign(new GalleryFile(), f));
        });
      }
    });
  }

  restoreDocument(file) {
    this.galleryService.restoreTrashFile(file).subscribe((response: any) => {
      this.toastMessage(response.message, this.toastSuccess);
      this.loadTrashFiles();
    });
  }

  async permanentlyDeleteDocument(file) {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      message: '<strong>Are you sure you want to permanently delete this file?</strong>',
      buttons: [
        {
          text: 'Delete',
          cssClass: 'delete-button',
          handler: () => {
            this.galleryService.deleteFile(file).subscribe((response: any) => {
              this.toastMessage(response.message, this.toastSuccess);
              this.loadTrashFiles();
            });
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
          handler: (blah) => {},
        },
      ],
    });

    await alert.present();
  }

  async clearTrash() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      message: '<strong>Are you sure you want to <b>delete all files</b> from trash?</strong>',
      subHeader: 'If you delete an items, it will be permananetly lost.',
      buttons: [
        {
          text: 'Clear Trash',
          cssClass: 'delete-button',
          handler: () => {
            this.galleryService.clearTrashFiles().subscribe((response: any) => {
              this.toastMessage(response.message, this.toastSuccess);
              this.loadTrashFiles();
            });
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
          handler: (blah) => {},
        },
      ],
    });

    await alert.present();
  }
}
