<div>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Treasure Hunting Climate</ion-title>
    </ion-toolbar>
  </ion-header>
  <div *ngFor="let item of climateData" class="climate-data-modal-view">
    <div class="video-wrapper">
      <iframe
        [src]="iframeSrc"
        width="560"
        height="340"
        frameborder="1"
        allowfullscreen="allowfullscreen"
        data-mce-fragment="1"
      ></iframe>
    </div>
    <span class="climate-description"> {{ item?.description }} </span>
  </div>
</div>
