import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PopupInformationService {
  cache: any = {};
  public data = [];
  slides: any;
  loaded: boolean;
  headers = new Headers();
  token: any;
  constructor(private http: HttpClient, private storageSrv: StorageService) {}

  getEligiblePopupData(userId: string) {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.storageSrv.get('token')}`
    );
    return this.http.post(
      `${baseUrl}/users/popups/check-popup/${userId}`,
      {},
      {
        headers,
      }
    );
  }

  dismissPopup(userId: string, popupId: string) {
    const token = this.storageSrv.get('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(`${baseUrl}/users/popups/dismiss/${userId}/${popupId}`, {}, { headers });
  }

  async checkEligiblePopup() {
    const userId = this.storageSrv.get('userId');
    await this.getEligiblePopupData(userId).subscribe((response: any) => {
      if (response?.data) {
        const data = response?.data ?? [];
        this.storageSrv.set('popupList', JSON.stringify(data));
      }
    });
  }
}
