import { Component, Input, OnInit } from '@angular/core';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
@Component({
  selector: 'app-common-right-menu',
  templateUrl: './common-right-menu.component.html',
  styleUrls: ['./common-right-menu.component.scss'],
})
export class CommonRightMenuComponent implements OnInit {
  public parentData: any = [];
  public menuType: string = '';

  constructor(private menuService: commonRightMenuService) {
    this.menuService.getOpenMenuSubject().subscribe((data) => {
      this.parentData = data;
      this.menuType = this.parentData.componentType;
    });
  }

  ngOnInit() {}
}
