<div>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="body-modal">
    <h2>Close the Trade?</h2>
    <p>All trade legs must be closed before you can finalize the trade.</p>
  </div>
  <div class="footer-modal">
    <ion-button class="close-btn" fill="outline" (click)="close()">Cancel</ion-button>
    <ion-button type="submit" fill="solid" (click)="onSubmit()">Close Trade Legs</ion-button>
  </div>
</div>
