import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseUrl } from 'src/environments/environment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class WatchListService {
  constructor(private http: HttpClient, private storagesrv: StorageService) {}
  private refreshNeeded$ = new Subject<void>();
  getAllWatchList(page, disableHttpLoader = 'true') {
    const qp = new URLSearchParams();
    qp.set('page', page);

    return this.http.get(`${baseUrl}/tools/dashboard/watchList?` + qp.toString(), {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  removeFromWatchList(_id) {
    return this.http.delete(`${baseUrl}/tools/dashboard/watchList/${_id}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  removeFromSymbol(symbols) {
    return this.http.delete(`${baseUrl}/tools/dashboard/watchList/symbol/${symbols}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  getAllSymbolList(_id) {
    return this.http.get(`${baseUrl}/tools/dashboard/watchList/symbol/${_id}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  createWatchList(formData) {
    return this.http.post(`${baseUrl}/tools/dashboard/watchList`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  updateWatchList(formData) {
    return this.http.patch(`${baseUrl}/tools/dashboard/watchList`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  sortSymbolList(data, id) {
    return this.http.patch(`${baseUrl}/tools/dashboard/watchList/${id}`, data, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
}
