import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { baseUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupsService {
  headers = new Headers();
  token: any;

  constructor(private http: HttpClient, private storageSrv: StorageService) {}

  getProductGroupsForUser() {
    return this.http.get(`${baseUrl}/fe/groupProducts/`, {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.storageSrv.get('token')}`)
        .set('cache-request', 'true'),
    });
  }

  getCategoriesAndContents(productSlug, setCache = true) {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.storageSrv.get('token')}`
    );
    if (setCache) {
      headers.set('cache-request', 'true');
    }

    return this.http.get(`${baseUrl}/fe/products/${productSlug}`, { headers });
  }

  getProductsDefaultPoster() {
    return this.http.get(`${baseUrl}/fe/default-poster`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storageSrv.get('token'))
        .set('cache-request', 'true'),
    });
  }
}
