import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from 'src/app/components/date-picker/date-picker.module';
import { WatchListRoutingModule } from './watch-list-routing.module';
import { WatchListAddEditComponent } from './watch-list-add-edit/watch-list-add-edit.component';
import { WatchListDataListingComponent } from './watch-list-data-listing/watch-list-data-listing.component';
import { StockDetailsComponent } from './watch-list-data-listing/stock-details/stock-details.component';
@NgModule({
  declarations: [WatchListAddEditComponent, WatchListDataListingComponent, StockDetailsComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    WatchListRoutingModule,
    IonicModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
  ],
  exports: [WatchListAddEditComponent, WatchListDataListingComponent],
  entryComponents: [WatchListAddEditComponent, WatchListDataListingComponent],
})
export class WatchListModule {}
