import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SetupScannerService {
  private idSource = new BehaviorSubject<number>(0); // Default value
  currentId = this.idSource.asObservable();
  private filterObject = new BehaviorSubject<any>({}); // Replace `any` with a more specific type if you have one
  filterObject$ = this.filterObject.asObservable();
  changeId(id: number) {
    this.idSource.next(id); // Update the id
  }

  updateFilterObject(newFilter: any) {
    this.filterObject.next(newFilter);
  }
  constructor(private http: HttpClient, private storagesrv: StorageService) {}

  createSetupScanner(formData) {
    return this.http.post(`${baseUrl}/setupScanner`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  updateSetupScanner(_id, formData) {
    return this.http.put(`${baseUrl}/setupScanner/${_id}`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  getAllSetupScanner(disableHttpLoader = 'true') {
    return this.http.get(`${baseUrl}/setupScanner`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  getAllStockDetails(_id, symbol = null) {
    const qp = new URLSearchParams();
    if (symbol) {
      qp.set('symbol', symbol.toUpperCase());
    }
    return this.http.get(`${baseUrl}/setupScanner/${_id}` + qp.toString(), {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
}
