import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  MaxLengthValidator,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { RapidApiService } from 'src/app/services/rapid-api.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { TRADE_SYMBOL } from 'src/app/utils/constant';

@Component({
  selector: 'app-ticker-trade-model',
  templateUrl: './ticker-trade-model.component.html',
  styleUrls: ['./ticker-trade-model.component.scss'],
})
export class TickerTradeModelComponent implements OnInit {
  @Input() productSlug;
  @Input() toolSlug;
  name: string;
  isLoading: Boolean = false;
  TRADE_SYMBOL = TRADE_SYMBOL;
  listOfTicker: any[] = []; // The complete list of ticker data from the API
  visibleTickerList: any[] = []; // The list of ticker data to display on the select component
  currentIndex: number = 0; // The index to keep track of the currently displayed records
  showLoadMoreOption: boolean = false; // Whether to show the "Load More" option
  customPopoverOptions: any = {
    showBackdrop: false,
  };
  public datePickerOptions = {
    classes: 'ion-item-no-inner-padding',
    lines: 'none',
    labelPosition: '',
  };
  filteredOptions: string[] = [];
  searchQuery: string = '';
  earchTerm: string = '';
  selectedOption: string = '';
  @ViewChild('popover') popover;
  isOpen = false;
  currentDate = new Date();
  minDate = this.currentDate.toISOString().slice(0, 16); // Format: "YYYY-MM-DDTHH:mm"
  addTickerTradeForm: FormGroup;
  selectedDateTime: string;
  private dateValue: any;
  public role;
  ticker: any;
  searchResult: any[];
  tickerListVisibility: boolean = false;
  selectedTicker: {
    name: string;
    symbol: string;
  } = {
    name: '',
    symbol: '',
  };

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private toolsApi: ToolServiceService,
    private rapidApi: RapidApiService,
    private route: ActivatedRoute,
    private router: Router,
    public storageServ: StorageService
  ) {
    this.role = this.storageServ.get('role');
    this.toolSlug = route.snapshot.params.toolSlug;
  }
  ngOnInit() {
    this.addTickerTradeForm = this.formBuilder.group({
      ticker: [''],
      symbol: [''],
      nextEarningDate: ['0'],
      status: ['open'],
      tradeProductSlug: [this.productSlug],
      contracts: 0,
    });
    // this.tickerList();
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  onSearch(event: any) {
    const searchTerm = event.detail.value;
  }

  onNextEarningDateCheck(event, field) {
    this.addTickerTradeForm.patchValue({ [field]: event });
    this.addTickerTradeForm.get(field).updateValueAndValidity();
  }

  onAddNewTrade() {
    if (this.addTickerTradeForm.valid) {
      this.addTickerTradeForm.value.ticker = this.selectedTicker.name;
      this.addTickerTradeForm.value.symbol = this.selectedTicker.symbol;

      this.toolsApi.addNewTrade(this.addTickerTradeForm.value).subscribe((res: any) => {
        this.router.navigate([
          `/${this.role}/tools/trade-trackers/${this.toolSlug}/product/${this.productSlug}/ticker/${res.tradeData.slug}`,
        ]);
        return this.modalCtrl.dismiss(null, 'cancel');
      });
      // Form is valid, process the form data here
    } else {
      // Form is invalid, show error messages and prevent submission
    }
  }

  phoneNumber: string = '';

  onInputChanged(event: any) {
    // Remove all non-digit characters from the input
    const cleanedValue = event.target.value.replace(/\D/g, '');

    // Limit the input to 10 digits
    this.phoneNumber = cleanedValue.slice(0, 10);
  }

  searchTicker(event: CustomEvent) {
    this.tickerListVisibility = true;
    const trimmedSearchValue = event.detail.value.trim().toLowerCase();
    if (trimmedSearchValue.length > 0) {
      const exactMatches = [];
      const otherMatches = [];
      for (const item of this.listOfTicker) {
        const value = `${item.symbol} - ${item.name}`.toLowerCase();
        if (value.startsWith(trimmedSearchValue)) {
          exactMatches.push(item);
        } else if (value.includes(trimmedSearchValue)) {
          otherMatches.push(item);
        }
      }
      this.searchResult = [...exactMatches, ...otherMatches];
    } else {
      this.tickerListVisibility = false;
      this.selectedTicker = {
        symbol: '',
        name: '',
      };
    }
  }

  hideTickerList() {
    this.tickerListVisibility = false;
    this.selectedTicker = {
      name: '',
      symbol: '',
    };
  }

  onSearchLookupNew(event: CustomEvent) {
    this.tickerListVisibility = true;
    const trimmedSearchValue = event.detail.value.trim().toLowerCase();
    this.onListForSymbol(trimmedSearchValue, 'true');
    this.tickerListVisibility = false;
  }

  onListForSymbol(searchText: string, disableHttpLoader = 'true') {
    this.isLoading = true;
    if (searchText) {
      this.toolsApi.getTickerSymbol(searchText, disableHttpLoader).subscribe((response: any) => {
        if (response && response?.data) {
          this.listOfTicker = response?.data;
          if (this.listOfTicker.length > 0) {
            this.listOfTicker = this.listOfTicker.filter((x) => x.type === 'S');
          }
          this.onSearchLookup(searchText);
          this.isLoading = false;
        }
      });
    } else {
      this.listOfTicker = [];
      this.isLoading = false;
    }
  }
  close() {
    this.modalCtrl.dismiss();
  }
  onSearchLookup(event: string) {
    this.tickerListVisibility = true;
    const trimmedSearchValue = event;
    if (trimmedSearchValue.length > 0) {
      const exactMatches = [];
      const otherMatches = [];
      for (const item of this.listOfTicker) {
        const value = `${item.symbol} - ${item.name}`.toLowerCase();
        if (value.startsWith(trimmedSearchValue)) {
          exactMatches.push(item);
        } else if (value.includes(trimmedSearchValue)) {
          otherMatches.push(item);
        }
      }
      this.searchResult = [...exactMatches, ...otherMatches];
    } else {
      this.tickerListVisibility = false;
      this.selectedTicker = {
        symbol: '',
        name: '',
      };
    }
  }

  addTicker(symbol: string, name: string) {
    this.selectedTicker = { symbol, name };
    this.tickerListVisibility = true;
  }

  tickerList() {
    const staticRecords = this.TRADE_SYMBOL;
    this.toolsApi.getCountryWiseStock('usa').subscribe((res: any) => {
      if (res && res?.data?.length) {
        // Filter records to only include those in the static list
        this.listOfTicker = res.data.filter((record: any) =>
          staticRecords.some((staticRecord) => staticRecord.symbol === record.symbol)
        );
      }
    });
  }
}
