import { Injectable } from '@angular/core';
import { baseUrl } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class HelpDeskService {
  headers = new Headers();
  token: any;
  constructor(private http: HttpClient, private storagesrv: StorageService) { }

  getAllHelpDesk(page, disableHttpLoader = 'true') {
    const qp = new URLSearchParams();
    qp.set('page', page);

    return this.http.get(`${baseUrl}/help-center?` + qp.toString(), {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  createHelpDesk(formData) {
    return this.http.post(`${baseUrl}/help-center/`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  getSelectedHelpDeskDetails(slug) {
    return this.http.get(`${baseUrl}/help-center/${slug}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  editHelpDesk(slug, formData) {
    return this.http.put(`${baseUrl}/help-center/${slug}`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  deleteHelpDeskSetting(slug) {
    return this.http.delete(`${baseUrl}/help-center/${slug}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  getHelpDeskBySlug(slug?, keyword?, isTopCard?) {
    const qp = new URLSearchParams();
    if (keyword) {
      qp.set('keyword', keyword);
    }

    if (isTopCard) {
      qp.set('isTopCard', isTopCard);
    }
    let url = `${baseUrl}/help-center?` + qp.toString();
    if (slug) {
      url += `/${slug}`;
    }
    return this.http.get(url, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
}
