import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpLoaderService } from '../services/http-loader.service';
import { CacheService } from './cache-interceptor.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private loaderService: HttpLoaderService,
    public alertController: AlertController,
    private cache: CacheService
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !request.headers.get('disableHttpLoader') ||
      request.headers.get('disableHttpLoader') !== 'true'
    ) {
      if (request.method === 'GET') {
        const cachedResponse = this.cache.getFromCache(request);
        if (!cachedResponse) {
          this.requests.push(request);
          this.loaderService.isLoading.next(true);
        }
      } else {
        this.requests.push(request);
        this.loaderService.isLoading.next(true);
      }
    }

    return new Observable((observer) => {
      const subscription = next.handle(request).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(request);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(request);
          observer.error(err);
        },
        () => {
          this.removeRequest(request);
          observer.complete();
        }
      );

      // remove request from queue when cancelled
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }
}
