import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TradeTrackersProductsComponent } from './products/products.component';
import { ProductTickersComponent } from './product-tickers/product-tickers.component';
import { TickerDetailsComponent } from './ticker-details/ticker-details.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: TradeTrackersProductsComponent,
  },
  {
    path: 'product/:productSlug',
    component: ProductTickersComponent,
  },
  {
    path: 'product/:productSlug/ticker/:tickerSlug',
    component: TickerDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TradeTrackersRoutingModule {}
