import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public toastController: ToastController, public alertCtrl: AlertController) {}

  async presentToast(message, color, duration = 2000, header = '') {
    const options: any = {
      message,
      duration: 2000,
      color,
      position: 'bottom',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => {},
        },
      ],
    };

    if (duration) {
      options.duration = duration;
    }

    if (header) {
      options.header = header;
    }

    const toast = await this.toastController.create(options);
    toast.present();
  }

  async confirmationAlert(message: string, buttonText: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>((resolve) => {
      resolveFunction = resolve;
    });
    const alert = await this.alertCtrl.create({
      message: `<strong>${message}</strong>`,
      cssClass: 'custom-alert-popup',
      buttons: [
        {
          text: `${buttonText}`,
          cssClass: 'delete-button',
          handler: () => resolveFunction(true),
        },
        {
          text: 'Cancel',
          cssClass: 'ghost-button',
          handler: () => resolveFunction(false),
        },
      ],
    });
    await alert.present();
    return promise;
  }
}
