import { FileType } from './enum.file-types';
import { uploadsS3BucketBasePath } from 'src/environments/environment';

export class GalleryFile {
  _id: string;
  parent: string;
  fileName: string;
  fileUrl: string;
  filePreviewUrl: string;
  fileType: FileType;
  title: string;
  caption: string;
  flag: string;
  createdAt: Date;
  updatedAt: Date;

  private _width: number;
  private _height: number;

  public get width(): number {
    return this._width ?? 240;
  }
  public set width(value: number) {
    this._width = value;
  }

  public get height(): number {
    return this._height ?? 48;
  }
  public set height(value: number) {
    this._height = value;
  }

  public get src(): string {
    return this.filePreviewUrl ? this.filePreviewUrl : this.fileUrl;
  }

  public isFolder(): boolean {
    return this.fileType === FileType.Folder;
  }

  public isDocument(): boolean {
    return this.fileType === FileType.Document;
  }

  public get filePath(): string {
    return this.fileUrl;
  }

  public static get iconNewFolder(): string {
    return `${uploadsS3BucketBasePath}/file-types/file-type-new-folder.svg`;
  }

  public static get iconFolder(): string {
    return `${uploadsS3BucketBasePath}/file-types/file-type-folder.svg`;
  }

  public static get iconHome(): string {
    return `${uploadsS3BucketBasePath}/file-types/icon-home.svg`;
  }
}
