import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RapidApiService {
  constructor(private http: HttpClient) {}

  /*  getStockTicker(usa) {
     return this.http.get('https://stock-api6.p.rapidapi.com/api/v1/stocks/country/' + usa, {
       headers: new HttpHeaders({
         'X-RapidAPI-Key': '9bb98629eemsh453ea97123cabacp11a1dejsn9d53c78ac7a7',
         'X-RapidAPI-Host': 'stock-api6.p.rapidapi.com',
       }),
     });
   }
   searchStock(data): Observable<any> {
     const apiUrl = 'https://quotient.p.rapidapi.com/search/symbol';
     const headers = {
       'X-RapidAPI-Key': 'eefbce24b6msh8050af1e5b82737p1cca54jsn69ed4bd4e562',
       'X-RapidAPI-Host': 'quotient.p.rapidapi.com',
     };
     const params = {
       query: data,
       categories: 'EQT,ETF',
       regions: 'US,UK,EU',
     };
     return this.http.get(apiUrl, { headers, params });
   } */

  getExpirationDate(data, disableHttpLoader = 'true'): Observable<any> {
    const apiUrl = 'https://quotient.p.rapidapi.com/options/prices';

    let headers = new HttpHeaders({
      'X-RapidAPI-Key': '9ed35abbbcmshbea487b4cc3c505p1b6837jsn51d0896b9ab0',
      'X-RapidAPI-Host': 'quotient.p.rapidapi.com',
    });
    if (disableHttpLoader) {
      headers = headers.set('disableHttpLoader', disableHttpLoader);
    }

    const params = {
      ...data,
    };
    return this.http.get(apiUrl, { headers, params });
  }

  // listOfTicker(): Observable<any> {
  //   // const url =
  //   //   'https://api.polygon.io/v3/reference/tickers?active=true&limit=1000&apiKey=hN6fXUHzNQlpWy_UtDkVsaQEiPhIQziw';
  //   // return this.http.get(url);
  //   const apiUrl = 'https://twelve-data1.p.rapidapi.com/stocks';
  //   const headers = {
  //     'X-RapidAPI-Key': '9ed35abbbcmshbea487b4cc3c505p1b6837jsn51d0896b9ab0',
  //     'X-RapidAPI-Host': 'twelve-data1.p.rapidapi.com'
  //   };
  //   const params = {
  //     country: 'us',
  //     format: 'json',
  //   };
  //   return this.http.get(apiUrl, { headers, params });
  // }

  // getStockPrice(symbols: string[]): Observable<any> {
  //   const apiUrl = 'https://mboum.com/api/v1/qu/quote/';
  //   const headers = {
  //     'X-Mboum-Secret': 'LmGSjwkIOXC63CZw9X8ldqxh2Nosgvcra71AiW40qjUABPZQbgnHutpuN9X6',
  //     'Access-Control-Allow-Origin': '*'
  //   };
  //   const params = {
  //     symbol: symbols.toString(),
  //     apikey: 'LmGSjwkIOXC63CZw9X8ldqxh2Nosgvcra71AiW40qjUABPZQbgnHutpuN9X6',
  //   };
  //   return this.http.get(apiUrl, { headers, params });
  // }

  /*  getStockPrice(symbols: string[]): Observable<any> {
     const apiKey = 'LmGSjwkIOXC63CZw9X8ldqxh2Nosgvcra71AiW40qjUABPZQbgnHutpuN9X6';
     const symbolString = symbols.join(',');
 
     return new Observable((observer) => {
       axios.get(`${mbomBaseUrl}/qu/quote/?symbol=${symbolString}&apikey=${apiKey}`)
         .then(response => {
           observer.next(response.data);
           observer.complete();
         })
         .catch(error => {
           observer.error('Failed to fetch quotes');
         });
     }).pipe(catchError((error) => throwError(error)));
   }
 
 
 
   getStockDeatils(symbol: string): Observable<any> {
     const apiUrl = 'https://mboum-finance.p.rapidapi.com/v1/markets/stock/modules';
     const headers = {
       'X-RapidAPI-Key': '9ed35abbbcmshbea487b4cc3c505p1b6837jsn51d0896b9ab0',
       'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com'
     };
     let params: any = {
       symbol: symbol,
       module: 'asset-profile',
     };
     return this.http.get(apiUrl, { headers, params });
   };
 
   getStockLogo(symbol: string): Observable<any> {
     const apiUrl = 'https://twelve-data1.p.rapidapi.com/logo';
     const headers = {
       'X-RapidAPI-Key': '9ed35abbbcmshbea487b4cc3c505p1b6837jsn51d0896b9ab0',
       'X-RapidAPI-Host': 'twelve-data1.p.rapidapi.com'
     };
     let params: any = {
       symbol: symbol
     };
     return this.http.get(apiUrl, { headers, params });
   };
 
   getStockEarnings(symbol: string): Observable<any> {
     const apiUrl = 'https://mboum-finance.p.rapidapi.com/v1/markets/stock/modules';
     const headers = {
       'X-RapidAPI-Key': '9ed35abbbcmshbea487b4cc3c505p1b6837jsn51d0896b9ab0',
       'X-RapidAPI-Host': 'mboum-finance.p.rapidapi.com'
     };
     let params: any = {
       symbol: symbol,
       module: 'earnings'
     };
     return this.http.get(apiUrl, { headers, params });
   } */
}
