import { Injectable } from '@angular/core';
import { baseUrl } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  headers = new Headers();
  public loadProfile = new BehaviorSubject(false);

  constructor(private http: HttpClient, private storagesrv: StorageService) {}

  public tickForLoadProfile(tick) {
    this.loadProfile.next(tick);
  }

  public onPingLoadProfile(): Observable<any> {
    return this.loadProfile.asObservable();
  }

  fetchProfile() {
    return this.http.get(`${baseUrl}/profile/me`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  updateProfile(userData, disableHttpLoader: string = 'false') {
    return this.http.post(`${baseUrl}/profile/update-profile`, userData, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  accountSize(accountData) {
    return this.http.post(`${baseUrl}/profile/account-size`, accountData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  changePassword(passwords) {
    return this.http.post(`${baseUrl}/profile/change-password`, passwords, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  updateNotificationSetting(settings) {
    return this.http.put(`${baseUrl}/profile/notification-setting/update`, settings, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  infusionsoftAuthorizationCallback(code) {
    return this.http.get(`${baseUrl}/profile/infusionsoft/authorization/callback?code=${code}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  acceptChatAggrement() {
    return this.http.post(`${baseUrl}/profile/accept-agreement`, null, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
      observe: 'response',
    });
  }

  updateHubInfo(value) {
    return this.http.post(`${baseUrl}/profile/update-hub-info`, value, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
}
