import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { GalleryFilesService } from 'src/app/services/gallery-files-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MoveDirectoryPage } from './move-dir-selection.component';

@Component({
  selector: 'app-gallery-document-info',
  template: `
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button (click)="dismissModal(false)">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Document</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-row>
        <ion-col size-xl="12" size-lg="12">
          <div id="selected-file" *ngIf="selectedFile">
            <div id="file-data">
              <figure>
                <img
                  (load)="onImageLoaded($event.target)"
                  #selectedImage
                  [src]="selectedFile.src"
                  [alt]="selectedFile.title"
                  [title]="selectedFile.title"
                />
              </figure>
              <form [formGroup]="fileForm" (ngSubmit)="updateFile()">
                <ion-item lines="inset">
                  <ion-label position="floating">Title</ion-label>
                  <ion-input
                    inputmode="text"
                    formControlName="title"
                    ngDefaultControl
                    [(ngModel)]="selectedFile.title"
                    name="title"
                  ></ion-input>
                </ion-item>
                <div class="error" *ngIf="formControls.title.errors?.required">
                  Title is required.
                </div>
                <ion-item lines="inset">
                  <ion-label position="floating">Caption</ion-label>
                  <ion-input
                    inputmode="text"
                    formControlName="caption"
                    ngDefaultControl
                    [(ngModel)]="selectedFile.caption"
                    name="caption"
                  ></ion-input>
                </ion-item>
                <div class="error" *ngIf="formControls.caption.errors?.minlength">
                  Caption should be at least 3 characters long.
                </div>
                <ion-item lines="inset">
                  <ion-label position="floating">Width</ion-label>
                  <ion-input
                    inputmode="number"
                    formControlName="width"
                    ngDefaultControl
                    [(ngModel)]="selectedFile.width"
                    name="width"
                  ></ion-input>
                </ion-item>
                <ion-item lines="inset">
                  <ion-label position="floating">Height</ion-label>
                  <ion-input
                    inputmode="number"
                    formControlName="height"
                    ngDefaultControl
                    [(ngModel)]="selectedFile.height"
                    name="height"
                  ></ion-input>
                </ion-item>
              </form>
            </div>
            <a
              *ngIf="selectedFile.fileUrl"
              id="link-preview-container"
              href="{{ selectedFile.fileUrl }}"
              target="_blank"
              >{{ selectedFile.fileUrl }}</a
            >
            <ion-item lines="none">
              <div id="file-actions">
                <ion-button
                  size="small"
                  *ngIf="selectedFile.fileUrl"
                  (click)="copyUrl(selectedFile.fileUrl)"
                  class="btn-common"
                  color="warning"
                >
                  <span slot="start">Copy</span>
                </ion-button>
                <ion-button
                  size="small"
                  class="btn-common"
                  color="secondary"
                  *ngIf="selectedFile.fileUrl"
                  [href]="selectedFile.fileUrl"
                  target="_blank"
                >
                  <span slot="start">Open</span>
                </ion-button>
                <ion-button
                  size="small"
                  class="btn-common"
                  color="success"
                  [disabled]="!fileForm.valid"
                  (click)="pickFile()"
                  *ngIf="!selectedFile.isFolder()"
                >
                  <span slot="start">Insert</span>
                </ion-button>
                <ion-button size="small" class="btn-common" color="primary" (click)="moveFile()">
                  Move
                </ion-button>
                <ion-button
                  size="small"
                  class="btn-common"
                  color="warning"
                  [disabled]="!fileForm.valid"
                  (click)="updateFile()"
                >
                  Update
                </ion-button>
                <ion-button
                  size="small"
                  class="btn-common"
                  color="danger"
                  (click)="requestFileDeletion()"
                >
                  Delete
                </ion-button>
              </div>
            </ion-item>
            <ion-item *ngIf="infoMessage" lines="none">
              <p
                class="info-message"
                [ngStyle]="{ color: copiedSuccessfully == true ? 'rgb(92 158 7)' : '#eb445a' }"
              >
                {{ infoMessage }}
              </p>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
    </ion-content>
  `,
  styleUrls: ['./gallery.component.scss'],
})
export class DocumentInfoPage implements OnInit {
  @Input() selectedFile;
  @Input() insertFile;
  @Input() folderDirs;
  @Input() moveUpdate;

  public fileForm: FormGroup;
  private readonly toastSuccess = 'success';
  public infoMessage = '';
  public copiedSuccessfully = true;

  constructor(
    private galleryService: GalleryFilesService,
    private toastCtrl: ToastController,
    public modalController: ModalController,
    public alertController: AlertController,
    private fb: FormBuilder
  ) {}

  get formControls() {
    return this.fileForm.controls;
  }

  ngOnInit(): void {
    this.resetFileUpdateForm();
  }

  pickFile() {
    this.insertFile(this.selectedFile);
    this.dismissModal(false);
  }

  copyUrl(url) {
    if (!navigator.clipboard) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = url;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.copiedSuccessfully = true;
      this.infoMessage = 'URL successfully copied.';
      setTimeout(() => {
        this.infoMessage = '';
      }, 1000);
    } else {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.copiedSuccessfully = true;
          this.infoMessage = 'URL successfully copied.';
          setTimeout(() => {
            this.infoMessage = '';
          }, 1000);
        })
        .catch(function () {
          this.copiedSuccessfully = false;
          this.infoMessage = 'Sorry, some issue with copy function, try manually!';
          setTimeout(() => {
            this.infoMessage = '';
          }, 1000);
        });
    }
  }

  dismissModal(shouldLoad, file = null) {
    this.modalController.dismiss({ shouldLoad, deleteFile: file });
  }

  deleteFile() {
    this.galleryService.trashDocument(this.selectedFile).subscribe(() => {
      this.dismissModal(true, this.selectedFile);
    });
  }

  updateFile() {
    this.galleryService.updateFileData(this.selectedFile).subscribe((response: any) => {
      this.toastMessage('File Update', this.toastSuccess);
      this.dismissModal(true);
    });
  }

  async moveFile() {
    const modal = await this.modalController.create({
      component: MoveDirectoryPage,
      cssClass: 'gallery-document-move-directory-modal layerd-modal-backdrop',
      showBackdrop: true,
      componentProps: {
        selectedFile: this.selectedFile,
        folderDirs: this.folderDirs,
      },
    });

    modal.onDidDismiss().then((data: any) => {
      if (data && data.data && data.data.file) {
        const { file, current = null, previousParent, dirs = [] } = data.data;
        this.galleryService.updateFileData(file).subscribe((response: any) => {
          this.toastMessage('The document has been moved successfully.', this.toastSuccess);
          this.moveUpdate(current, previousParent, dirs, file);
        });
      }
    });

    return await modal.present();
  }

  private resetFileUpdateForm() {
    this.fileForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(4)]],
      caption: [''],
      width: [this.selectedFile?.width, [Validators.required, Validators.min(4)]],
      height: [this.selectedFile?.height, [Validators.required, Validators.min(4)]],
    });
  }

  onImageLoaded(image: HTMLImageElement) {
    this.selectedFile.width = image.naturalWidth;
    this.selectedFile.height = image.naturalHeight;

    this.fileForm.setValue({
      title: this.selectedFile.title || '',
      caption: this.selectedFile.caption || '',
      width: image.naturalWidth,
      height: image.naturalHeight,
    });
  }

  async requestFileDeletion() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      message: '<strong>Are you sure you want to delete this file?</strong>',
      buttons: [
        {
          text: 'Delete',
          cssClass: 'delete-button',
          handler: () => {
            this.deleteFile();
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
          handler: (blah) => {},
        },
      ],
    });

    await alert.present();
  }

  async toastMessage(message, color) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'top',
      animated: true,
      color,
      cssClass: 'my custom class',
    });
    toast.present();

    return toast.onDidDismiss();
  }
}
