import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MenuController } from '@ionic/angular';
import { HelpDeskService } from 'src/app/services/help-desk.service';

@Component({
  selector: 'app-help-section',
  templateUrl: './help-section.component.html',
  styleUrls: ['./help-section.component.scss'],
})
export class HelpSectionComponent implements OnInit {
  @Input() helpSectionData: any;
  showForm = false;
  helpDeskTopCardContent = [];
  helpDeskContent = [];
  helpDeskDetail = null;
  private sanitizedContentCache = new Map<string, SafeHtml>();

  selectedSectionData: any;
  slug: any;
  constructor(
    public menuController: MenuController,
    public helpService: HelpDeskService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getHelpDeskList();
  }

  goBack() {
    this.showForm = false;
    this.helpDeskTopCardContent = [];
    this.helpDeskContent = [];
    this.helpDeskDetail = null;
    this.getHelpDeskList();
  }

  getSanitizedContent(content: string): SafeHtml {
    // Check if the content is already sanitized and cached
    if (this.sanitizedContentCache.has(content)) {
      return this.sanitizedContentCache.get(content)!;
    }

    // Sanitize the content and store it in the cache
    const sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(content);
    this.sanitizedContentCache.set(content, sanitizedContent);
    return sanitizedContent;
  }

  getHelpDeskList(slug?, keyword?, isTopCard?) {
    this.helpService.getHelpDeskBySlug(slug, keyword, isTopCard).subscribe((res: any) => {
      if (!!res.data?.length) {
        this.helpDeskTopCardContent = [];
        this.helpDeskContent = [];
        if (!slug) {
          res.data.forEach((element) => {
            if (element?.isTopCard) {
              this.helpDeskTopCardContent.push(element);
            } else {
              this.helpDeskContent.push(element);
            }
          });
        } else {
          this.helpDeskDetail = res.data.filter((data) => data.slug === slug)?.[0];
          this.showForm = !this.showForm;
        }
      }
    });
  }

  cancel() {
    if (this.showForm) {
      this.getHelpDeskList();
      this.showForm = !this.showForm;
    } else this.menuController.close();
  }

  searchHelpDesk(event) {
    const searchValue = event?.target?.value;
    if (searchValue && searchValue.length >= 3) this.helpDeskDetail = null;
    this.helpDeskTopCardContent = [];
    this.helpDeskContent = [];

    this.getHelpDeskList(null, searchValue, false);
  }
}
