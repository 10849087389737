import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GravatarModule } from 'ngx-gravatar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxTextDiffModule } from 'ngx-text-diff';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxTinymceModule } from 'ngx-tinymce';

import { environment } from '../environments/environment';
import { HttpErrorInterceptor } from './interceptors/httperrorinterceptor.service';
import { HttpLoaderService } from './services/http-loader.service';
import { CacheInterceptor } from './interceptors/cache-interceptor.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { PipesModule } from './pipes/pipes.module';
import { WalkwelDirectiveModules } from './directives/walkwel-directive-modules.module';

import { CommonLoaderComponent } from './components/common-loader/common-loader.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { UserLayoutComponent } from './layouts/user/user-layout.component';
import { ModeratorLayoutComponent } from './layouts/moderator/moderator-layout.component';
import { StaffLayoutComponent } from './layouts/staff/staff-layout.component';

import { LoaderService } from './services/loader.service';
import { CustomUrlSerializer } from './utils/custom-url-serializer/custom-url-serializer';

import { CommonHeaderModule } from './layouts/components/common-header/common-header.module';
import { InAppNotificationModule } from './components/in-app-notification/in-app-notification.module';
import { NgChartsModule } from 'ng2-charts';
import { CommonRightMenuModule } from './components/common-right-menu/common-right-menu.module';
import { HubInformationModule } from './layouts/hub-information/hub-information.module';
import { HelpDeskComponent } from './scenes/administrator-common/help-desk/help-desk/help-desk.component';

import { Crop } from '@ionic-native/crop/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { PopupInformationModule } from './layouts/popup-information/popup-information.module';
import { PopupInformationComponent } from './layouts/popup-information/popup-information.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: 'var(--ion-color-light)',
  fgsPosition: 'center-center',
  fgsSize: 120,
  fgsType: 'ball-scale-multiple',
  gap: 24,
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: 'var(--ion-color-light)',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  maxTime: -1,
  minTime: 100,
};

@NgModule({
  declarations: [
    AppComponent,
    CommonLoaderComponent,
    AdminLayoutComponent,
    UserLayoutComponent,
    ModeratorLayoutComponent,
    StaffLayoutComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      animated: false,
      mode: 'md',
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    ReactiveFormsModule,
    HttpClientModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    GravatarModule,
    PipesModule,
    NgxTextDiffModule,
    NgChartsModule,
    InAppNotificationModule,
    CommonHeaderModule,
    CommonRightMenuModule,
    HubInformationModule,
    PopupInformationModule,
    FormsModule,
    WalkwelDirectiveModules,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot(),
    NgxTinymceModule.forRoot({
      baseURL: 'assets/tinymce/',
    }),
  ],
  providers: [
    Window,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    PopupInformationComponent,
    HttpLoaderService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    Title,
    Crop,
    Camera,
    ImagePicker,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
