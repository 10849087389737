import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { constant } from 'lodash';
import { Page } from 'src/app/models/page';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { SetupScannerService } from 'src/app/services/setup-scanner.service';
import { ToastService } from 'src/app/services/toast.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import {
  FILTERSETUPDATA,
  CHANGE180DAYS,
  CHANGE1DAY,
  CHANGE1YTD,
  CHANGE30DAYS,
  CHANGE365DAYS,
  CHANGE60DAYS,
  CHANGE90DAYS,
  CHANGELAST7DAYS,
  COUNTRY,
  DAVGCHG200,
  DAVGCHG50,
  EARNINGS,
  FILTERLIST,
  INDUSTRY,
  MARKET_CAP,
  PRICE,
  SECTOR,
  VOLUME,
  WEEKLYOPTION,
  WHIGHCHG52,
} from 'src/app/utils/constant';

@Component({
  selector: 'app-filter-setup-scanner-list',
  templateUrl: './filter-setup-scanner-list.component.html',
  styleUrls: ['./filter-setup-scanner-list.component.scss'],
})
export class FilterSetupScannerListComponent implements OnInit {
  @Input() setupScannerFilterData: any;
  showForm = false;
  isLoading = true;
  selectedMarketCap: string;
  selectedPrice: string;
  selectedPriceData: string;
  selectedMinValue: string = '5';
  selectedMaxValue: string = '10';
  selectedMinValue1D: string = '5';
  selectedMaxValue1D: string = '10';
  selectedChange1Day: string;
  selectedChangeLast7Days: string;
  selectedChange30Days: string;
  selectedChange60Days: string;
  selectedChange90Days: string;
  selectedChange180Days: string;
  selectedChange1YTD: string;
  selectedChange365Days: string;
  selectedVolume: string;
  selectedWeeklyOption: string;
  selectedEarnings: string;
  selectedCountry: string;
  selectedIndustry: string;
  selectedSector: string;
  selected50DAvgChg: string;
  selected200DAvgChg: string;
  selected52WHighChg: string;
  selectedVolumeData: string;
  selectedWeeklyOptionData: string;
  selectedEarningsData: string;
  selectedMarketCapData: string;
  selectedChangeData1Day: string;
  selectedChangeDataLast7Days: string;
  selectedChangeData30Days: string;
  selectedChangeData60Days: string;
  selectedChangeData90Days: string;
  selectedChangeData180Days: string;
  selectedChangeData1YTD: string;
  selectedChangeData365Days: string;
  selectedCountryData: string;
  selectedIndustryData: string;
  selectedSectorData: string;
  selected50DAvgChgData: string;
  selected200DAvgChgData: string;
  selected52WHighChgData: string;
  filterDataObject = {};
  showFilterData: any;
  MARKET_CAP = MARKET_CAP;
  PRICE = PRICE;
  CHANGE1DAY = CHANGE1DAY;
  CHANGELAST7DAYS = CHANGELAST7DAYS;
  CHANGE30DAYS = CHANGE30DAYS;
  CHANGE60DAYS = CHANGE60DAYS;
  CHANGE90DAYS = CHANGE90DAYS;
  CHANGE180DAYS = CHANGE180DAYS;
  CHANGE1YTD = CHANGE1YTD;
  CHANGE365DAYS = CHANGE365DAYS;
  VOLUME = VOLUME;
  WEEKLYOPTION = WEEKLYOPTION;
  EARNINGS = EARNINGS;
  FILTERLIST = FILTERLIST;
  COUNTRY = COUNTRY;
  INDUSTRY = INDUSTRY;
  SECTOR = SECTOR;
  DAVGCHG50 = DAVGCHG50;
  DAVGCHG200 = DAVGCHG200;
  WHIGHCHG52 = WHIGHCHG52;
  FILTERSETUPDATA = FILTERSETUPDATA;
  selectedFilter: string = '';
  searchText: any;
  priceChangeDateRange: string = '';
  public page = new Page();
  rows: any;
  constructor(
    public menuController: MenuController,
    private tradeToolsService: TradeToolsService,
    private toastServ: ToastService,
    private menuService: commonRightMenuService,
    private setupScannerService: SetupScannerService
  ) {}

  ngOnInit() {
    this.calculateDateRange();
    this.setupScannerService.filterObject$.subscribe((data) => {
      if (data) {
        this.selectedPriceData = data.price;
        var totalWords = this.selectedPriceData;
        var firstWord = totalWords.replace(/ .*/, '');
        this.selectedPrice = firstWord;
        var words = totalWords.split(' ');
        var totalNumbers = words[1];
        var numbers = totalNumbers.split('-');
        this.selectedMinValue = numbers[0];
        this.selectedMaxValue = numbers[1];

        this.selectedVolumeData = data.volume;
        this.selectedVolume = this.selectedVolumeData;
        this.selectedEarningsData = data.earnings;
        this.selectedEarnings = this.selectedEarningsData;
        this.selectedChangeData1Day = data.change1Day;
        // this.selectedChange1Day = this.selectedChangeData1Day;

        var totalWords = this.selectedChangeData1Day;
        var firstWord = totalWords.replace(/ .*/, '');
        this.selectedChange1Day = firstWord;
        var words = totalWords.split(' ');
        var totalNumbers = words[1];
        var numbers = totalNumbers.split('-');
        this.selectedMinValue1D = numbers[0];
        this.selectedMaxValue1D = numbers[1];

        this.selectedChangeLast7Days = this.selectedChangeDataLast7Days;
        this.selectedChange30Days = this.selectedChangeData30Days;
        this.selectedChange60Days = this.selectedChangeData60Days;
        this.selectedChange90Days = this.selectedChangeData90Days;
        this.selectedChange180Days = this.selectedChangeData180Days;
        this.selectedChange1YTD = this.selectedChangeData1YTD;
        this.selectedChange365Days = this.selectedChange365Days;
        this.selectedWeeklyOptionData =
          data.isWeeklyStock != null ? (data.isWeeklyStock === true ? 'Yes' : 'No') : null;
        this.selectedWeeklyOption = this.selectedWeeklyOptionData;
        this.selectedMarketCapData = data.marketCap;
        this.selectedMarketCap = this.selectedMarketCapData;
      }
    });
  }

  calculateDateRange() {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1); // Start of the current year

    const startDateFormatted = this.formatDateWithOrdinal(startDate);
    const currentDateFormatted = this.formatDateWithOrdinal(currentDate);

    this.priceChangeDateRange = `${startDateFormatted} to ${currentDateFormatted}`;
  }

  formatDateWithOrdinal(date: Date): string {
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });

    const ordinal = this.getOrdinalSuffix(day);
    return `${month} ${day}${ordinal}`;
  }

  getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th'; // 11th to 19th
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  getFilterWiseShow(filter: string) {
    this.selectedFilter = filter;
    this.showForm = !this.showForm;
  }

  goBack() {
    this.showForm = false;
  }

  cancel(isNotify = 'true') {
    this.showForm = false;
    this.menuController.close();
    if (isNotify === 'true') {
      this.menuService.setCloseMenuSubject({});
    }
  }

  isAnyFilterApplied() {
    return (
      this.selectedPrice ||
      this.selectedVolume ||
      this.selectedWeeklyOption ||
      this.selectedEarnings ||
      this.selectedMarketCap ||
      this.selectedChange1Day ||
      this.selectedChangeLast7Days ||
      this.selectedChange30Days ||
      this.selectedChange60Days ||
      this.selectedChange90Days ||
      this.selectedChange180Days ||
      this.selectedChange1YTD ||
      this.selectedChange365Days ||
      this.selectedCountry ||
      this.selectedIndustry ||
      this.selectedSector ||
      this.selected50DAvgChg ||
      this.selected200DAvgChg ||
      this.selected52WHighChg
    );
  }

  clearFilters() {
    let filterObject = { page: 1, pageSize: 50 };
    filterObject['price'] = null;
    this.selectedPrice = null;
    this.selectedPriceData = null;
    filterObject['volume'] = null;
    this.selectedVolume = null;
    this.selectedVolumeData = null;
    filterObject['isWeeklyStock'] = null;
    this.selectedWeeklyOption = null;
    this.selectedWeeklyOptionData = null;
    filterObject['earnings'] = null;
    this.selectedEarnings = null;
    this.selectedEarningsData = null;
    filterObject['marketcap'] = null;
    this.selectedMarketCap = null;
    this.selectedMarketCapData = null;
    filterObject['change1Day'] = null;
    this.selectedChange1Day = null;
    this.selectedChangeData1Day = null;
    filterObject['change7Days'] = null;
    this.selectedChangeLast7Days = null;
    this.selectedChangeDataLast7Days = null;
    filterObject['change30Days'] = null;
    this.selectedChange30Days = null;
    this.selectedChangeData30Days = null;
    filterObject['change60Days'] = null;
    this.selectedChange60Days = null;
    this.selectedChangeData60Days = null;
    filterObject['change90Days'] = null;
    this.selectedChange90Days = null;
    this.selectedChangeData90Days = null;
    filterObject['change180Days'] = null;
    this.selectedChange180Days = null;
    this.selectedChangeData180Days = null;
    filterObject['change1YTD'] = null;
    this.selectedChange1YTD = null;
    this.selectedChangeData1YTD = null;
    filterObject['change365Days'] = null;
    this.selectedChange365Days = null;
    this.selectedChangeData365Days = null;
    filterObject['country'] = null;
    this.selectedCountry = null;
    this.selectedCountryData = null;
    filterObject['industry'] = null;
    this.selectedIndustry = null;
    this.selectedIndustryData = null;
    filterObject['sector'] = null;
    this.selectedSector = null;
    this.selectedSectorData = null;
    filterObject['50DAvgChg'] = null;
    this.selected50DAvgChg = null;
    this.selected50DAvgChgData = null;
    filterObject['200DAvgChg'] = null;
    this.selected200DAvgChg = null;
    this.selected200DAvgChgData = null;
    filterObject['52WHighChg'] = null;
    this.selected52WHighChg = null;
    this.selected52WHighChgData = null;
    const filterCount = this.calculateFilterCount();
    filterObject['filterCount'] = filterCount;
    filterObject['page'] = this.page.offset + 1;
    filterObject['pageSize'] = this.page.size;
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.menuService.setCloseMenuSubject(this.filterDataObject);
        this.showForm = false;
        this.toastServ.presentToast(result?.message, 'success');
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.menuService.triggerChildResetFilter({ ...result });
      });
  }

  get appliedFilterCount(): number {
    let count = 0;
    if (this.selectedPrice) count++;
    if (this.selectedVolume) count++;
    if (this.selectedWeeklyOption) count++;
    if (this.selectedEarnings) count++;
    if (this.selectedMarketCap) count++;
    if (this.selectedChange1Day) count++;
    if (this.selectedChangeLast7Days) count++;
    if (this.selectedChange30Days) count++;
    if (this.selectedChange60Days) count++;
    if (this.selectedChange90Days) count++;
    if (this.selectedChange180Days) count++;
    if (this.selectedChange1YTD) count++;
    if (this.selectedChange365Days) count++;
    return count;
  }

  applyFilter() {
    let filterObject = { page: 1, pageSize: 50 };
    if (this.selectedPrice && this.selectedPrice !== 'any' && this.selectedPrice !== 'between') {
      filterObject['price'] = this.selectedPrice;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Price');
      priceFilter.currentValue = this.selectedPrice;
      priceFilter.currentValue = this.selectedPrice;
      this.selectedPriceData = priceFilter.currentValue;
    }
    if (this.selectedPrice && this.selectedPrice !== 'any' && this.selectedPrice == 'between') {
      filterObject[
        'price'
      ] = `${this.selectedPrice} ${this.selectedMinValue}-${this.selectedMaxValue}`;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Price');
      priceFilter.currentValue = filterObject['price'];
      this.selectedPriceData = priceFilter.currentValue;
    }

    if (this.selectedVolume && this.selectedVolume !== 'any') {
      filterObject['volume'] = this.selectedVolume;
      const volumeFilter = FILTERSETUPDATA.find((item) => item.name === 'Volume');
      volumeFilter.currentValue = this.selectedVolume;
      this.selectedVolumeData = volumeFilter.currentValue;
    }

    if (this.selectedWeeklyOption && this.selectedWeeklyOption !== 'any') {
      filterObject['weeklyoption'] = this.selectedWeeklyOption;
      const weeklyOptionFilter = FILTERSETUPDATA.find((item) => item.name === 'WeeklyOption');
      weeklyOptionFilter.currentValue = this.selectedWeeklyOption;
      this.selectedWeeklyOptionData = weeklyOptionFilter.currentValue;
    }

    if (this.selectedEarnings && this.selectedEarnings !== 'any') {
      filterObject['earnings'] = this.selectedEarnings;
      const earningsFilter = FILTERSETUPDATA.find((item) => item.name === 'Earnings');
      earningsFilter.currentValue = this.selectedEarnings;
      this.selectedEarningsData = earningsFilter.currentValue;
    }

    if (this.selectedMarketCap && this.selectedMarketCap !== 'any') {
      filterObject['marketCap'] = this.selectedMarketCap;
      const marketCapFilter = FILTERSETUPDATA.find((item) => item.name === 'MarketCap');
      marketCapFilter.currentValue = this.selectedMarketCap;
      this.selectedMarketCapData = marketCapFilter.currentValue;
    }

    if (
      this.selectedChange1Day &&
      this.selectedChange1Day !== 'any' &&
      this.selectedChange1Day !== 'between'
    ) {
      filterObject['priceChange1Days'] = this.selectedChange1Day;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'PriceChange1Days');
      priceFilter.currentValue = this.selectedChange1Day;
      priceFilter.currentValue = this.selectedChange1Day;
      this.selectedChangeData1Day = priceFilter.currentValue;
    }
    if (
      this.selectedChange1Day &&
      this.selectedChange1Day !== 'any' &&
      this.selectedChange1Day == 'between'
    ) {
      filterObject[
        'priceChange1Days'
      ] = `${this.selectedChange1Day} ${this.selectedMinValue1D}-${this.selectedMaxValue1D}`;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'PriceChange1Days');
      priceFilter.currentValue = filterObject['priceChange1Days'];
      this.selectedChangeData1Day = priceFilter.currentValue;
    }

    if (this.selectedChangeLast7Days && this.selectedChangeLast7Days !== 'any') {
      filterObject['priceChangeLast7Days'] = this.selectedChangeLast7Days;
      const priceChangeLast7DaysFilter = FILTERSETUPDATA.find(
        (item) => item.name === 'PriceChangeLast7Days'
      );
      priceChangeLast7DaysFilter.currentValue = this.selectedChangeLast7Days;
      this.selectedChangeDataLast7Days = priceChangeLast7DaysFilter.currentValue;
    }

    if (this.selectedChange30Days && this.selectedChange30Days !== 'any') {
      filterObject['priceChange30Days'] = this.selectedChange30Days;
      const priceChange30DaysFilter = FILTERSETUPDATA.find(
        (item) => item.name === 'PriceChange30Days'
      );
      priceChange30DaysFilter.currentValue = this.selectedChange30Days;
      this.selectedChangeData30Days = priceChange30DaysFilter.currentValue;
    }

    if (this.selectedChange60Days && this.selectedChange60Days !== 'any') {
      filterObject['priceChange60Days'] = this.selectedChange60Days;
      const priceChange60DaysFilter = FILTERSETUPDATA.find(
        (item) => item.name === 'PriceChange60Days'
      );
      priceChange60DaysFilter.currentValue = this.selectedChange60Days;
      this.selectedChangeData60Days = priceChange60DaysFilter.currentValue;
    }

    if (this.selectedChange90Days && this.selectedChange90Days !== 'any') {
      filterObject['priceChange90Days'] = this.selectedChange90Days;
      const priceChange90DaysFilter = FILTERSETUPDATA.find(
        (item) => item.name === 'PriceChange90Days'
      );
      priceChange90DaysFilter.currentValue = this.selectedChange90Days;
      this.selectedChangeData90Days = priceChange90DaysFilter.currentValue;
    }

    if (this.selectedChange1YTD && this.selectedChange1YTD !== 'any') {
      filterObject['priceChange1YTD'] = this.selectedChange1YTD;
      const priceChange1YTDFilter = FILTERSETUPDATA.find((item) => item.name === 'PriceChange1YTD');
      priceChange1YTDFilter.currentValue = this.selectedChange1YTD;
      this.selectedChangeData1YTD = priceChange1YTDFilter.currentValue;
    }

    if (this.selectedChange365Days && this.selectedChange365Days !== 'any') {
      filterObject['priceChange365Days'] = this.selectedChange365Days;
      const priceChange365DaysFilter = FILTERSETUPDATA.find(
        (item) => item.name === 'PriceChange365Days'
      );
      priceChange365DaysFilter.currentValue = this.selectedChange365Days;
      this.selectedChangeData365Days = priceChange365DaysFilter.currentValue;
    }

    if (this.selectedCountry && this.selectedCountry !== 'any') {
      filterObject['country'] = this.selectedCountry;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Country');
      priceFilter.currentValue = this.selectedCountry;
      this.selectedCountryData = priceFilter.currentValue;
    }

    if (this.selectedIndustry && this.selectedIndustry !== 'any') {
      filterObject['industry'] = this.selectedIndustry;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Industry');
      priceFilter.currentValue = this.selectedIndustry;
      this.selectedIndustryData = priceFilter.currentValue;
    }

    if (this.selectedSector && this.selectedSector !== 'any') {
      filterObject['sector'] = this.selectedSector;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === 'Sector');
      priceFilter.currentValue = this.selectedSector;
      this.selectedSectorData = priceFilter.currentValue;
    }

    if (this.selected50DAvgChg && this.selected50DAvgChg !== 'any') {
      filterObject['50DAvgChg'] = this.selected50DAvgChg;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === '50DAvgChg');
      priceFilter.currentValue = this.selected50DAvgChg;
      this.selected50DAvgChgData = priceFilter.currentValue;
    }

    if (this.selected200DAvgChg && this.selected200DAvgChg !== 'any') {
      filterObject['200DAvgChg'] = this.selected200DAvgChg;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === '200DAvgChg');
      priceFilter.currentValue = this.selected200DAvgChg;
      this.selected200DAvgChgData = priceFilter.currentValue;
    }
    if (this.selected52WHighChg && this.selected52WHighChg !== 'any') {
      filterObject['52WHighChg'] = this.selected52WHighChg;
      const priceFilter = FILTERSETUPDATA.find((item) => item.name === '52WHighChg');
      priceFilter.currentValue = this.selected52WHighChg;
      this.selected52WHighChgData = priceFilter.currentValue;
    }
    this.goBack();
  }

  applyFilterList() {
    let filterObject = { page: 1, pageSize: 50 };
    FILTERSETUPDATA.forEach((item) => {
      if (!item.currentValue || item.currentValue === this.selectedPriceData) {
        filterObject['price'] = this.selectedPriceData ? this.selectedPriceData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedVolumeData) {
        filterObject['volume'] = this.selectedVolumeData ? this.selectedVolumeData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedWeeklyOptionData) {
        filterObject['isWeeklyStock'] =
          this.selectedWeeklyOptionData === 'yes'
            ? true
            : this.selectedWeeklyOptionData === 'no'
            ? false
            : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedEarningsData) {
        filterObject['earnings'] = this.selectedEarningsData ? this.selectedEarningsData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedMarketCapData) {
        filterObject['marketCap'] = this.selectedMarketCapData ? this.selectedMarketCapData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeData1Day) {
        filterObject['change1Day'] = this.selectedChangeData1Day
          ? this.selectedChangeData1Day
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeDataLast7Days) {
        filterObject['change7Days'] = this.selectedChangeDataLast7Days
          ? this.selectedChangeDataLast7Days
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeData30Days) {
        filterObject['change30Days'] = this.selectedChangeData30Days
          ? this.selectedChangeData30Days
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeData60Days) {
        filterObject['change60Days'] = this.selectedChangeData60Days
          ? this.selectedChangeData60Days
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeData90Days) {
        filterObject['change90Days'] = this.selectedChangeData90Days
          ? this.selectedChangeData90Days
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeData180Days) {
        filterObject['change180Days'] = this.selectedChangeData180Days
          ? this.selectedChangeData180Days
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeData1YTD) {
        filterObject['change1YTD'] = this.selectedChangeData1YTD
          ? this.selectedChangeData1YTD
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedChangeData365Days) {
        filterObject['change365Days'] = this.selectedChangeData365Days
          ? this.selectedChangeData365Days
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedCountryData) {
        filterObject['country'] = this.selectedCountryData ? this.selectedCountryData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedIndustryData) {
        filterObject['industry'] = this.selectedIndustryData ? this.selectedIndustryData : null;
      }
      if (!item.currentValue || item.currentValue === this.selectedSectorData) {
        filterObject['sector'] = this.selectedSectorData ? this.selectedSectorData : null;
      }
      if (!item.currentValue || item.currentValue === this.selected50DAvgChgData) {
        filterObject['50DAvgChg'] = this.selected50DAvgChgData ? this.selected50DAvgChgData : null;
      }
      if (!item.currentValue || item.currentValue === this.selected200DAvgChgData) {
        filterObject['200DAvgChg'] = this.selected200DAvgChgData
          ? this.selected200DAvgChgData
          : null;
      }
      if (!item.currentValue || item.currentValue === this.selected52WHighChgData) {
        filterObject['52WHighChg'] = this.selected52WHighChgData
          ? this.selected52WHighChgData
          : null;
      }
    });

    const filterCount = this.calculateFilterCount();
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.isLoading = true;
        this.menuService.setCloseMenuSubject(this.filterDataObject);
        this.showForm = false;
        this.toastServ.presentToast(result?.message, 'success');
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;

        this.cancel('false');
        this.applyFilterData();
        this.menuService.triggerChildResetFilter({ ...result, filterCount });
      });
    this.isLoading = false;
  }

  applyFilterData() {
    const newFilters = {
      price: this.selectedPriceData,
      volume: this.selectedVolumeData,
      isWeeklyStock:
        this.selectedWeeklyOptionData != null
          ? this.selectedWeeklyOptionData === 'yes'
            ? true
            : false
          : null,
      earnings: this.selectedEarningsData,
      sector: this.selectedSectorData,
      industry: this.selectedIndustryData,
      marketCap: this.selectedMarketCapData,
      country: this.selectedCountryData,
      change1Day: this.selectedChangeData1Day,
      change7Days: this.selectedChangeDataLast7Days,
      change30Days: this.selectedChangeData30Days,
      change60Days: this.selectedChangeData60Days,
      change90Days: this.selectedChangeData90Days,
      change180Days: this.selectedChangeData180Days,
      change1YTD: this.selectedChangeData1YTD,
      change365Days: this.selectedChangeData365Days,
    };
    this.setupScannerService.updateFilterObject(newFilters);
    this.showFilterData = newFilters;
  }

  private calculateFilterCount(): number {
    let count = 0;
    if (this.selectedPrice && this.selectedPrice !== 'any') count++;
    if (this.selectedVolume && this.selectedVolume !== 'any') count++;
    if (this.selectedWeeklyOption && this.selectedWeeklyOption !== 'any') count++;
    if (this.selectedEarnings && this.selectedEarnings !== 'any') count++;
    if (this.selectedMarketCap && this.selectedMarketCap !== 'any') count++;
    if (this.selectedChange1Day && this.selectedChange1Day !== 'any') count++;
    if (this.selectedChangeLast7Days && this.selectedChangeLast7Days !== 'any') count++;
    if (this.selectedChange30Days && this.selectedChange30Days !== 'any') count++;
    if (this.selectedChange60Days && this.selectedChange60Days !== 'any') count++;
    if (this.selectedChange90Days && this.selectedChange90Days !== 'any') count++;
    if (this.selectedChange180Days && this.selectedChange180Days !== 'any') count++;
    if (this.selectedChange1YTD && this.selectedChange1YTD !== 'any') count++;
    if (this.selectedChange365Days && this.selectedChange365Days !== 'any') count++;
    if (this.selectedCountry && this.selectedCountry !== 'any') count++;
    if (this.selectedIndustry && this.selectedIndustry !== 'any') count++;
    if (this.selectedSector && this.selectedSector !== 'any') count++;
    if (this.selected50DAvgChg && this.selected50DAvgChg !== 'any') count++;
    if (this.selected200DAvgChg && this.selected200DAvgChg !== 'any') count++;
    if (this.selected52WHighChg && this.selected52WHighChg !== 'any') count++;
    return count;
  }

  clearSpecificFilter(type) {
    let filterObject = { page: 1, pageSize: 50 };
    if (type == 'price') {
      filterObject['price'] = null;
      this.selectedPrice = null;
      this.selectedPriceData = null;
    }
    if (type == 'volume') {
      filterObject['volume'] = null;
      this.selectedVolume = null;
      this.selectedVolumeData = null;
    }
    if (type == 'isWeeklyStock') {
      filterObject['isWeeklyStock'] = null;
      this.selectedWeeklyOption = null;
      this.selectedWeeklyOptionData = null;
    }
    if (type == 'earnings') {
      filterObject['earnings'] = null;
      this.selectedEarnings = null;
      this.selectedEarningsData = null;
    }
    if (type == 'marketcap') {
      filterObject['marketcap'] = null;
      this.selectedMarketCap = null;
      this.selectedMarketCapData = null;
    }
    if (type == 'change1Day') {
      filterObject['change1Day'] = null;
      this.selectedChange1Day = null;
      this.selectedChangeData1Day = null;
    }
    if (type == 'priceChangeLast7Days') {
      filterObject['priceChangeLast7Days'] = null;
      this.selectedChangeLast7Days = null;
      this.selectedChangeDataLast7Days = null;
    }
    if (type == 'priceChange30Days') {
      filterObject['priceChange30Days'] = null;
      this.selectedChange30Days = null;
      this.selectedChangeData30Days = null;
    }
    if (type == 'priceChange60Days') {
      filterObject['priceChange60Days'] = null;
      this.selectedChange60Days = null;
      this.selectedChangeData60Days = null;
    }
    if (type == 'priceChange90Days') {
      filterObject['priceChange90Days'] = null;
      this.selectedChange90Days = null;
      this.selectedChangeData90Days = null;
    }
    if (type == 'priceChange180Days') {
      filterObject['priceChange180Days'] = null;
      this.selectedChange180Days = null;
      this.selectedChangeData180Days = null;
    }

    if (type == 'priceChange1YTD') {
      filterObject['priceChange1YTD'] = null;
      this.selectedChange1YTD = null;
      this.selectedChangeData1YTD = null;
    }
    if (type == 'priceChange365Days') {
      filterObject['priceChange365Days'] = null;
      this.selectedChange365Days = null;
      this.selectedChangeData365Days = null;
    }
    if (type == 'country') {
      filterObject['country'] = null;
      this.selectedCountry = null;
      this.selectedCountryData = null;
    }
    if (type == 'industry') {
      filterObject['industry'] = null;
      this.selectedIndustry = null;
      this.selectedIndustryData = null;
    }
    if (type == 'sector') {
      filterObject['sector'] = null;
      this.selectedSectorData = null;
    }
    if (type == '50DAvgChg') {
      filterObject['50DAvgChg'] = null;
      this.selected50DAvgChgData = null;
    }
    if (type == '200DAvgChg') {
      filterObject['200DAvgChg'] = null;
      this.selected200DAvgChgData = null;
    }
    if (type == '52WHighChg') {
      filterObject['52WHighChg'] = null;
      this.selected52WHighChgData = null;
    }

    /* const filterCount = this.calculateFilterCount();
    filterObject['filterCount'] = filterCount;
    filterObject['page'] = this.page.offset + 1;
    filterObject['pageSize'] = this.page.size;
    this.filterDataObject = { ...this.filterDataObject, ...filterObject };
    this.tradeToolsService
      .setupScannerWeeklyStock(this.filterDataObject)
      .subscribe((result: any) => {
        this.menuService.setCloseMenuSubject(this.filterDataObject);
        this.showForm = false;
        this.toastServ.presentToast(result?.message, 'success');
        const { data = [], meta } = result;
        this.page = {
          offset: parseInt(meta.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.menuService.triggerChildResetFilter({ ...result, filterCount });
      }); */
  }
}
export interface SetupScannerRow {
  name: string;
  description: string;
  _id: string;
}
