import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonComponent } from './common.component';
import { GravatarModule } from 'ngx-gravatar';
import { WalkwelDirectiveModules } from 'src/app/directives/walkwel-directive-modules.module';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NotificationsPopoverPage } from './notifications-popover';
import { ProfileMenuPopoverPage } from './profile-menu-popover';
import { NotificationsModalPage } from './notification-modal';

@NgModule({
  declarations: [
    CommonComponent,
    NotificationsPopoverPage,
    NotificationsModalPage,
    ProfileMenuPopoverPage,
  ],
  imports: [CommonModule, GravatarModule, WalkwelDirectiveModules, IonicModule, PipesModule],
  exports: [CommonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonHeaderModule {}
