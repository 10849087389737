import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, ModalOptions } from '@ionic/angular';
import { PopupInformationService } from 'src/app/services/popup-information.service';
import { StorageService } from 'src/app/services/storage.service';
import { videoExtensions } from 'src/app/utils/constant';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-popup-information',
  templateUrl: './popup-information.component.html',
  styleUrls: ['./popup-information.component.scss'],
})
export class PopupInformationComponent implements OnInit {
  @Input() formType: string;
  @Input() popupInfo: any;
  @Input() preview: any;

  previewType: any;
  currentPopupShow = [];
  modelId: any;
  subType: any;
  constructor(
    private modalController: ModalController,
    private popupInformationService: PopupInformationService,
    public storageService: StorageService,
    protected sanitizer: DomSanitizer
  ) {}
  user: any = JSON.parse(this.storageService.get('userData') || '{}');
  userId = this.user.id;

  ngOnInit() {
    if (!this.preview) this.popupInformationService.checkEligiblePopup();
    else this.subType = this.checkFileType(this.popupInfo.image);
  }

  async close(popupId) {
    this.modalController.dismiss({ popupId });
  }
  cleanURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openImageRedirectUrl(imageRedirectUrl: string): void {
    window.open(imageRedirectUrl, '_blank');
  }

  getPopupData() {
    return JSON.parse(this.storageService.get('popupList') ?? '[]');
  }
  async manageUserPopup(url_chunks = []) {
    if (url_chunks.length == 0) return;
    const popupList = this.getPopupData();
    if (popupList.length == 0) return;
    this.currentPopupShow = popupList.filter((popup) => {
      return (
        (popup.allowed_pages.length ?? 0) == 0 ||
        popup.allowed_pages.every((pages) => url_chunks.includes(pages))
      );
    });
    await this.showMultiplePopups();
  }
  async showMultiplePopups() {
    if (this.currentPopupShow[0]) await this.showPopup(this.currentPopupShow[0]);
  }
  checkFileType(url: string) {
    let ext = url?.split('.').reverse()[0].toLowerCase();
    ext = ext.toLowerCase();
    if (videoExtensions.includes(ext)) return 'video';
    else return 'iframe';
  }

  async showPopup(popupData) {
    let Props: any = {
      formType: 'popupInformationType',
      popupInfo: popupData,
      previewType: popupData.mediaType,
    };
    if (Props.previewType == 'video') Props.subType = this.checkFileType(popupData.image);
    this.modelId = await this.modalController.create({
      component: PopupInformationComponent, // Your modal page component
      cssClass: 'modal-buyers-information',
      showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        ...Props,
      },
    });

    this.modelId.onDidDismiss().then(async (data) => {
      let popupId: any;
      if (data.role == 'backdrop') popupId = popupData._id;
      else popupId = data?.data?.popupId;
      if (!popupId) return;
      this.popupInformationService.dismissPopup(this.userId, popupId).subscribe((response: any) => {
        this.modalController.dismiss();
        if (!this.preview) this.popupInformationService.checkEligiblePopup();
      });
    });
    await this.modelId.present();
  }
}
