import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseUrl, hostUrl, environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import OneSignal from 'onesignal-cordova-plugin';
import { OneSignal as OnesignalWeb } from 'onesignal-ngx';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  public userId = null;

  constructor(
    private http: HttpClient,
    private storagesrv: StorageService,
    private oneSignalWeb: OnesignalWeb,
    private router: Router
  ) {
    this.userId = this.storagesrv.get('userId');
  }

  public configureWebPush() {
    this.oneSignalWeb
      .init({
        appId: environment.onesignalAppId,
        allowLocalhostAsSecureOrigin: true,
      })
      .then(() => {
        this.oneSignalWeb.isPushNotificationsEnabled((isEnabled) => {
          if (isEnabled) {
            this.oneSignalWeb.setExternalUserId(this.userId);
          }
        });

        this.oneSignalWeb.on('subscriptionChange', (isSubscribed) => {

          if (isSubscribed) {
            this.oneSignalWeb.setExternalUserId(this.userId);
          }
        });
      })
      .catch((error) => {
        console.log('web push configuration error:', error);
      });
  }

  async configureNativePush() {
    try {
      OneSignal.setAppId(environment.onesignalAppId);

      OneSignal.promptForPushNotificationsWithUserResponse(true, (accepted) => {

        if (accepted) {
          OneSignal.setExternalUserId(this.userId);
        }
      });

      OneSignal.getDeviceState((state) => {

        if (state.subscribed) {
          OneSignal.setExternalUserId(this.userId);
        }
      });

      OneSignal.addSubscriptionObserver((event) => {
        if (event.to.isSubscribed) {
          OneSignal.setExternalUserId(this.userId);
        }
      });

      OneSignal.setNotificationOpenedHandler((data) => {

        const { notification } = data;
        const { url: launchURL = null }: any = notification?.additionalData;

        if (launchURL && hostUrl) {
          const pathArray = launchURL.split(hostUrl);
          const appPath = pathArray.pop();

          if (appPath) {
            this.router.navigateByUrl(appPath);
          }
        }
      });
    } catch (error) {
      console.log('configure native push catch error:', error);
    }
  }

  public getTemplatesList() {
    return this.http.get(`${baseUrl}/broadcast/templates`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('cache-request', 'true'),
    });
  }

  public getTemplateDetails(templateId) {
    return this.http.get(`${baseUrl}/broadcast/template/${templateId}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('cache-request', 'true'),
    });
  }

  public getAudiencesList() {
    return this.http.get(`${baseUrl}/broadcast/audiences`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('cache-request', 'true'),
    });
  }

  public getTemplatePreview({ templateId, subject, body }) {
    const template: any = {};
    if (templateId) {
      template.templateId = templateId;
    }
    if (subject) {
      template.subject = subject;
    }
    if (body) {
      template.body = body;
    }

    return this.http.post(`${baseUrl}/broadcast/template/email/preview`, template, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  public getAudienceContacts(audienceId) {
    return this.http.get(`${baseUrl}/broadcast/audience/contacts?audienceId=${audienceId}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('cache-request', 'true'),
    });
  }

  public emitBroadcast(data) {
    return this.http.post(`${baseUrl}/broadcast`, data, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }

  public getTextBroadcastLogs(page, disableHttpLoader = 'true') {
    return this.http.get(`${baseUrl}/broadcast/logs/message?page=${page}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  public getEmailBroadcastLogs(page, disableHttpLoader = 'true') {
    return this.http.get(`${baseUrl}/broadcast/logs/email?page=${page}`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  public emailBroadcastUpdateStatus(broadcastId, operation, platform = '') {
    return this.http.get(
      `${baseUrl}/broadcast/email-broadcast/update/status?broadcastId=${broadcastId}&operation=${operation}&platform=${platform}`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer' + ' ' + this.storagesrv.get('token')
        ),
      }
    );
  }

  public smsBroadcastUpdateStatus(broadcastId, operation) {
    return this.http.get(
      `${baseUrl}/broadcast/sms-broadcast/update/status?broadcastId=${broadcastId}&operation=${operation}`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer' + ' ' + this.storagesrv.get('token')
        ),
      }
    );
  }
}
