<ng-container *ngIf="slides && slides.slidesItems && slides.slidesItems.length">
  <div class="slider-root">
    <ion-slides pager #slideWithNav>
      <ion-slide *ngFor="let slide of slides.slidesItems">
        <div
          class="slide-container"
          [ngStyle]="{ cursor: !!slide.link ? 'pointer' : 'unset' }"
          (click)="handleLink(slide.link)"
        >
          <div class="svg-title-block">
            <h2 class="slider-title">
              <img *ngIf="slide?.svg" class="slider-svg left-svg" [src]="slide?.svg" />{{
                slide.title
              }}<img *ngIf="slide?.svg" class="slider-svg right-svg" [src]="slide?.svg" />
            </h2>
          </div>
          <div *ngIf="slide?.image">
            <img class="slider-image" [src]="slide?.image" />
          </div>
          <video
            class="video-wrapper"
            *ngIf="!slides?.isBeginningSlide && slide?.link && currentSlideIndex !== 3"
            width="400"
            controls
            autoplay
            muted
            loop
          >
            <source [src]="slide?.link" type="video/mp4" />
            <source [src]="slide?.link" type="video/ogg" />
          </video>
          <div
            class="video-wrapper"
            *ngIf="formType === 'hubInformationType' && currentSlideIndex === 3"
          >
            <iframe
              src="https://player.vimeo.com/video/885745458"
              width="560"
              height="340"
              frameborder="1"
              allowfullscreen="allowfullscreen"
              data-mce-fragment="1"
            ></iframe>
          </div>
          <div *ngIf="formType === 'wowInfo' && currentSlideIndex === 3">
            <video class="video-wrapper" width="400" controls autoplay muted loop>
              <source src="assets/mp3/Money Press Method(MP4)/insider-mpm.mp4" type="video/mp4" />
              <source src="assets/mp3/Money Press Method(MP4)/insider-mpm.ogg" type="video/ogg" />
            </video>
          </div>
          <h3 class="slider-subtitle">{{ slide.description }}</h3>
        </div>
      </ion-slide>
    </ion-slides>

    <div class="left-arrow-container right-arrow-container">
      <ion-button
        class="back-btn"
        *ngIf="!slides.isBeginningSlide"
        color="medium"
        size="large"
        fill="clear"
        (click)="$event.stopPropagation(); slidePrev()"
      >
        <ion-icon name="arrow-back-outline"></ion-icon>
        BACK
      </ion-button>
      <ion-button
        *ngIf="currentSlideIndex !== slides.slidesItems.length - 1"
        class="next-btn"
        color="medium"
        size="large"
        fill="clear"
        (click)="$event.stopPropagation(); slideNext()"
      >
        NEXT
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </ion-button>
      <ion-button
        class="next-btn"
        color="medium"
        size="large"
        fill="clear"
        *ngIf="currentSlideIndex === slides.slidesItems.length - 1"
        (click)="$event.stopPropagation(); slideFinish()"
      >
        FINISH
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </ion-button>
    </div>
  </div>
</ng-container>
