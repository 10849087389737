import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doesnot-exist',
  templateUrl: './doesnot-exist.page.html',
  styleUrls: ['./doesnot-exist.page.scss'],
})
export class DoesnotExistPage implements OnInit {
  constructor() {}

  ngOnInit() {}
}
