import { Component, OnDestroy } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpLoaderService } from '../../services/http-loader.service';
@Component({
  selector: 'app-my-loader',
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.scss'],
})
export class CommonLoaderComponent implements OnDestroy {
  public subscriptions = [];

  constructor(private loaderService: HttpLoaderService, private ngxService: NgxUiLoaderService) {
    this.subscriptions.push(
      this.loaderService.isLoading.subscribe((load) => {
        if (load) {
          this.ngxService.start();
        }

        if (!load) {
          this.ngxService.stop();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
