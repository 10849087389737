<div class="drawer-header header-drawer-custom">
  <div class="header-container">
    <div class="back-to">
      <a (click)="cancel()"><ion-icon name="chevron-back-outline"></ion-icon>Back to profile </a>
      <div class="close-block" (click)="cancel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </div>
    </div>
  </div>
</div>

<ion-header class="ion-no-border header-top-custom">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-title class="title-size" position="floating">{{ formTitle }}</ion-title>
    </ion-buttons>
    <ion-title>{{ mode === 'edit' ? 'Update' : 'Add' }} Leg</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal content-right-block add-potion-modal-split">
  <div class="form-container" [formGroup]="addOption">
    <ion-item lines="none" class="input-block-custom ion-no-padding width-css toggle-gray-sm">
      <ion-toggle
        lines="none"
        class="ion-no-padding"
        justify="end"
        formControlName="isHistoricalLeg"
        (ionChange)="onIsHistorical($event)"
      ></ion-toggle>
      <div>
        Is historical leg?
        <span class="tooltip">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.492 6.92414C8.46974 6.80615 8.40225 6.70043 8.30283 6.6278C8.20341 6.55517 8.07926 6.5209 7.95482 6.53174C7.83039 6.54257 7.71469 6.59773 7.63051 6.68634C7.54633 6.77496 7.49977 6.89061 7.5 7.01052V11.3312L7.508 11.4176C7.53026 11.5355 7.59775 11.6413 7.69717 11.7139C7.79659 11.7865 7.92074 11.8208 8.04518 11.81C8.16961 11.7991 8.28531 11.744 8.36949 11.6554C8.45367 11.5667 8.50023 11.4511 8.5 11.3312V7.01052L8.492 6.92414ZM8.799 4.85115C8.799 4.66025 8.71998 4.47717 8.57933 4.34218C8.43868 4.20719 8.24791 4.13136 8.049 4.13136C7.85009 4.13136 7.65932 4.20719 7.51867 4.34218C7.37802 4.47717 7.299 4.66025 7.299 4.85115C7.299 5.04205 7.37802 5.22513 7.51867 5.36012C7.65932 5.4951 7.85009 5.57094 8.049 5.57094C8.24791 5.57094 8.43868 5.4951 8.57933 5.36012C8.71998 5.22513 8.799 5.04205 8.799 4.85115ZM16 7.97024C16 5.93397 15.1571 3.9811 13.6569 2.54124C12.1566 1.10138 10.1217 0.29248 8 0.29248C5.87827 0.29248 3.84344 1.10138 2.34315 2.54124C0.842855 3.9811 0 5.93397 0 7.97024C0 10.0065 0.842855 11.9594 2.34315 13.3992C3.84344 14.8391 5.87827 15.648 8 15.648C10.1217 15.648 12.1566 14.8391 13.6569 13.3992C15.1571 11.9594 16 10.0065 16 7.97024ZM1 7.97024C1 7.08801 1.18106 6.21443 1.53284 5.39936C1.88463 4.58429 2.40024 3.8437 3.05025 3.21987C3.70026 2.59604 4.47194 2.10119 5.32122 1.76358C6.1705 1.42597 7.08075 1.2522 8 1.2522C8.91925 1.2522 9.8295 1.42597 10.6788 1.76358C11.5281 2.10119 12.2997 2.59604 12.9497 3.21987C13.5998 3.8437 14.1154 4.58429 14.4672 5.39936C14.8189 6.21443 15 7.08801 15 7.97024C15 9.75197 14.2625 11.4607 12.9497 12.7206C11.637 13.9805 9.85652 14.6883 8 14.6883C6.14348 14.6883 4.36301 13.9805 3.05025 12.7206C1.7375 11.4607 1 9.75197 1 7.97024Z"
              fill="#000032"
              fill-opacity="0.39"
              strikeBought
            />
          </svg>
          <p class="tooltiptext">
            A historical leg is when you add a leg from the past whose expiration date has already
            passed. This is typically used when journaling old positions.
          </p>
        </span>
      </div>
    </ion-item>

    <!-- <div class="input-block-custom custom-opacity"> -->
    <!-- <ion-label *ngIf="formType === 'long'" position="floating">Purchase Date</ion-label> -->
    <!-- <ion-label *ngIf="formType === 'weekly'" position="floating">Date Sold</ion-label> -->
    <!-- <ion-input -->
    <!-- formControlName="purchasedDate" -->
    <!-- readonly="true" -->
    <!-- (click)="openDatePicker()" -->
    <!-- (focus)="openDatePicker()" -->
    <!-- placeholder="MM/DD/YYYY" -->
    <!-- ></ion-input> -->
    <!-- <ion-datetime -->
    <!-- presentation="date" -->
    <!-- (ionChange)="closeDatePicker($event)" -->
    <!-- [hidden]="!showDatePicker" -->
    <!-- displayFormat="MM/DD/YYYY" -->
    <!-- pickerFormat="MM DD YYYY" -->
    <!-- ></ion-datetime> -->
    <!-- </div> -->

    <div class="input-block-custom custom-opacity bg-date-icon">
      <!-- Display the appropriate label based on formType -->
      <ion-label *ngIf="formType === 'long'" position="floating">Purchase Date</ion-label>
      <ion-label *ngIf="formType === 'weekly'" position="floating">Date Sold</ion-label>

      <!-- Make the input field editable by removing readonly -->
      <ion-input
        formControlName="purchasedDate"
        (click)="openDatePicker()"
        (focus)="openDatePicker()"
        placeholder="MM/DD/YYYY"
      ></ion-input>

      <!-- Date picker which will be triggered by user action -->
      <ion-datetime
        presentation="date"
        (ionChange)="closeDatePicker($event)"
        [hidden]="!showDatePicker"
        displayFormat="MM/DD/YYYY"
        pickerFormat="MM DD YYYY"
      ></ion-datetime>
    </div>
    <div
      class="input-block-custom custom-opacity bg-date-icon"
      *ngIf="addOption.get('isHistoricalLeg').value"
    >
      <ion-label position="floating">Expiration Date</ion-label>
      <ion-input
        formControlName="expirationDate"
        (click)="openDatePickerExpiration()"
        (focus)="openDatePickerExpiration()"
        placeholder="MM/DD/YYYY"
      ></ion-input>
      <ion-datetime
        presentation="date"
        (ionChange)="closeDatePickerExpiration($event)"
        [hidden]="!showDatePickerExpiration"
        displayFormat="MM/DD/YYYY"
        pickerFormat="MM DD YYYY"
      ></ion-datetime>
    </div>
    <div class="input-block-custom" *ngIf="!addOption.get('isHistoricalLeg').value">
      <!--  *ngIf="componentData && componentData?.isPositionExpired === false && !toggleChecked" -->
      <ion-label>Expiration Date</ion-label>
      <ion-select
        line="none"
        formControlName="expirationDate"
        okText="Okay"
        (ionChange)="getListStrike(symbol, $event, 'fromExpirationDate')"
        cancelText="Dismiss"
        interface="popover"
      >
        <ion-select-option *ngFor="let expirationDate of listOfExpiration" [value]="expirationDate">
          {{ expirationDate }}
        </ion-select-option>
      </ion-select>
    </div>
    <!-- <div -->
    <!-- class="input-block-custom custom-opacity" -->
    <!-- *ngIf="componentData.isPositionExpired === true && !toggleChecked" -->
    <!-- > -->
    <!-- <ion-label position="floating">Expiration Date</ion-label> -->
    <!-- <ion-input formControlName="expirationDate" type="text"></ion-input> -->
    <!-- </div> -->
    <ion-row class="content-row underlyingprice-block" *ngIf="!isSplitStrike">
      <ion-col size-xl="6" size-lg="6" class="pl-0">
        <div
          class="input-block-custom custom-opacity"
          *ngIf="!addOption.get('isHistoricalLeg').value"
        >
          <ion-label *ngIf="formType === 'long'">Strike Bought</ion-label>
          <ion-label *ngIf="formType === 'weekly'">Strike Sold</ion-label>
          <div class="space-left-block custom-opacity">
            <ion-select
              line="none"
              formControlName="strikeBought"
              okText="Okay"
              cancelText="Dismiss"
              [disabled]="!addOption.get('expirationDate').value"
              (ionChange)="getPremiumPaid($event)"
              interface="popover"
            >
              <ion-select-option
                *ngFor="let position of positionDetail"
                value="{{ position.strike?.raw }}"
              >
                $ {{ position.strike?.raw }}
              </ion-select-option>
            </ion-select>
          </div>
        </div>
        <!-- <div class="input-block-custom" *ngIf="toggleChecked"> -->
        <!-- <ion-label *ngIf="formType === 'long'">Strike Bought</ion-label> -->
        <!-- <ion-label *ngIf="formType === 'weekly'">Strike Sold1</ion-label> -->
        <!-- <ion-input formControlName="strikeBought" type="text"></ion-input> -->
        <!-- </div> -->
        <div
          class="input-block-custom custom-opacity"
          *ngIf="addOption.get('isHistoricalLeg').value"
        >
          <ion-label *ngIf="formType === 'long'">Strike Bought</ion-label>
          <ion-label *ngIf="formType === 'weekly'">Strike Sold</ion-label>
          <ion-input formControlName="strikeBought" type="text"></ion-input>
        </div>
        <!-- <div class="input-block-custom custom-opacity" *ngIf="!isSplitStrike && !toggleChecked"> -->
        <!-- <ion-label *ngIf="formType === 'long'">Strike Bought</ion-label> -->
        <!-- <ion-label *ngIf="formType === 'weekly'">Strike Sold2</ion-label> -->
        <!-- <ion-input formControlName="strikeBought" type="text"></ion-input> -->
        <!-- </div> -->
      </ion-col>
      <ion-col size-xl="6" size-lg="6" class="pr-0">
        <div class="input-block-custom custom-opacity">
          <ion-label>Underlying Price</ion-label>
          <div class="underlyingprice-left">
            <span class="dor">$</span>
            <ion-input formControlName="underlyingPrice" type="number" min="0"></ion-input>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="content-row underlyingprice-block" *ngIf="isSplitStrike">
      <ion-col size-xl="12" size-lg="12" class="pr-0">
        <div class="input-block-custom custom-opacity">
          <ion-label>Underlying Price</ion-label>
          <div class="underlyingprice-left">
            <span class="dor">$</span>
            <ion-input formControlName="underlyingPrice" type="number" min="0"></ion-input>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-item lines="none" class="input-block-custom ion-no-padding width-css toggle-gray-sm">
      <ion-toggle
        lines="none"
        class="ion-no-padding"
        justify="end"
        formControlName="isSplitStrike"
        (ionChange)="onSplitStrikeSelect()"
      ></ion-toggle>
      <div>Split Strike?</div>
    </ion-item>
    <div class="splitstrikeshow" *ngIf="isSplitStrike">
      <div class="splitwrap" formArrayName="legs">
        <div
          class="splititems"
          *ngFor="let leg of addOption.get('legs')['controls']; let i = index"
          [formGroupName]="i"
        >
          <div class="splitstrikeheading">Leg {{ i + 1 }}</div>
          <div class="input-block-custom dropdownsplit">
            <span *ngIf="formType === 'weekly'">
              <ion-label>Strike Sold</ion-label>
            </span>
            <span *ngIf="formType === 'long'">
              <ion-label>Strike Bought</ion-label>
            </span>

            <div class="underlyingprice-left" *ngIf="!addOption.get('isHistoricalLeg').value">
              <div class="space-left-block custom-opacity">
                <ion-select
                  line="none"
                  formControlName="strikeSold"
                  okText="Okay"
                  cancelText="Dismiss"
                  [disabled]="!addOption.get('expirationDate').value"
                  (ionChange)="getPremiumPaid($event)"
                  interface="popover"
                >
                  <ion-select-option
                    *ngFor="let position of positionDetail"
                    value="{{ position.strike?.raw }}"
                  >
                    $ {{ position.strike?.raw }}
                  </ion-select-option>
                </ion-select>
              </div>
            </div>
            <div
              class="underlyingprice-left"
              *ngIf="addOption.get('isHistoricalLeg').value && isSplitStrike"
            >
              <div class="space-left-block custom-opacity">
                <div>
                  <span class="dor">$</span>
                  <ion-input line="none" formControlName="strikeSold"></ion-input>
                </div>
              </div>
            </div>
          </div>
          <div class="input-block-custom inputsplit contracts-sec">
            <ion-label>Contracts</ion-label>
            <ion-input line="none" formControlName="contracts"></ion-input>
          </div>
          <div class="input-block-custom inputsplit">
            <span *ngIf="formType === 'weekly'">
              <ion-label>Credit</ion-label>
            </span>
            <span *ngIf="formType === 'long'">
              <ion-label>Premium Paid</ion-label>
            </span>
            <div class="underlyingprice-left">
              <span class="dor">$</span>
              <ion-input line="none" formControlName="credit"></ion-input>
            </div>
          </div>
          <div class="input-block-custom inputsplit premium-sec">
            <ion-label>Exit Premium</ion-label>
            <div class="underlyingprice-left">
              <span class="dor">$</span>
              <ion-input line="none" formControlName="exitPremium"></ion-input>
            </div>
          </div>
        </div>
      </div>
      <div class="button-add" (click)="addLeg()">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_5777_5201)">
            <path
              d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
              stroke="#72B642"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M5 8H11" stroke="#72B642" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 5V11" stroke="#72B642" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_5777_5201">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>ADD LEG</span>
      </div>
    </div>

    <ion-col size-xl="12" size-lg="12" class="p-0" *ngIf="!isSplitStrike">
      <div class="input-block-custom custom-opacity">
        <ion-label position="floating"># Of Contracts</ion-label>
        <ion-input
          formControlName="contracts"
          type="number"
          inputmode="decimal"
          min="0"
        ></ion-input>
      </div>
    </ion-col>
    <ion-col
      size-xl="12"
      size-lg="12"
      *ngIf="formType === 'long' && (!isSplitStrike || mode == 'edit')"
      class="p-0"
    >
      <div class="input-block-custom custom-opacity" *ngIf="!isSplitStrike">
        <ion-label>Premium Paid</ion-label>
        <div class="underlyingprice-left">
          <span class="dor">$</span>
          <ion-input formControlName="credit" type="number" min="0"></ion-input>
        </div>
      </div>
    </ion-col>
    <ion-col size-xl="12" size-lg="12" *ngIf="formType === 'weekly' && !isSplitStrike" class="p-0">
      <div class="input-block-custom custom-opacity">
        <ion-label>Credit</ion-label>
        <div class="underlyingprice-left">
          <span class="dor">$</span>
          <ion-input formControlName="credit" type="number" min="0"></ion-input>
        </div>
      </div>
    </ion-col>
    <ion-col
      size-xl="12"
      size-lg="12"
      *ngIf="addOption.get('isHistoricalLeg').value && !isSplitStrike"
      class="p-0"
    >
      <div class="input-block-custom">
        <ion-label>Exit Premium</ion-label>
        <div class="underlyingprice-left">
          <span class="dor">$</span>
          <ion-input formControlName="exitPremium" type="number" min="0"></ion-input>
        </div>
      </div>
    </ion-col>
    <ion-item class="ion-item-no-inner-padding" lines="none">
      <ion-label>Status</ion-label>
      <div class="toggle-block">
        <div class="toggle-button-cover">
          <div class="button-cover">
            <div class="button" id="button-1">
              <input
                type="checkbox"
                class="checkbox"
                [checked]="isStatusChecked"
                (change)="toggleStatus($event)"
                [disabled]="addOption.get('isHistoricalLeg').value"
              />
              <div class="knobs"></div>
              <div class="layer"></div>
            </div>
          </div>
        </div>
      </div>
    </ion-item>
    <ion-col size-xl="12" size-lg="12" class="p-0 assignedRow">
      <ion-label
        ><img src="../../../../../../assets/icons/blue-arrow.png" class="img" />
        Assigned?</ion-label
      >
      <div class="radio-btn">
        <ion-radio-group formControlName="isAssigned">
          <ion-radio [value]="true" labelPlacement="end">Yes</ion-radio>
          <span>Yes</span>
          <ion-radio [value]="false" labelPlacement="end">No</ion-radio>
          <span>No</span>
        </ion-radio-group>
      </div>
    </ion-col>
    <ion-item class="ion-item-no-inner-padding checkbox-add" lines="none">
      <ion-checkbox
        color="primary"
        slot="start"
        (ionChange)="onaddNoteSelect($event)"
        formControlName="notesCheck"
      ></ion-checkbox>
      <ion-label>Add Note</ion-label>
    </ion-item>
    <div class="input-block-custom" *ngIf="isaddNote">
      <label for="notes">Notes</label>
      <textarea class="notes-description" formControlName="noteDes" rows="4" cols="50"></textarea>
    </div>
    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <ion-button
          type="submit"
          color="primary"
          fill="solid"
          (click)="createLegs()"
          [disabled]="addOption.invalid"
          style="width: 100%"
        >
          {{ mode === 'edit' ? 'Update' : 'Add' }} Leg
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </div>
</ion-content>
