import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ClimateService {
  constructor(private http: HttpClient, private storagesrv: StorageService) {}

  getAllClimate(disableHttpLoader = 'true') {
    return this.http.get(`${baseUrl}/climate`, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer' + ' ' + this.storagesrv.get('token'))
        .set('disableHttpLoader', disableHttpLoader),
    });
  }

  updateClimates(_id, formData) {
    return this.http.put(`${baseUrl}/climate/${_id}`, formData, {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer' + ' ' + this.storagesrv.get('token')
      ),
    });
  }
}
