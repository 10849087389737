import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { IonIconComponent } from './ion-icon.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, PipesModule, VirtualScrollerModule],
  declarations: [IonIconComponent],
  providers: [
    {
      provide: 'virtual-scroller-default-options',
      useValue: {
        bufferAmount: 50,
        checkResizeInterval: 1000,
        resizeBypassRefreshThreshold: 5,
        scrollDebounceTime: 5,
        scrollThrottlingTime: 5,
        scrollAnimationTime: 0,
      },
    },
  ],
  exports: [IonIconComponent],
})
export class IonIconModule {}
