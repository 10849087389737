import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { AlertDatePickerComponent } from './alert-date-picker.component';

@NgModule({
  declarations: [AlertDatePickerComponent],
  imports: [CommonModule, IonicModule, PipesModule, FormsModule],
  exports: [AlertDatePickerComponent],
})
export class AlertDatePickerModule {}
