import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpSectionComponent } from './help-section.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppDatePickerModule } from 'src/app/components/date-picker/date-picker.module';
import { HelpSectionRoutingModule } from './help-section-routing.module';

@NgModule({
  declarations: [HelpSectionComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    AppDatePickerModule,
    HelpSectionRoutingModule,
  ],
  exports: [HelpSectionComponent],
})
export class HelpSectionModule { }
