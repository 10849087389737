import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Page } from 'src/app/models/page';
import { commonRightMenuService } from 'src/app/services/common-right-menu.service';
import { LoaderService } from 'src/app/services/loader.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToolServiceService } from 'src/app/services/tool-service.service';
import { TradeToolsService } from 'src/app/services/trade-tools-setting';
import { WatchListService } from 'src/app/services/watch-list.service';
import { StockDetailsComponent } from './stock-details/stock-details.component';

@Component({
  selector: 'app-watch-list-data-listing',
  templateUrl: './watch-list-data-listing.component.html',
  styleUrls: ['./watch-list-data-listing.component.scss'],
})
export class WatchListDataListingComponent implements OnInit {
  public page = new Page();
  @Input() watchListData: any;
  rows = [];
  public tag = [];
  public isToolAccess = false;
  public lockedPageContent: null;
  isImageShow: Boolean = false;
  [x: string]: any;
  role: any;
  mode: string;
  watchListRow: WatchListRow = {
    name: '',
    description: '',
    _id: '',
  };
  watchListSymbols: any[] = [];
  watchListDetails: any;
  showDatatable: boolean = false;
  slideOpts = {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: true,
    speed: 400,
    pagination: {
      clickable: true,
    },
  };
  staticData = [
    {
      name: 'Stella Seasonal Trades',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
    {
      name: 'Brandons Quarterly',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
    {
      name: 'Master Income Trader',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
    {
      name: 'Stella Seasonal Trades',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
    {
      name: 'Stella Seasonal Trades',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
    {
      name: 'Stella Seasonal Trades',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
    {
      name: 'Stella Seasonal Trades',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
    {
      name: 'Stella Seasonal Trades',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
    {
      name: 'Master Income Trader',
      description: 'Lipsum solor trade lorum',
      image: '/assets/icon/broadcast-speaker.png',
    },
  ];
  chunkedData = [];
  constructor(
    public watchListService: WatchListService,
    public alertController: AlertController,
    private toastServ: ToastService,
    private storageServ: StorageService,
    private router: Router,
    public loaderService: LoaderService,
    private menuService: commonRightMenuService,
    private tradeToolsService: TradeToolsService,
    private activatedRoute: ActivatedRoute,
    private toolsApi: ToolServiceService,
    public modalController: ModalController
  ) {
    this.lockedPageContent = null;
    this.role = this.storageServ.get('role');
    this.tag = this.storageServ.get('tags') ? JSON.parse(this.storageServ.get('tags')) : [];
    this.activeToolSlug = this.activatedRoute?.snapshot.params.id;
    if (this.activeToolSlug) {
      this.tradeToolsService
        .getToolDetails(this.activeToolSlug, 'true')
        .subscribe((toolResponse: any) => {
          const { tools: toolDetails = null } = toolResponse.data;
          if (toolDetails) {
            this.storageServ.set('toolId', JSON.stringify(toolDetails));
            this.handletoolInit(toolDetails);
          }
        });
    }
  }

  ngOnInit() {
    this.menuService.getTriggerChildReset().subscribe((data) => {
      this.watchListRow.name = data.name ? data.name : this.watchListRow.name;
      this.watchListRow.description = data.description
        ? data.description
        : this.watchListRow.description;
    });
    this.chunkedData = this.chunkArray(this.staticData, 3);
    this.getAllSymbolData(this.activatedRoute?.snapshot.params?.productName);
  }

  chunkArray(myArray, chunk_size) {
    let results = [];
    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }
    return results;
  }

  getAllWatchListData(page, disableHttpLoader = 'true') {
    this.page.offset = page.offset;
    this.watchListService
      .getAllWatchList(this.page.offset + 1, disableHttpLoader)
      .subscribe((result: any) => {
        const { data, meta } = result;
        this.page = {
          offset: parseInt(meta?.page, 10) - 1,
          size: meta?.perPage,
          totalElements: meta?.total,
          totalPages: meta?.pages,
        };
        this.rows = data;
        this.rows = this.chunkArray(data, 3);
      });
  }

  async createEditWatchList(mode) {
    this.mode = mode;
    let watchListProps: any = {
      mode,
      componentType: 'WatchListAddEditComponent',
    };
    if (mode == 'edit') {
      watchListProps.editData = this.watchListRow;
    }
    this.menuService.openRightMenu(watchListProps);
  }

  viewSymbol(row) {
    this.toolsApi.setContext('watchlist');
    this.router.navigate([
      `/${this.role}/tools/dashboard/${this.activeToolSlug}/stock-info/${row.symbol}`,
    ]);
  }

  back() {
    window.location.reload();
  }

  onTrash(row) {
    this.watchListService.removeFromSymbol(row.symbol).subscribe((response: any) => {
      this.toastServ.presentToast(response.message, 'success');
    });
  }

  async onRemoveSymbol(row) {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      subHeader: 'Are you sure you want to remove this stock from the watchlist?',
      buttons: [
        {
          text: 'Delete',
          cssClass: 'delete-button',
          handler: () => {
            this.onTrash(row);
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'ghost-button',
        },
      ],
    });
    await alert.present();
  }

  getAllSymbolData(row) {
    this.watchListService.getAllSymbolList(row).subscribe((res: any) => {
      this.watchListRow = res?.data;
      this.watchListDetails = res?.stockInfo;
      this.watchListSymbols = res?.data?.symbols
        ? res?.data?.symbols.map((symbol, index) => {
            const stockInfo = res?.stockInfo?.find((info) => info.symbol === symbol);
            return {
              symbol,
              id: index,
              stockUrl: stockInfo?.stockUrl,
              regularMarketPrice: stockInfo?.regularMarketPrice,
              regularMarketChange: stockInfo?.regularMarketChange,
              regularMarketChangePercent: stockInfo?.regularMarketChangePercent,
            };
          })
        : [];
      if (this.stockUrl) {
        this.isImageShow = !this.isImageShow;
      }
    });
  }

  async addStockDetails() {
    const symbolsArray = this.watchListSymbols.map((stock) => stock.symbol);
    const modal = await this.modalController.create({
      cssClass: 'modal-buyers-information',
      component: StockDetailsComponent,
      componentProps: {
        selectedSymbol: symbolsArray,
        selectedIds: this.userId,
        watchlistId: this.watchListRow._id,
      },
    });
    return await modal.present();
  }

  addStock() {
    this.addStockDetails();
  }

  onReorderSymbols(event, symbolData) {
    const symbolArray = [...symbolData];
    const draggedItem = symbolArray.splice(event.detail.from, 1)[0];
    symbolArray.splice(event.detail.to, 0, draggedItem);
    const draggedItemOriginal = this.watchListSymbols.splice(event.detail.from, 1)[0];
    this.watchListSymbols.splice(event.detail.to, 0, draggedItemOriginal);

    const sortedSymbolRequest = {
      symbols: symbolArray.map(({ symbol }) => {
        return symbol;
      }),
    };
    event.detail.complete();
    this.watchListService.sortSymbolList(sortedSymbolRequest, this.watchListRow._id).subscribe(
      (response: any) => {
        event.detail.complete();
      },
      (error) => {
        event.detail.complete();
      }
    );
  }

  handletoolInit(tool) {
    this.tool = tool;
    if (this.tool && this.role) {
      const { readonly = false, postAllowedRoles = [], status = null, tags } = this.tool;
      if (status && status === 'locked' && !readonly) {
        const allIdsExist = tags.some((id) => this.tag.includes(Number(id)));
        if (allIdsExist) {
          this.isToolAccess = true;
          this.lockedPageContent = null;
        } else {
          this.loaderService.HideLoader();
          this.toolTitle = tool.title;
          this.lockedPageContent = tool.lockedPageContent;
        }
      } else if (status && status === 'published') {
        this.isToolAccess = true;
        this.lockedPageContent = null;
      } else {
        if (readonly) {
          this.toolIsReadOnlyForUser = !postAllowedRoles.includes(this.userRole);
          if (this.toolIsReadOnlyForUser) {
            this.toolReadOnlyMessage =
              'The channel has been opened with specific levels of access, you are not permitted to post. For more information, please email thecrew@tradersedgenetwork.com.';
          }
        }
      }
    }
  }
  getHeaderClassTodayChange({ row }): any {
    return {
      'custom-green': Number(row?.regularMarketChange) >= 0,
      'custom-red': Number(row?.regularMarketChange) < 0,
      'custom-black': Number(row?.regularMarketChange) === 0,
    };
  }

  getHeaderClassTodayChangePer({ row }): any {
    return {
      'custom-green': Number(row?.regularMarketChangePercent) >= 0,
      'custom-red': Number(row?.regularMarketChangePercent) < 0,
      'custom-black': Number(row?.regularMarketChangePercent) === 0,
    };
  }

  async deleteWatchList() {
    const alert = await this.alertController.create({
      cssClass: 'custom-alert-popup',
      subHeader: 'Are you sure you want to delete watchlist?',
      buttons: [
        {
          text: 'Yes',
          cssClass: 'delete-button',
          handler: () => {
            this.removeWatchList();
          },
        },
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'ghost-button',
        },
      ],
    });
    await alert.present();
  }

  removeWatchList() {
    this.toolsApi.removeWatchList(this.watchListRow?._id).subscribe((response: any) => {
      if (response) {
        this.toastServ.presentToast(response.message, 'success');
        this.viewRemoveWatchlist();
      }
    });
  }

  viewRemoveWatchlist() {
    this.menuService.triggerSymbolReset();
    this.router.navigate([`/${this.role}/tools/watchList/${this.activeToolSlug}`]);
  }
}
export interface WatchListRow {
  name: string;
  description: string;
  _id: string;
}
