<div class="ten-image-uploader">
  <div class="flex">
    <label *ngIf="labelTitle" class="input-label input-button-block"
      >{{ labelTitle }}
      <ion-button
        color="danger"
        fill="clear"
        (click)="$event.preventDefault(); $event.stopPropagation(); deleteVideo()"
      >
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
      </ion-button>
    </label>
    <!--  <button class="delete-video-button" (click)="deleteVideo()">Delete Video</button> -->
  </div>

  <label
    class="picture__container"
    [class.picture__container__default]="layoutType === 'default'"
    [class.picture__container__profile]="layoutType === 'profile'"
    [class.picture__container__banner]="layoutType === 'poster'"
    [ngClass]="customClass ? customClass : ''"
    [ngStyle]="customCss"
  >
    <img
      *ngIf="previewType === 'image' && imageUrl"
      (load)="onMediaLoad()"
      [src]="imageUrl"
      class="picture__img"
    />
    <video
      *ngIf="previewType === 'video' && imageUrl"
      class="picture__img"
      (load)="onMediaLoad()"
      controls
      autoplay
      muted
      loop
    >
      <source [src]="imageUrl" type="video/mp4" />
    </video>
    <div *ngIf="!imageUrl && loading" class="picture__image__text">
      <span>Please wait while uploading file...</span>
    </div>
    <div *ngIf="!imageUrl && !loading" class="picture__image__text">
      {{ inputPlaceholder ? inputPlaceholder : 'Choose an image' }}
    </div>
    <input
      [accept]="acceptedFileTypes"
      #Image
      (change)="handleFileInput($event.target.files)"
      type="file"
      class="picture__input"
    />
  </label>
  <div class="text-danger" *ngIf="inputTypeError">
    <p>Incorrect file type.</p>
  </div>
</div>
